import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import { useTranslation } from "react-i18next";
import { passportService } from "../../../@framework/services";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert } from "antd";

const AuthLogin: React.FC<any> = () => {
  const { t } = useTranslation();
  const loading = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const errorMsg = searchParams.get("message");

  useEffect(() => {
    document.body.style.backgroundColor = "#E5E5E5";

    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, []);

  const onFinish = () => {
    passportService.loginKeycloak();
  };

  const clearError = () => {
    navigate("/login", { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>{t("auth.login.metaTitle")}</title>
      </Helmet>

      {error === "true" && (
        <div className="login-alert-wrapper">
          <Alert
            message={errorMsg}
            type="error"
            showIcon
            closable
            onClose={clearError}
          />
        </div>
      )}

      <div className="main-wrapper login-wrapper">
        <div className="form-wrapper">
          <div className="form-logo">
            <img className="logo" src={"/images/logo.png"} alt="logo" />
          </div>

          <Spin spinning={loading.get()}>
            <Button onClick={onFinish} type="primary" block size={"large"}>
              {t("common.button.signIn")}
            </Button>
          </Spin>
        </div>
      </div>
      <div className="copyright">
        <div>© 2021 PT Erajaya Swasembada</div>
      </div>
    </>
  );
};

export default AuthLogin;
