import { useTranslation } from "react-i18next";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import Button from "antd/es/button";
import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import { useNavigate, useSearchParams } from "react-router-dom";
import Tabs from "antd/lib/tabs";
import TabPlan from "./Tabs/Plan";
import { useState } from "@hookstate/core";
import TabPermintaan from "./Tabs/Permintaan";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import JourneyPlanRepository from "../../../../../repositories/JourneyPlanRepository";
import { haveAccess } from "../../../../../functions/global";
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";
import { permissions } from "../../../../../constant/permissions";
var fileDownload = require("js-file-download");

const { TabPane } = Tabs;

const breadcrumbs = [
  {
    label: "Journey Plan B2C",
    link: "/journey-plan",
  },
];

const AppJourneyPlanList: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let fullPath = window.location.pathname;
  const [searchParams] = useSearchParams();

  const tabKey = useState(searchParams.get("tab") || "Plan");

  const handleExportPlan = async () => {
    let params = {
      ...planParams.get(),
      export: 1,
    };
    try {
      let res = await JourneyPlanRepository.export("b2c", params);
      await fileDownload(res.data, `${Date.now()} - Journey Plan .xlsx`);
    } catch (err) {}
  };

  const planParams = useState({});
  const acl = useAccess();

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={"Journey Plan B2C"} breadcrumbs={breadcrumbs}>
          {tabKey.get() === "Plan" ? (
            <>
              <Button icon={<FileExcelOutlined />} onClick={handleExportPlan}>
                Export Jadwal
              </Button>
              <Accessible access={permissions.write_journey_plan_b2c}>
                <Button
                  onClick={() => navigate(fullPath + "/form")}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  {t("common.button.addNew")}
                </Button>
              </Accessible>
            </>
          ) : (
            ""
          )}
        </WuiSectionTitle>

        <Tabs
          defaultActiveKey={tabKey.get()}
          onChange={(key) => {
            tabKey.set(key);
          }}
        >
          {haveAccess(acl, permissions.read_journey_plan_b2c) && (
            <TabPane tab="Plan" key="Plan">
              <TabPlan
                active={tabKey.get() === "Plan"}
                setParams={planParams.set}
              />
            </TabPane>
          )}

          {
            <TabPane tab="Permintaan" key="Permintaan">
              <TabPermintaan active={tabKey.get() === "Permintaan"} />
            </TabPane>
          }
        </Tabs>
      </WuiContainer>
    </>
  );
};

export default AppJourneyPlanList;
