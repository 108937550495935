import { Button, Modal, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import Moment from "react-moment";

interface Props {
  show: boolean;
  handleClose: () => void,
  soNumber: string,
  tableData: any[]
}

const ModalTransactionDetail: React.FC<Props> = (
    {
      show= false,
      handleClose,
      soNumber= '',
      tableData= []
    }
) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "SO Number",
      dataIndex: "sales_order_no",
      key: "sales_order_no",
    },
    {
      title: "DO Date",
      dataIndex: "delivery_order_date",
      key: "delivery_order_date",
      render: (text: any, record: any) => {
          return <Moment format="YYYY-MM-DD">{text}</Moment>;
      },
    },
    {
      title: "DO No",
      dataIndex: "delivery_order_no",
      key: "delivery_order_no",
    },
    {
      title: "Article Code",
      dataIndex: "product_code",
      key: "product_code",
    },
    {
      title: "Article Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Qty DO",
      dataIndex: "delivery_order_quantity",
      key: "delivery_order_quantity",
    },
    {
      title: "Billing No",
      dataIndex: "billing_no",
      key: "billing_no",
    },
    {
      title: "Billing Date",
      dataIndex: "billing_date",
      key: "billing_date",
      render: (text: any, record: any) => {
          return <Moment format="YYYY-MM-DD">{text}</Moment>;
      },
    },
    {
      title: "Qty Billing",
      dataIndex: "billing_quantity",
      key: "billing_quantity",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any) => {
        return (
          <NumericFormat
            prefix="Rp. "
            value={text}
            thousandSeparator={"."}
            decimalSeparator={","}
            displayType="text"
          />
        );
      },
    },
  ];

  return (
    <Modal
      title={`Informasi Transaksi | ${soNumber}`}
      centered
      visible={show}
      onCancel={handleClose}
      bodyStyle={{ paddingLeft: 15, paddingRight:0, paddingTop: 0, paddingBottom: 0 }}
      width={1300}
      footer={[
        <Button type="primary" key="back" onClick={handleClose}>
          {t("common.button.back")}
        </Button>,
      ]}
    >
      <Table
        rowKey={"id"}
        dataSource={tableData}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: 260 }}
        summary={(pageData) => {
          let totalQtyDo = 0;
          let totalQtyBilling = 0;
          let totalAmount = 0;

          tableData.forEach(({ delivery_order_quantity, billing_quantity, amount }) => {
            totalQtyDo += delivery_order_quantity;
            totalQtyBilling += billing_quantity;
            totalAmount += amount;
          });

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <b>{tableData.length} Produk</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <b>{totalQtyDo}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <b>{totalQtyBilling}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  <b>
                    <NumericFormat
                      prefix="Rp. "
                      value={totalAmount}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      displayType="text"
                    />
                  </b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </Modal>
  );
};

export default ModalTransactionDetail;
