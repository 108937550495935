import React, { useEffect } from "react";
import WuiContainer from "../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Button from "antd/es/button";
import WuiFormTitle from "../../../../@framework/wui/components/Form/Title";
import moment from "moment";
import Form from "antd/lib/form";
import WuiUploadImages from "../../../../@framework/wui/components/UploadImages";
import { Col, Modal, Row, Spin, Tag } from "antd";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import IssueTrackingRepository from "../../../../repositories/IssueTrackingRepository";
import {
  ISSUE_TRACKING_CATEGORY_COMPLAINT,
  ISSUE_TRACKING_CATEGORY_MARKETING,
  ISSUE_TRACKING_CATEGORY_NETWORK,
  ISSUE_TRACKING_CATEGORY_OTHER,
  ISSUE_TRACKING_CATEGORY_STORE_VISIBILITY,
  ISSUE_TRACKING_COMPLETED,
  ISSUE_TRACKING_OPEN,
  ISSUE_TRACKING_PROCESSING,
  ISSUE_TRACKING_TAG_COLOR,
} from "../../../../constant";
import WuiModalConfirmation from "../../../../@framework/wui/components/Modal/Confirmation";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import Text from "antd/es/typography/Text";
import Accessible from "../../../../@framework/wui/components/Accessible";
import { toLower } from "lodash";
import { permissions } from "../../../../constant/permissions";

let title = "Issue Tracking";

const AppIssueTrackingForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { channel, id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append(
      "status",
      form.getFieldValue("status") === ISSUE_TRACKING_PROCESSING
        ? ISSUE_TRACKING_COMPLETED
        : ISSUE_TRACKING_PROCESSING
    );

    if (data.solving_description) {
      formData.append("solving_description", data.solving_description);
    }

    if (data.solving_attachments) {
      data.solving_attachments.forEach((item: any) => {
        formData.append("solving_attachments[]", item);
      });
    }

    await IssueTrackingRepository.update(toLower(channel), id, formData)
      .then((res: AxiosResponse) => {
        getData();
        showCompleteModal.set(false);
        showConfirmModal.set(false);
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        loading.set(false);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  const handleChangeStatus = async (data: any) => {
    if (form.getFieldValue("status") === ISSUE_TRACKING_PROCESSING) {
      showCompleteModal.set(true);
    } else {
      showConfirmModal.set(true);
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    const relationParams = ["attachments", "solvingAttachments", "updatedBy"];

    if (channel === "b2b") {
      const rel = ["dealer", "subject.sales"];
      relationParams.push(...rel);
    } else {
      const rel = ["store", "subject.assistant"];
      relationParams.push(...rel);
    }

    await IssueTrackingRepository.show(toLower(channel), id, {
      with: relationParams,
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        form.setFieldsValue({
          ...data,
          dealer_name: channel === "b2b" ? data.dealer.name : data.store.name,
          sales_consultant_name:
            channel === "b2b"
              ? data.subject?.sales?.name
              : data.subject?.assistant?.name,
          category: data.category,
          dealer_id: channel === "b2b" ? data.dealer_id : data.store_id,
          status: data.status,
          description: data.description,
          attachments: toFileList(data.attachments),
          solving_attachments: toFileList(data.solving_attachments),
          date: moment(data.created_at).format("D MMM YYYY | HH:mm"),
          updatedByName: data.updated_by?.name,
        });

        disable.set(false);
      })
      .catch((e: AxiosError) => {});
  };

  // Modal
  const showCompleteModal = useState(false);
  const showConfirmModal = useState(false);

  const confirmModalCallback = async () => {
    form.submit();
  };

  const StatusTag = () => {
    const text = form.getFieldValue("status");
    const allowedStatus = [
      ISSUE_TRACKING_OPEN,
      ISSUE_TRACKING_PROCESSING,
      ISSUE_TRACKING_COMPLETED,
    ];
    if (!allowedStatus.includes(text)) {
      return <Text>-</Text>;
    }
    return <Tag color={ISSUE_TRACKING_TAG_COLOR[text]}>{text}</Tag>;
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card title="Informasi">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={25}
                >
                  <div className="wui-form-group">
                    <WuiFormTitle
                      title={channel === "b2b" ? "Dealer" : "Toko"}
                    />

                    <div className="input-section">
                      <Text>{form.getFieldValue("dealer_name")}</Text>
                    </div>
                  </div>

                  <div className="wui-form-group">
                    <WuiFormTitle
                      title={
                        channel === "b2b" ? "Sales Consultant" : "Assistant"
                      }
                    />

                    <div className="input-section">
                      <Text>{form.getFieldValue("sales_consultant_name")}</Text>
                    </div>
                  </div>

                  <div className="wui-form-group">
                    <WuiFormTitle title={"Kategori"} />

                    <div className="input-section">
                      <Text>{form.getFieldValue("category")}</Text>
                    </div>
                  </div>

                  <div className="wui-form-group">
                    <WuiFormTitle title={"Status"} />

                    <div className="input-section">
                      <StatusTag />
                    </div>
                  </div>

                  <div className="wui-form-group">
                    <WuiFormTitle title={"Tanggal"} />

                    <div className="input-section">
                      <Text>{form.getFieldValue("date")}</Text>
                    </div>
                  </div>

                  <div className="wui-form-group">
                    <WuiFormTitle title={"Deskripsi"} />

                    <div className="input-section">
                      <Text>{form.getFieldValue("description")}</Text>
                    </div>
                  </div>

                  {(form.getFieldValue("status") === ISSUE_TRACKING_COMPLETED ||
                    form.getFieldValue("status") ===
                      ISSUE_TRACKING_PROCESSING) && (
                    <div className="wui-form-group">
                      <WuiFormTitle title={"Diubah oleh"} />

                      <div className="input-section">
                        <Text>{form.getFieldValue("updatedByName")}</Text>
                      </div>
                    </div>
                  )}
                </Space>
              </Card>
              {form.getFieldValue("status") === ISSUE_TRACKING_COMPLETED && (
                <>
                  <br />

                  <Card title="Bukti">
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size={25}
                    >
                      <div className="wui-form-group">
                        <WuiFormTitle title={"Deskripsi"} />

                        <div className="input-section">
                          <Text>
                            {form.getFieldValue("solving_description")}
                          </Text>
                        </div>
                      </div>

                      <div className="wui-form-group">
                        <WuiFormTitle title={"Foto"} />

                        <div className="input-section">
                          <Form.Item name="solving_attachments">
                            <WuiUploadImages disabled={true} max={5} />
                          </Form.Item>
                        </div>
                      </div>
                    </Space>
                  </Card>
                </>
              )}
            </Col>

            <Col
              className="gutter-row"
              md={{
                span: 8,
                order: 2,
              }}
              xs={{
                span: 24,
                order: 1,
              }}
            >
              <Card title="Lampiran">
                {!disable.get() && (
                  <WuiUploadImages
                    disabled={true}
                    max={5}
                    value={form.getFieldValue("attachments")}
                  />
                )}

                {!disable.get() &&
                  (form.getFieldValue("attachments") || []).length === 0 && (
                    <Text>Tidak ada lampiran</Text>
                  )}
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.back")}
            </Button>
            {!!form.getFieldValue("status") && form.getFieldValue("status") !== ISSUE_TRACKING_COMPLETED &&
              [
                ISSUE_TRACKING_CATEGORY_MARKETING,
                ISSUE_TRACKING_CATEGORY_NETWORK,
                ISSUE_TRACKING_CATEGORY_STORE_VISIBILITY,
                ISSUE_TRACKING_CATEGORY_OTHER,
                ISSUE_TRACKING_CATEGORY_COMPLAINT,
              ].includes(form.getFieldValue("category")) && (
                <Accessible
                  access={[
                    permissions.write_issue_tracking_b2b,
                    permissions.write_issue_tracking_b2c,
                  ]}
                >
                  <Spin spinning={loading.get()}>
                    <Button
                      className="wui-btn"
                      onClick={handleChangeStatus}
                      type="primary"
                      size={"large"}
                      disabled={disable.get()}
                    >
                      {form.getFieldValue("status") !==
                      ISSUE_TRACKING_PROCESSING
                        ? "Proses"
                        : "Selesai"}
                    </Button>
                  </Spin>
                </Accessible>
              )}
          </div>
          {form.getFieldValue("status") === ISSUE_TRACKING_PROCESSING && (
            <Modal
              title="Bukti Penyelesaian"
              centered
              className="pt48 pb48"
              bodyStyle={{ padding: 0 }}
              onCancel={() => {
                showCompleteModal.set(false);
              }}
              visible={showCompleteModal.get()}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    showCompleteModal.set(false);
                  }}
                >
                  {t("common.button.cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  danger
                  loading={loading.get()}
                  onClick={() => form.submit()}
                >
                  Kirim
                </Button>,
              ]}
            >
              <Space
                className="p24"
                direction="vertical"
                style={{ width: "100%" }}
              >
                <div className="wui-form-group type-column ant-form-vertical mb8">
                  <WuiFormTitle title={"Kategori"} />

                  <div className="input-section">
                    <Text strong>{form.getFieldValue("category")}</Text>
                  </div>
                </div>
                <div className="wui-form-group type-column ant-form-vertical">
                  <Space
                    style={{ width: "100%" }}
                    direction="vertical"
                    size={10}
                  >
                    <div>
                      <Form.Item
                        name="solving_description"
                        label={"Deskripsi"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Deskripsi harus diisi",
                            }),
                          },
                        ]}
                      >
                        <Input.TextArea
                          size={"large"}
                          rows={4}
                          placeholder="Masukkan deskripsi"
                        />
                      </Form.Item>
                    </div>
                  </Space>
                </div>

                <div className="wui-form-group type-column ant-form-vertical">
                  <Space
                    style={{ width: "100%" }}
                    direction="vertical"
                    size={10}
                  >
                    <div>
                      <Form.Item
                        name="solving_attachments"
                        label={"Foto"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Foto harus diisi",
                            }),
                          },
                        ]}
                      >
                        <WuiUploadImages max={5} />
                      </Form.Item>
                    </div>
                  </Space>
                </div>
              </Space>
            </Modal>
          )}

          <WuiModalConfirmation
            show={showConfirmModal.get()}
            onOk={confirmModalCallback}
            confirmLabel="Ya"
            onCancel={() => showConfirmModal.set(false)}
          />
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppIssueTrackingForm;
