import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Select from "antd/es/select";
import Space from "antd/es/space";
import Text from "antd/es/typography/Text";
import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import {InputNumber} from "antd/es";
import {KPI_MASTER_DEFAULT_OPERATORS} from "../../../../../../constant";
import React from "react";

interface Props {
  title: string
  name: string
  selectName: string
  selectLabel: string
  selectRepository: any,
  selectParams?: any
}

const OperatorForm: React.FC<Props> = (
    {
      title= '',
      name = '',
      selectName = '',
      selectLabel= '',
      selectRepository,
      selectParams
    }
) => {
  const { t } = useTranslation();
  return (
    <Form.List name={['detail', name]}>
      {(fields, { add, remove }) => {
        return (
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
            size={16}
          >
            {fields.map(({ key, name, ...restField }) => {
              return (
                  <div key={key}>
                    <div>
                      <Row gutter={8}>
                        <Col span={17}>
                          <Form.Item
                              {...restField}
                              name={[name, selectName]}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: selectLabel,
                                  }),
                                },
                              ]}
                          >
                            <WuiSelectSingle
                                placeholder={`Pilih ${selectLabel}`}
                                repository={selectRepository}
                                selectParams={selectParams}
                                style={{ width: "100%" }}
                                size={"middle"}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                              {...restField}
                              name={[name, "weight"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: "Bobot",
                                  })
                                }
                              ]}
                          >
                            <InputNumber
                                min={0}
                                max={100}
                                placeholder={"Bobot"}
                                style={{ width: '100%' }}
                                addonAfter="%"
                            />
                          </Form.Item>
                        </Col>

                        <Col className="pt8">
                          <DeleteOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>

                      <Text type="warning">
                        <InfoCircleOutlined /> Text informasi untuk range
                      </Text>

                      <div className="mt24">
                        <Form.List name={[name, "multiplier"]}>
                          {(
                              multipliersFields,
                              { add: multipliersAdd, remove: multipliersRemove }
                          ) => {
                            return (
                                <Space
                                    style={{
                                      width: "100%",
                                    }}
                                    direction="vertical"
                                    size={16}
                                >
                                  {multipliersFields.map(
                                      ({
                                         key: multiplierKey,
                                         name: multiplierName,
                                         ...multiplierRestField
                                       }) => {
                                        return (
                                            <Row key={key+multiplierKey} gutter={8}>
                                              <Col span={4}>
                                                <Form.Item
                                                    className="mb6"
                                                    name={[
                                                      multiplierName,
                                                      "first_operator",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: t(
                                                            "validation.required",
                                                            {
                                                              item: "Operator",
                                                            }
                                                        ),
                                                      },
                                                    ]}
                                                    {...multiplierRestField}
                                                >
                                                  <Select
                                                      style={{ width: "100%" }}
                                                      placeholder="Operator"
                                                      options={KPI_MASTER_DEFAULT_OPERATORS}
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={4}>
                                                <Form.Item
                                                    className="mb6"
                                                    name={[multiplierName, "first_value"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: t(
                                                            "validation.required",
                                                            {
                                                              item: "Nilai",
                                                            }
                                                        ),
                                                      },
                                                    ]}
                                                    {...restField}
                                                >
                                                  <InputNumber
                                                      placeholder={"Nilai"}
                                                      style={{ width: '100%' }}
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col className="pt4">-</Col>
                                              <Col span={4}>
                                                <Form.Item
                                                    className="mb6"
                                                    name={[
                                                      multiplierName,
                                                      "second_operator",
                                                    ]}
                                                    {...multiplierRestField}
                                                >
                                                  <Select
                                                      allowClear={true}
                                                      style={{ width: "100%" }}
                                                      placeholder="Operator"
                                                      options={KPI_MASTER_DEFAULT_OPERATORS}
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={4}>
                                                <Form.Item
                                                    className="mb6"
                                                    name={[
                                                      multiplierName,
                                                      "second_value",
                                                    ]}
                                                    {...multiplierRestField}
                                                >
                                                  <InputNumber
                                                      placeholder={"Nilai"}
                                                      style={{ width: '100%' }}
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col className="pt4">=</Col>
                                              <Col span={6}>
                                                <Form.Item
                                                    className="mb6"
                                                    name={[multiplierName, "weight_percentage"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: t(
                                                            "validation.required",
                                                            {
                                                              item: "Bobot",
                                                            }
                                                        ),
                                                      },
                                                    ]}
                                                    {...multiplierRestField}
                                                >
                                                  <InputNumber
                                                      max={100}
                                                      placeholder={"Bobot Persen"}
                                                      style={{ width: '100%' }}
                                                      addonAfter="%"
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col className="pt4">
                                                <DeleteOutlined
                                                    onClick={() =>
                                                        multipliersRemove(multiplierName)
                                                    }
                                                />
                                              </Col>
                                            </Row>
                                        );
                                      }
                                  )}
                                  <Button
                                      icon={<PlusOutlined />}
                                      type="primary"
                                      ghost
                                      block
                                      onClick={multipliersAdd}
                                  >
                                    Tambah Pengali
                                  </Button>
                                </Space>
                            );
                          }}
                        </Form.List>
                      </div>
                    </div>
                    <Divider />
                  </div>
              );
            })}

            <Button
              icon={<PlusOutlined />}
              type="primary"
              ghost
              block
              onClick={add}
            >
              Tambah {title}
            </Button>
          </Space>
        );
      }}
    </Form.List>
  );
};

export default OperatorForm;
