import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useState} from "@hookstate/core";
import PageHeader from "antd/es/page-header";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import DealerRepository from "../../../../../../repositories/DealerRepository";
import {AxiosError, AxiosResponse} from "axios";
import {TChannels} from "../../../../../../utils";
import {Col, Row} from "antd";
import {MONTH_MULTI_OPTIONS} from "../../../../../../constant";
import Button from "antd/es/button";
import {NumericFormat} from "react-number-format";
import moment from "moment";
import {DatePicker} from "antd/es";
import BrandRepository from "../../../../../../repositories/BrandRepository";
import WuiSelectMulti from "../../../../../../@framework/wui/components/Select/Multi";
import _ from "lodash";
import SalesRepository from "../../../../../../repositories/SalesRepository";
import Text from "antd/es/typography/Text";

const AppSettingsAccountSalesTargetDealer: React.FC<any> = () => {
    const { t } = useTranslation()
    const { id, dealerId } = useParams();
    const navigate = useNavigate();
    const pageTitle = useState('')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedChannel = useState<TChannels>('b2b')

    // Data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dealer, setDealer] = React.useState<any>({})
    const [targetData, setTargetData] = React.useState<any[]>([])

    useEffect(() => {
        if (id) {
            getData();
            getFilterData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        await DealerRepository.show(selectedChannel.get(), dealerId).then((res: AxiosResponse) => {
            const data = res.data?.data || {}
            setDealer(data);
            pageTitle.set(data.name)
        }).catch((e: AxiosError) => {

        });
    }

    const getTableData = async () => {
        let payload: any = {
            page: 1,
            per_page: 10000,
            months: _.map(filterMonth, 'id') || [],
            year: filterYear.format('YYYY'),
            brand: _.map(filterBrands, 'id') || []
        }

        payload = _.pickBy(payload, _.identity)

        await SalesRepository.allTargetPeriodDetail(id, dealerId, payload).then((res: AxiosResponse) => {
            setTargetData(res?.data?.data || [])
        }).catch((e: AxiosError) => {

        })
    }

    // Filter
    const [filterBrands, setFilterBrands] = React.useState<any[]>([])
    const [filterYear, setFilterYear] = React.useState<any>(moment(searchParams.get('year')))
    const [filterMonth, setFilterMonth] = React.useState<any[]>([]);

    useEffect(() => {
        getTableData()
    }, [filterBrands, filterYear, filterMonth]); // eslint-disable-line react-hooks/exhaustive-deps

    const getFilterData = () => {
        const months = _.map(searchParams.getAll('month'), (item) => parseInt(item))
        let multiMonths = _.filter(MONTH_MULTI_OPTIONS, (data) => {
            return months.includes(data.id)
        });
        setFilterMonth(multiMonths)
    }

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle.get()}
                />

                <Row className="mb8" gutter={[10, 10]}>
                    <Col className="gutter-row" xs={24} md={12} lg={12}>
                        <WuiSelectMulti
                            value={filterBrands}
                            onChange={
                                (val: any) => {
                                    setFilterBrands(val)
                                }
                            }
                            repository={BrandRepository}
                            placeholder={'Semua Brand'}
                        />
                    </Col>
                </Row>

                <Row className="mb16" gutter={[10, 10]}>
                    <Col className="gutter-row" xs={24} md={12} lg={4}>
                        <DatePicker
                            style={{
                                width: "100%",
                            }}
                            allowClear={false}
                            picker="year"
                            placeholder={'Semua Tahun'}
                            value={filterYear}
                            size={'large'}
                            onChange={(date) => {setFilterYear(date)}}
                        />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12} lg={14}>
                        <WuiSelectMulti
                            value={filterMonth}
                            onChange={(val) => {
                                setFilterMonth(val)
                            }}
                            options={MONTH_MULTI_OPTIONS}
                            placeholder={'Semua Bulan'}
                        />
                    </Col>
                </Row>

                <div className={"ant-table ant-table-small ant-table-scroll-horizontal"}>
                    <div className={"ant-table-content"} style={{overflow: "auto hidden"}}>
                        <table className={'table'} style={{width: 'max-content', minWidth: '100%', tableLayout: 'auto'}}>
                            <thead className={"ant-table-thead"}>
                            <tr>
                                <th colSpan={2} rowSpan={2}>{filterYear.format('YYYY')}</th>
                                <th colSpan={filterMonth.length ? filterMonth.length+1 : 13}>
                                    Target
                                </th>
                            </tr>
                            <tr>
                                {
                                    (filterMonth.length ? filterMonth : MONTH_MULTI_OPTIONS).map((item) => {
                                        return (
                                            <th key={item.id}>{item.name}</th>
                                        )
                                    })
                                }
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody className={"ant-table-tbody"}>
                            {
                                targetData.map((item) => {
                                    let totalTarget = _.find(item?.targets || [], (e) => {
                                        return e.type === 'total'
                                    })?.target || 0;
                                    let totalAchievement = _.find(item?.achievements || [], (e) => {
                                        return e.type === 'total'
                                    })?.achievement || 0;
                                    let totalDifference = _.find(item?.differences || [], (e) => {
                                        return e.type === 'total'
                                    })?.difference || 0;

                                    return (
                                        <React.Fragment key={item.id}>
                                            <tr key={`target-${item.id}`}>
                                                <td rowSpan={3}>
                                                    {item?.brand_code}
                                                </td>
                                                <td>
                                                    Target
                                                </td>
                                                {
                                                    (filterMonth.length ? filterMonth : MONTH_MULTI_OPTIONS).map((month) => {
                                                        let selectedData = _.find(item?.targets || [], (e) => {
                                                            return e.month_number === month.id && e.type === 'month'
                                                        });

                                                        return (
                                                            <td key={`${item?.brand_code}${month.id}`}>
                                                                <NumericFormat
                                                                    value={selectedData?.target || 0}
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    displayType="text"
                                                                />
                                                            </td>
                                                        )
                                                    })
                                                }
                                                <td>
                                                    <NumericFormat
                                                        value={totalTarget || 0}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        displayType="text"
                                                    />
                                                </td>
                                            </tr>
                                            <tr key={`pencapaian-${item.id}`}>
                                                <td>
                                                    Pencapaian
                                                </td>
                                                {
                                                    (filterMonth.length ? filterMonth : MONTH_MULTI_OPTIONS).map((month) => {
                                                        let selectedData = _.find(item?.achievements || [], (e) => {
                                                            return e.month_number === month.id && e.type === 'month'
                                                        });

                                                        return (
                                                            <td key={`${item?.brand_code}${month.id}`}>
                                                                <NumericFormat
                                                                    value={selectedData?.achievement || 0}
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    displayType="text"
                                                                />
                                                            </td>
                                                        )
                                                    })
                                                }
                                                <td>
                                                    <NumericFormat
                                                        value={totalAchievement || 0}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        displayType="text"
                                                    />
                                                </td>
                                            </tr>
                                            <tr key={`difference-${item.id}`}>
                                                <td>
                                                    Difference
                                                </td>
                                                {
                                                    (filterMonth.length ? filterMonth : MONTH_MULTI_OPTIONS).map((month) => {
                                                        let selectedData = _.find(item?.differences || [], (e) => {
                                                            return e.month_number === month.id && e.type === 'month'
                                                        });

                                                        return (
                                                            <td key={`${item?.brand_code}${month.id}`}>
                                                                <NumericFormat
                                                                    value={selectedData?.difference || 0}
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    displayType="text"
                                                                    renderText={(value) => {
                                                                        return (
                                                                            <Text type={parseInt(selectedData?.difference || 0) > 0 ? 'success' : 'danger'}>
                                                                                {value}
                                                                            </Text>
                                                                        )
                                                                    }}
                                                                />
                                                            </td>
                                                        )
                                                    })
                                                }
                                                <td>
                                                    <NumericFormat
                                                        value={totalDifference || 0}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        displayType="text"
                                                        renderText={(value) => {
                                                            return (
                                                                <Text type={(parseInt(totalDifference || 0)) > 0 ? 'success' : 'danger'}>
                                                                    {value}
                                                                </Text>
                                                            )
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>




                <div className="wui-form-btn-group">
                    <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.back')}</Button>
                </div>
            </WuiContainer>
        </>
    )
}

export default AppSettingsAccountSalesTargetDealer