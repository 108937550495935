import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "@hookstate/core";
import WuiSectionTitle from "../../../../../../../@framework/wui/components/Sections/Title";
import WuiContainer from "../../../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import Card from "antd/es/card";
import Col from "antd/es/grid/col";
import WuiFormTitle from "../../../../../../../@framework/wui/components/Form/Title";
import Text from "antd/es/typography/Text";
import Row from "antd/es/grid/row";
import {DASHBOARD_B2C_O2O_STATUS_COLOR} from "../../../../../../../constant";
import Tag from "antd/es/tag";
import Table from "antd/es/table";
import {NumericFormat} from "react-number-format";
import DashboardRepository from "../../../../../../../repositories/DashboardRepository";
import {AxiosError, AxiosResponse} from "axios";
import {handleBackendError} from "../../../../../../../functions/global";
import moment from "moment";
import Button from "antd/es/button";

const AppDashboardB2CO2ODetail: React.FC<any> = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    // Data
    const [data, setData] = React.useState<any>(null);
    const [list, setList] = React.useState<any[]>([]);

    const breadcrumbs = useState<any[]>([
        {
            label: 'Dashboard',
            link: '/dashboard'
        },
        {
            label: 'B2C',
            link: '/dashboard/B2C'
        },
        {
            label: 'O2O',
            link: '/dashboard/B2C?tab=O2O'
        }
    ])

    const columns = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 70,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "Code",
            dataIndex: "product_code",
            key: "product_code",
        },
        {
            title: "Description",
            dataIndex: "product.name",
            key: "product.name",
            render: (text: any, item: any, index: number) => {
                return item?.product?.name || '-'
            }
        },
        {
            title: "Qty",
            dataIndex: "quantity",
            key: "quantity",
            render: (text: any, item: any, index: number) => {
                return `${text} Pcs`
            }
        },
        {
            title: "Harga",
            dataIndex: "amount_per_quantity",
            key: "amount_per_quantity",
            render: (text: any, item: any, index: number) => {
                return (
                    <NumericFormat
                        value={text}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        displayType="text"
                    />
                );
            }
        },
        {
            title: "Diskon Item",
            dataIndex: "discount",
            key: "discount",
            render: (text: any, item: any, index: number) => {
                return (
                    <NumericFormat
                        value={text}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        displayType="text"
                    />
                );
            }
        },
        {
            title: "Total",
            dataIndex: "subtotal",
            key: "subtotal",
            render: (text: any, item: any, index: number) => {
                return (
                    <NumericFormat
                        value={text}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        displayType="text"
                    />
                );
            }
        },
    ]

    const getData = async () => {
        await DashboardRepository.showReportCourier(id, {
            with: ['products.product']
        }).then((res: AxiosResponse) => {
            const tempData = res.data?.data || {}
            setData(tempData)
            setList(tempData?.products)

            breadcrumbs.merge([
                {
                    label: `${tempData?.courier_name} - ${tempData?.store_code}`,
                    link: `/dashboard/B2C/O2O/${id}`
                },
            ])
        }).catch((e: AxiosError) => {
            handleBackendError(e);
        });
    }

    const isAccessFromMobileApp = () => {
        return !!localStorage.getItem('is_mobile_app')
    }

    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={''} breadcrumbs={breadcrumbs.get()}/>

                <PageHeader
                    className="default-page-header"
                    onBack={isAccessFromMobileApp() ? undefined : () => navigate(-1)}
                    title={`Detail ${data?.courier_name} - ${data?.store_code}`}
                />

                <Card
                    className="mb16"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Nama Toko"} />

                                <div className="input-section">
                                    <Text strong>{data?.store_name || '-'}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"No Transaksi"} />

                                <div className="input-section">
                                    <Text strong>{data?.no || '-'}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Tanggal"} />

                                <div className="input-section">
                                    <Text strong>{moment(data?.date).format("D MMM YYYY | HH:mm") || '-'}</Text>
                                </div>
                            </div>
                        </Col>

                        {
                            data?.customer_name ? (
                                <Col xs={24} md={24}>
                                    <div className="wui-form-group type-column">
                                        <WuiFormTitle title={"Nama Customer"} />

                                        <div className="input-section">
                                            <Text strong>{data?.customer_name || '-'}</Text>
                                        </div>
                                    </div>
                                </Col>
                            ) : ''
                        }


                        {
                            data?.customer_address ? (
                                <Col xs={24} md={24}>
                                    <div className="wui-form-group type-column">
                                        <WuiFormTitle title={"Alamat Customer"} />

                                        <div className="input-section">
                                            <Text strong>{data?.customer_address || '-'}</Text>
                                        </div>
                                    </div>
                                </Col>
                            ) : ''
                        }

                        {
                            data?.customer_phone ? (
                                <Col xs={24} md={24}>
                                    <div className="wui-form-group type-column">
                                        <WuiFormTitle title={"Nomor Telepon"} />

                                        <div className="input-section">
                                            <Text strong>{data?.customer_phone || '-'}</Text>
                                        </div>
                                    </div>
                                </Col>
                            ) : ''
                        }

                        <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Status"} />

                                <div className="input-section">
                                    <Tag color={DASHBOARD_B2C_O2O_STATUS_COLOR[data?.status]}>{data?.status || '-'}</Tag>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>

                <Card
                    title={'List'}
                    className="mb16"
                >
                    <Table
                        rowKey={"id"}
                        columns={columns}
                        pagination={false}
                        style={{ whiteSpace: 'pre'}}
                        dataSource={list || []}
                    />
                </Card>

                <Row justify="end">
                    <Col xs={24} lg={6} >
                        <Row gutter={[8,8]} justify={"space-between"} align={"middle"}>
                            <Col span={24}>
                                <Text strong>Total</Text>
                            </Col>

                            <Col span={12}>
                                <Text strong>Total Item</Text>
                            </Col>
                            <Col span={12} className={'text-right'}>
                                <Text strong>{data?.total_product} Pcs</Text>
                            </Col>

                            <Col span={12} >
                                <Text strong>Total Qty</Text>
                            </Col>
                            <Col span={12} className={'text-right'}>
                                <Text strong>{data?.total_quantity} Pcs</Text>
                            </Col>

                            <Col span={12}>
                                <Text strong>Diskon Payment</Text>
                            </Col>
                            <Col span={12} className={'text-right'}>
                                <Text strong>
                                    <NumericFormat
                                        value={data?.discount_payment}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType="text"
                                    />
                                </Text>
                            </Col>

                            <Col span={12}>
                                <Text strong>Grand Total</Text>
                            </Col>
                            <Col span={12} className={'text-right'}>
                                <Text strong>
                                    <NumericFormat
                                        prefix={"Rp "}
                                        value={data?.total_amount}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType="text"
                                    />
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>

               {!isAccessFromMobileApp() && <div className="wui-form-btn-group">
                    <Button
                        className="wui-btn"
                        size={"large"}
                        type="primary"
                        onClick={() => navigate(-1)}
                    >
                        {t("common.button.back")}
                    </Button>
                </div>}
            </WuiContainer>
        </>
    )
}


export default AppDashboardB2CO2ODetail