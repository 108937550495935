import {api, createCancelTokenHandler} from "../@framework/services";
import {TChannels} from "../utils";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    select: function (params: any = null) {
        let url = `${endPoint()}/api/v1/select/dealer`;
        if (params?.channel) {
            url = `${endPoint()}/api/v1/${params.channel}/select/dealer`;
        }
        return api.get(url, {
            params,
            cancelToken: cancelTokenHandlerObject[this.select.name].handleRequestCancellation().token
        });
    },
    all: function (channel: TChannels = 'b2b', params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealers`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
        });
    },
    show: function (channel: TChannels = 'b2b', id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
        });
    },
    create: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/dealer`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token
        });
    },
    update: function (channel: TChannels = 'b2b', id: number | string, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/${channel}/dealer/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
        });
    },
    delete: function (id: number | string, params: any = null) {
        return api.delete(`${endPoint()}/api/v1/dealer/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
        });
    },
    allProducts: function (channel: TChannels = 'b2b', id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}/products`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allProducts.name].handleRequestCancellation().token
        });
    },
    updateStatusProduct: function (channel: TChannels = 'b2b', id: number | string, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/${channel}/dealer/${id}/product`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.updateStatusProduct.name].handleRequestCancellation().token
        });
    },
    allStocks: function (channel: TChannels = 'b2b', id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}/stocks`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allStocks.name].handleRequestCancellation().token
        });
    },
    allSolds: function (channel: TChannels = 'b2b', id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}/solds`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allSolds.name].handleRequestCancellation().token
        });
    },
    import: function (channel: TChannels = 'b2b', payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/dealer-import`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.import.name].handleRequestCancellation().token
        });
    },
    importProduct: function (channel: TChannels = 'b2b', payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/dealer-product-import`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.importProduct.name].handleRequestCancellation().token
        });
    },
    importBrand: function (channel: TChannels = 'b2b', payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/dealer-brand-import`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.importBrand.name].handleRequestCancellation().token
        });
    },
    allSalesTransaction: function (channel: TChannels = 'b2b', id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}/sales`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allSalesTransaction.name].handleRequestCancellation().token
        });
    },
    showSalesTransaction: function (channel: TChannels = 'b2b', id: number | string, salesId: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}/sales/${salesId}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.showSalesTransaction.name].handleRequestCancellation().token
        });
    },
    allSalesTarget: function (channel: TChannels = 'b2b', id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}/sales-targets`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allSalesTarget.name].handleRequestCancellation().token
        });
    },
    allGapProgram: function (channel: TChannels = 'b2b', params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/register-promotions`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allGapProgram.name].handleRequestCancellation().token
        });
    },
    financialInfo: function (channel: TChannels = 'b2b', id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dealer/${id}/financial-info`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.financialInfo.name].handleRequestCancellation().token
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;