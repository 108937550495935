import { DownOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import { Dropdown, Menu, Tabs } from "antd";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Space from "antd/es/space";
import Table from "antd/es/table";
import TabPane from "antd/es/tabs/TabPane";
import Collapse from "antd/lib/collapse";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../../@framework/utilities/hooks";
import useDebounce from "../../../../../@framework/utilities/hooks/useDebounce";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import WuiModalImport from "../../../../../@framework/wui/components/Modal/Import";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import WuiSelectSingle from "../../../../../@framework/wui/components/Select/Single";
import {
    AREA_HIERARCHY_OPTIONS,
    ASSISTANT_STORE_LEADER, B2C_AREA_HIERARCHY_OPTIONS,
    MONTH_OPTIONS,
    SALES_CONSULTANT,
    SALES_PROMOTER, SALES_SUPERIOR, STORE_LEADER
} from "../../../../../constant";
import {
    USER_KPI_BAD_DEBT_IMPORT,
    USER_KPI_FRAUD_IMPORT,
    USER_KPI_GROWTH_EXISTING_IMPORT,
    USER_KPI_ONTIME_PAYMENT_IMPORT
} from "../../../../../constant/import";
import { permissions } from "../../../../../constant/permissions";
import { handleBackendError, haveAccess, openNotification } from "../../../../../functions/global";
import KPIMasterRepository from "../../../../../repositories/KPIMasterRepository";
import KPIUserRepository from "../../../../../repositories/KPIUserRepository";
import SuperiorRepository from "../../../../../repositories/SuperiorRepository";
import { TChannels } from "../../../../../utils";
import _ from "lodash";
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";

const { Panel } = Collapse;
const { Option } = Select;

var qs = require("qs");

const breadcrumbs = [
    {
        label: 'KPI',
        link: '/kpi'
    },
    {
        label: 'User KPI',
        link: '/kpi/user-kpi'
    },
]

const tabs: TChannels[] = ["B2B", "B2C", "B2B_B2C"];

const permissionMapping: any = {
    B2B: {
        read: permissions.read_user_kpi_b2b
    },
    B2C: {
        read: permissions.read_user_kpi_b2c
    }
};

const AppKPIUserList: React.FC<any> = () => {
    const {t} = useTranslation()
    const isMounted = useIsMounted()
    const navigate = useNavigate()
    const acl = useAccess()
    const [searchParams, setSearchParams] = useSearchParams();
    let fullPath = window.location.pathname;

    const tabKey = useState<TChannels | string>(
        tabs.find((tab) => haveAccess(acl, permissionMapping[tab].read)) || searchParams.get("channel") || tabs[0]
    );

    // Table
    const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
    const tablePerPage = useState<number>(
        parseInt(searchParams.get("perPage") || "10")
    );
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const tableSort = useState<{
        sortField: any;
        sortOrder: any;
    }>({
        sortField: searchParams.get("sortField"),
        sortOrder: searchParams.get("sortOrder"),
    });
    const tableData = useState<any[]>([]);
    const totalData = useState<number>(0);

    const columns: any = [
        {
            title: "Nama KPI",
            dataIndex: "master_kpi",
            key: "master_kpi",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "master_kpi" && tableSort.sortOrder.get(),
        },
        {
            title: "Peran",
            dataIndex: "superior_name",
            key: "superior_name",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "superior_name" && tableSort.sortOrder.get(),
        },
        {
            title: "Nama",
            dataIndex: "user_name",
            key: "user_name",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "user_name" && tableSort.sortOrder.get(),
        },
        {
            title: "Channel",
            dataIndex: "channels",
            key: "channels",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "channels" && tableSort.sortOrder.get(),
        },
        {
            title: "Hirarki",
            dataIndex: "hierarchy",
            key: "hierarchy",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "hierarchy" && tableSort.sortOrder.get(),
        },
        {
            title: "Periode",
            dataIndex: "period",
            key: "period",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "period" && tableSort.sortOrder.get(),
        },
        {
            title: "Action",
            key: "action",
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath + "/" + record.id}>Lihat Detail</Link>
                </Space>
            ),
        },
    ]

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => {
        tablePage.set(pagination.current);
        tablePerPage.set(pagination.pageSize);

        if (sorter.order) {
            tableSort.sortField.set(sorter.field);
            tableSort.sortOrder.set(sorter.order);
        } else {
            tableSort.sortField.set(null);
            tableSort.sortOrder.set(null);
        }

        setFilterDataToQuery();
    };

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true);

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),
            month: selectedMonth,
            hierarchy: selectedHierarchy,
            superior: filterSuperior.get(),
            channel: getSelectedChannels()
        };

        if (!params.order_by) {
            params.order_by = "created_at";
            params.sorted_by = "descend";
        }

        await KPIUserRepository.all(params)
            .then((res: AxiosResponse) => {
                if (res.data.data) {
                    tableData.set(res.data.data);
                }

                totalData.set(res.data?.total || 0);

                setTableLoading(false);
            })
            .catch((e: AxiosError) => {
                setTableLoading(false);
            });
    };

    // For Search Input
    const [search, setSearch] = React.useState<string>("");
    const debouncedSearch = useDebounce<string>(search, 1000);

    // For Filter
    const [selectedMonth, setSelectedMonth] = React.useState<string>(searchParams.get("month") ?? '');
    const [selectedHierarchy, setSelectedHierarchy] = React.useState<string>(searchParams.get("hierarchy") ?? '');

    const filterSuperior = useState(
        searchParams.get("superior") ? parseInt(searchParams.get("superior")!) : ""
    );

    // For Import
    const [selectedMasterKpi, setSelectedMasterKpi] = React.useState<string>('');

    const handleReset = () => {
        filterSuperior.set('')
        setSelectedMonth('')
        setSelectedHierarchy('')
    }

    const setFilterDataToQuery = () => {
        let params = {};
        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch,
            });

            if (debouncedSearch !== searchParams.get("keyword")) {
                reset = true;
            }
        }

        if (tablePage.get() !== 1 && !reset) {
            Object.assign(params, {
                page: tablePage.get(),
            });
        }

        if (tablePerPage.get() !== 10 && !reset) {
            Object.assign(params, {
                perPage: tablePerPage.get(),
            });
        }

        if (tableSort.sortField.get() && !reset) {
            Object.assign(params, {
                sortField: tableSort.sortField.get(),
            });
        }

        if (tableSort.sortOrder.get() && !reset) {
            Object.assign(params, {
                sortOrder: tableSort.sortOrder.get(),
            });
        }

        if (filterSuperior.get()) {
            Object.assign(params, {
                superior: filterSuperior.get(),
            });
        }

        if (selectedMonth) {
            Object.assign(params, {
                month: selectedMonth,
            });
        }

        if (selectedHierarchy) {
            Object.assign(params, {
                hierarchy: selectedHierarchy,
            });
        }

        if (tabKey.get()) {
            Object.assign(params, {
                channel: tabKey.get(),
            });
        }

        const queryParams = qs.stringify(params, { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`);
        } else {
            navigate("");
        }

        getTableData(reset).then();
    };

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get("keyword");

        if (keyword) {
            setSearch(keyword);
        }

        if (searchParams.get('channel')) {
            tabKey.set(searchParams.get('channel') ?? tabs[0])
        }

        getTableData().then()
    };

    const getSelectedChannels = () => {
        let channels = [tabKey.get()];
        if (tabKey.get() == 'B2B_B2C') {  // eslint-disable-line eqeqeq
            channels = ["B2B", "B2C"]
        }
        return channels
    }

    const importType = useState('');
    const showImportModal = useState(false)

    const getImportMenu = () => {
        const channels = getSelectedChannels()

        if (_.includes(channels,'B2B') && _.includes(channels,'B2C')) {
            return [USER_KPI_FRAUD_IMPORT, USER_KPI_BAD_DEBT_IMPORT]
        } else if (_.includes(channels,'B2C')) {
            return [USER_KPI_FRAUD_IMPORT]
        } else {
            return [USER_KPI_GROWTH_EXISTING_IMPORT, USER_KPI_ONTIME_PAYMENT_IMPORT]
        }
    }

    const handleShowImportModal = (type: string) => {
        importType.set(type)
        showImportModal.set(true)
        setSelectedMasterKpi("");
    }

    const importModalCallback = async (files: File[]) => {
        const formData = new FormData();

        if (files) {
            files.forEach((file: any) => {
                formData.append('file_import', file);
            })
        }

        if (selectedMasterKpi) {
            formData.append('kpi_master_id',selectedMasterKpi)
        }

        if (importType.get() === USER_KPI_FRAUD_IMPORT.TEMPLATE_TEXT) {
            await KPIUserRepository.importFraud(formData).then((res: AxiosResponse) => {
                showImportModal.set(false)
                openNotification('success', t('notification.success.importItem', {item: importType.get()}))
                getTableData(true)
            }).catch((e: AxiosError) => {
                handleBackendError(e, t('notification.error.default'))
            })
        } else if (importType.get() === USER_KPI_BAD_DEBT_IMPORT.TEMPLATE_TEXT) {
            await KPIUserRepository.importBadDebt(formData).then((res: AxiosResponse) => {
                showImportModal.set(false)
                openNotification('success', t('notification.success.importItem', {item: importType.get()}))
                getTableData(true)
            }).catch((e: AxiosError) => {
                handleBackendError(e, t('notification.error.default'))
            })
        } else if (importType.get() === USER_KPI_ONTIME_PAYMENT_IMPORT.TEMPLATE_TEXT) {
            await KPIUserRepository.importOntimePayment(formData).then((res: AxiosResponse) => {
                showImportModal.set(false)
                openNotification('success', t('notification.success.importItem', {item: importType.get()}))
                getTableData(true)
            }).catch((e: AxiosError) => {
                handleBackendError(e, t('notification.error.default'))
            })
        } else if (importType.get() === USER_KPI_GROWTH_EXISTING_IMPORT.TEMPLATE_TEXT) {
            await KPIUserRepository.importGrowthExisting(formData).then((res: AxiosResponse) => {
                showImportModal.set(false)
                openNotification('success', t('notification.success.importItem', {item: importType.get()}))
                getTableData(true)
            }).catch((e: AxiosError) => {
                handleBackendError(e, t('notification.error.default'))
            })
        }
    }

    const importModalDownload = async () => {
        if (importType.get() === USER_KPI_FRAUD_IMPORT.TEMPLATE_TEXT) {
            window.open(USER_KPI_FRAUD_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
        } else if (importType.get() === USER_KPI_BAD_DEBT_IMPORT.TEMPLATE_TEXT) {
            window.open(USER_KPI_BAD_DEBT_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
        } else if (importType.get() === USER_KPI_ONTIME_PAYMENT_IMPORT.TEMPLATE_TEXT) {
            window.open(USER_KPI_ONTIME_PAYMENT_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
        } else if (importType.get() === USER_KPI_GROWTH_EXISTING_IMPORT.TEMPLATE_TEXT) {
            window.open(USER_KPI_GROWTH_EXISTING_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
        }
    }


    useMemo(() => {
        getFilterDataFromQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isMounted) {
            setFilterDataToQuery();
        }
        // eslint-disable-next-line
    }, [debouncedSearch, filterSuperior.get(), selectedHierarchy, selectedMonth, tabKey.get()]);

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={'User KPI'} breadcrumbs={breadcrumbs}>
                    <Accessible access={[permissions.write_user_kpi_b2c]}>
                        <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            overlay={() => {
                                return (
                                    <Menu>
                                        { getImportMenu().map((menu,index) => (
                                            <Menu.Item key={index} icon={<UploadOutlined />} onClick={() => handleShowImportModal(menu.TEMPLATE_TEXT)}>
                                                {menu.TEMPLATE_TEXT}
                                            </Menu.Item>
                                        )) }
                                    </Menu>
                                )
                            }}
                        >
                            <Button>
                                Import <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Accessible>
                </WuiSectionTitle>

                <Tabs
                    defaultActiveKey={tabKey.get()}
                    onChange={(key) => {
                        tabKey.set(key);
                        handleReset()
                    }}
                >
                    {tabs.filter((item) => {
                        if (permissionMapping[item]) {
                            return haveAccess(acl, permissionMapping[item]?.read);
                        }
                            return (
                                haveAccess(acl, permissionMapping[tabs[0]].read) &&
                                haveAccess(acl, permissionMapping[tabs[1]].read)
                            );
                        }).map((tab) => {
                        return (
                            <TabPane tab={tab.toUpperCase().replace('_','&')} key={tab}></TabPane>
                        );
                    })}
                </Tabs>

                <Row gutter={[10, 10]} className="mb16">
                    <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                        <Input
                            allowClear
                            placeholder={t("common.filter.search.placeholder")}
                            prefix={<SearchOutlined />}
                            value={search}
                            onChange={(value) => {
                                setSearch(value.target.value);
                            }}
                        />
                    </Col>
                </Row>

                <Collapse
                    className="mb16"
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                >
                    <Panel header="Filter" key="1">
                        <Row className="mb6" gutter={[10, 10]}>
                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <WuiSelectSingle
                                    labelInValue={false}
                                    value={filterSuperior.get()}
                                    onChange={(value: any) => {
                                        filterSuperior.set(value);
                                    }}
                                    selectParams={{
                                        strict_channel: getSelectedChannels()
                                    }}
                                    style={{ width: "100%" }}
                                    defaultOptions={[{ id: "", name: "Semua Peran" }]}
                                    repository={SuperiorRepository}
                                ></WuiSelectSingle>
                            </Col>

                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <Select
                                    defaultValue={selectedMonth}
                                    onChange={(val) => {
                                        setSelectedMonth(val)
                                    }}
                                    size={"large"}
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Pilih Bulan"
                                >
                                    <Option key='0' value={''}>
                                        Semua Bulan
                                    </Option>
                                    {MONTH_OPTIONS.map((item: any, index: number) => (
                                        <Option key={item} value={index+1}>
                                            {item}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <Select
                                    defaultValue={selectedHierarchy}
                                    onChange={(val) => {
                                       setSelectedHierarchy(val)
                                    }}
                                    size={"large"}
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Pilih Hirarki"
                                >
                                    <Option key='0' value={''}>
                                        Semua Hirarki
                                    </Option>
                                    {!_.includes(getSelectedChannels(),'B2B') ?
                                        B2C_AREA_HIERARCHY_OPTIONS.map((item: any) => (
                                            <Option key={item} value={item}>
                                                {item}
                                            </Option>
                                        )) : AREA_HIERARCHY_OPTIONS.map((item: any) => (
                                            <Option key={item} value={item}>
                                                {item}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>

                            <Col>
                                <Button type="link" onClick={handleReset}>Reset</Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get(),
                    }}
                />
            </WuiContainer>

            <WuiModalImport
                show={showImportModal.get()}
                onUpload={importModalCallback}
                onCancel={() => showImportModal.set(false)}
                onDownload={importModalDownload}
                headerTitle={`Import ${importType.get()}`}
                maxFileOnMB={50}
                disabledSubmit={!selectedMasterKpi}
            >
                <WuiSelectSingle
                    labelInValue={false}
                    value={selectedMasterKpi}
                    onChange={(value: any) => {
                        setSelectedMasterKpi(value);
                    }}
                    size={"large"}
                    selectParams={{
                        channel: getSelectedChannels()
                    }}
                    style={{ width: "100%" }}
                    defaultOptions={[{ id: "", name: "Pilih Master Kpi" }]}
                    placeholder="Pilih Master Kpi"
                    repository={KPIMasterRepository}
                ></WuiSelectSingle>
            </WuiModalImport>
        </>
    )
}

export default AppKPIUserList