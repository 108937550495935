import "./style.less";

interface Props {
  color?: string;
}

const WuiLoadingScreen: React.FC<Props> = () => {
  return (
    <div className="loading-screen-section">
      <img className="spinner" src="/images/spinner.svg" alt="spinner" />
      <img className="logo" src="/images/erafos-logo.png" alt="logo" />
    </div>
  );
};

export default WuiLoadingScreen;
