import React, { ReactChild, ReactChildren, useMemo } from "react";
import useAccess from "../../@framework/utilities/hooks/useAccess";
import { useLocation } from "react-router-dom";
import { accessRoute } from "../../constant/access-route";
import { haveAccess } from "../../functions/global";
import WuiHeader from "../../@framework/wui/components/Header";
import Error404 from "../../pages/Error/404";

interface Props {
  children: ReactChildren | ReactChild;
}

const ProtectedRoute = (props: Props) => {
  const acl = useAccess();
  const location = useLocation();

  const renderPage = useMemo(() => {
    const findRoute = accessRoute.find((route) =>
      location.pathname.includes(route.url)
    );

    if (findRoute) {
      if (!haveAccess(acl, findRoute.access)) {
        return (
          <section id={"auth-layout"}>
            <WuiHeader />

            <div className="container-wrapper">
              <div className="container">
                <Error404 />
              </div>
            </div>
          </section>
        );
      }
    }

    return <>{props.children}</>;
  }, [props.children, location.pathname, acl]);

  return renderPage;
};

export default ProtectedRoute;
