import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import _, { toLower } from "lodash";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { $clone } from "../../../../../../@framework/utilities";
import AppDefaultTableau from "../../../../../../components/Tableau";
import AccountRepository from "../../../../../../repositories/AccountRepository";
import { useAppSelector } from "../../../../../../stores/hooks";

var qs = require("qs");

interface Props {
  active: boolean;
  setParams?: Function;
}

const DashboardB2BTabB: React.FC<Props> = ({ active, setParams }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const {account} = useAppSelector((state) => state.system)
  // For DateRange Picker

  // Tableau
  const tableauReady = useState<boolean>(false);
  const tableauFilter = useState<string>('')

  const setFilterDataToQuery = (isReset: boolean = false) => {
    let params = {
      tab: "Tab B",
    };

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    }

    getDataDashboard();
  };

  const getDataDashboard = async () => {};

  const getDataSuperior = async () => {
      const userTableauCode = toLower(_.find(account?.user_superiors, {channel: 'B2B'})?.tableau_code || '')

      await AccountRepository.allSuperior().then((res: AxiosResponse) => {
          const tempData = res?.data?.data;

          let tempFilter = 'Channel=DISTRI'

          let userHOR = _.find(tempData?.B2B, {area_hierarchy: 'Region'})

          if (tempData?.B2B) {
              tempData.B2B.map((item: any) => {
                  if (toLower(item?.tableau_code) === 'ash') {
                      tempFilter += `,SL=${item.name}`
                  } else if (toLower(item?.tableau_code) === 'tsh') {
                      tempFilter += `,TSH=${item.name}`
                  } else if (toLower(item?.tableau_code) === 'hot') {
                      tempFilter += `,HOT=${item.name}`
                  }
              })
          }

          if (userTableauCode === 'hot') tempFilter += `,HOT=${account?.name}`
          else if (userTableauCode === 'tsh') tempFilter += `,TSH=${account?.name}`
          else if (userTableauCode === 'ash') tempFilter += `,SL=${account?.name}`
          else if (userTableauCode === 'sc') tempFilter += `,ASL=${account?.name}`

          if (userHOR) {
              tempFilter += `,Tableau Region=${userHOR?.area_hierarchy_name || ''}`
          }

          tableauFilter.set(tempFilter)

          tableauReady.set(true)
      }).catch((e: AxiosError) => {

      })
  }


    useEffect(() => {
        setFilterDataToQuery()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (active) {
            getDataSuperior()
        }
        // eslint-disable-next-line
    }, [active])

    return (
        <>
            {
                tableauReady.get() ? (
                    <AppDefaultTableau tab={'DETAILPERFORMANCEDISTRI'} filters={$clone(tableauFilter.get())}/>
                ) : ''
            }
        </>
    )
}

export default DashboardB2BTabB;
