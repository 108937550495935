import {api, createCancelTokenHandler} from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    select: function (params: any = null) {
        let url = `${endPoint()}/api/v1/multi/select/users`;
        return api.get(url, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
                .token,
        });
    },
    all: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/users`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
        });
    },
    show: function (id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/user/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
        });
    },
    create: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/user`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token
        });
    },
    update: function (id: number | string, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/multi/user/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
        });
    },
    delete: function (id: number | string, params: any = null) {
        return api.delete(`${endPoint()}/api/v1/multi/user/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
        });
    },
    updateStatus: function (id: number | string, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/multi/user/${id}/status`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.updateStatus.name].handleRequestCancellation().token
        });
    },
    validateEmail: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/user-validate-email`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.validateEmail.name].handleRequestCancellation().token
        });
    },
    export : function (params: any = null) {
        return api({
            url: `${endPoint()}/api/v1/multi/users`,
            params,
            method: 'GET',
            responseType: 'blob',
        })
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;