export const permissions = {
  ignore: "IGNORE",
  write_check_in_superior: "write check in superior",
  read_area_b2b: "read area b2b",
  write_area_b2b: "write area b2b",
  delete_area_b2b: "delete area b2b",
  write_cluster_b2b: "write cluster b2b",
  delete_cluster_b2b: "delete cluster b2b",
  read_cluster_b2b: "read cluster b2b",
  read_produk_table_b2b: "read produk table b2b",
  read_journey_plan_b2b: "read journey plan b2b",
  write_journey_plan_b2b: "write journey plan b2b",
  delete_journey_plan_b2b: "delete journey plan b2b",
  read_request_journey_plan_b2b: "read request journey plan b2b",
  write_status_request_journey_plan_b2b:
    "write status request journey plan b2b",
  read_promotion_b2b: "read promotion b2b",
  write_promotion_b2b: "write promotion b2b",
  delete_promotion_b2b: "delete promotion b2b",
  read_register_promotion_b2b: "read register promotion b2b",
  read_issue_tracking_b2b: "read issue tracking b2b",
  write_issue_tracking_b2b: "write issue tracking b2b",
  delete_issue_tracking_b2b: "delete issue tracking b2b",
  read_handover_stuff_b2b: "read handover stuff b2b",
  read_uniform_request_b2b: "read uniform request b2b",
  read_survey_b2b: "read survey b2b",
  write_survey_b2b: "write survey b2b",
  delete_survey_b2b: "delete survey b2b",
  read_master_kpi_b2b: "read master kpi b2b",
  write_master_kpi_b2b: "write master kpi b2b",
  delete_master_kpi_b2b: "delete master kpi b2b",
  read_master_kpi_b2c: "read master kpi b2c",
  write_master_kpi_b2c: "write master kpi b2c",
  delete_master_kpi_b2c: "delete master kpi b2c",
  read_item_kpi_b2b: "read item kpi b2b",
  write_item_kpi_b2b: "write item kpi b2b",
  delete_item_kpi_b2b: "delete item kpi b2b",
  read_item_kpi_b2c: "read item kpi b2c",
  write_item_kpi_b2c: "write item kpi b2c",
  delete_item_kpi_b2c: "delete item kpi b2c",
  read_user_kpi_b2b: "read user kpi b2b",
  write_user_kpi_b2b: "write user kpi b2b",
  read_user_kpi_b2c: "read user kpi b2c",
  write_user_kpi_b2c: "write user kpi b2c",
  read_target_kpi_b2b: "read target kpi b2b",
  write_target_kpi_b2b: "write target kpi b2b",
  delete_target_kpi_b2b: "delete target kpi b2b",
  read_target_kpi_b2c: "read target kpi b2c",
  write_target_kpi_b2c: "write target kpi b2c",
  delete_target_kpi_b2c: "delete target kpi b2c",
  read_store_kpi_b2c: "read store kpi b2c",
  write_store_kpi_b2c: "write store kpi b2c",
  delete_store_kpi_b2c: "delete store kpi b2c",
  read_dealer_b2b: "read dealer b2b",
  write_dealer_b2b: "write dealer b2b",
  delete_dealer_b2b: "delete dealer b2b",
  read_product_b2b: "read product b2b",
  write_product_b2b: "write product b2b",
  delete_product_b2b: "delete product b2b",
  read_brand_b2b: "read brand b2b",
  write_brand_b2b: "write brand b2b",
  delete_brand_b2b: "delete brand b2b",
  read_region_b2b: "read region b2b",
  write_region_b2b: "write region b2b",
  delete_region_b2b: "delete region b2b",
  read_territory_b2b: "read territory b2b",
  write_territory_b2b: "write territory b2b",
  delete_territory_b2b: "delete territory b2b",
  read_subterritory_b2b: "read subterritory b2b",
  write_subterritory_b2b: "write subterritory b2b",
  delete_subterritory_b2b: "delete subterritory b2b",
  read_sales_b2b: "read sales b2b",
  write_sales_b2b: "write sales b2b",
  delete_sales_b2b: "delete sales b2b",
  read_user_b2b: "read user b2b",
  write_user_b2b: "write user b2b",
  delete_user_b2b: "delete user b2b",
  read_access_role_b2b: "read access role b2b",
  write_access_role_b2b: "write access role b2b",
  delete_access_role_b2b: "delete access role b2b",
  read_superior_b2b: "read superior b2b",
  write_superior_b2b: "write superior b2b",
  delete_superior_b2b: "delete superior b2b",
  read_push_notification_b2b: "read push notification b2b",
  write_push_notification_b2b: "write push notification b2b",
  delete_push_notification_b2b: "delete push notification b2b",
  write_setting_journey_b2b: "write setting journey b2b",
  read_setting_journey_b2b: "read setting journey b2b",
  read_dashboard_sc: "read dashboard sc",
  write_check_in_sc: "write check in sc",
  write_update_location_sc: "write update location sc",
  read_journey_plan_sc: "read journey plan sc",
  read_request_journey_plan_sc: "read request journey plan sc",
  write_request_journey_plan_sc: "write request journey plan sc",
  delete_request_journey_plan_sc: "delete request journey plan sc",
  read_promotion_sc: "read promotion sc",
  read_register_promotion_sc: "read register promotion sc",
  write_register_promotion_sc: "write register promotion sc",
  read_push_notification_sc: "read push notification sc",
  read_account_sc: "read account sc",
  read_profile_sc: "read profile sc",
  write_profile_sc: "write profile sc",
  read_dashboard_promoter: "read dashboard promoter",
  write_check_in_promoter: "write check in promoter",
  read_handover_stuff_promoter: "read handover stuff promoter",
  write_handover_stuff_promoter: "write handover stuff promoter",
  delete_handover_stuff_promoter: "delete handover stuff promoter",
  read_uniform_request_promoter: "read uniform request promoter",
  write_uniform_request_promoter: "write uniform request promoter",
  delete_uniform_request_promoter: "delete uniform request promoter",
  read_promotion_promoter: "read promotion promoter",
  read_push_notification_promoter: "read push notification promoter",
  read_account_promoter: "read account promoter",
  read_profile_promoter: "read profile promoter",
  write_profile_promoter: "write profile promoter",
  read_dashboard_superior: "read dashboard superior",
  read_handover_stuff_superior: "read handover stuff superior",
  read_uniform_request_superior: "read uniform request superior",
  read_journey_plan_superior: "read journey plan superior",
  write_journey_plan_superior: "write journey plan superior",
  delete_journey_plan_superior: "delete journey plan superior",
  read_request_journey_plan_superior: "read request journey plan superior",
  write_status_request_journey_plan_superior:
    "write status request journey plan superior",
  read_sales_superior: "read sales superior",
  read_account_b2b: "read account b2b",
  write_account_b2b: "write account b2b",
  read_account_online: "read account online",
  write_account_online: "write account online",
  read_promotion_superior: "read promotion superior",
  read_bcp_superior: "read bcp superior",
  read_push_notification_superior: "read push notification superior",
  read_bcp_sc: "read bcp sc",
  read_promoter_superior: "read promoter superior",
  write_check_in_bcp_superior: "write check in bcp superior",
  read_account_superior: "read account superior",
  read_profile_superior: "read profile superior",
  write_profile_superior: "write profile superior",
  read_report_bcp_superior: "read report bcp superior",
  read_product_type_b2b: "read product type b2b",
  write_product_type_b2b: "write product type b2b",
  delete_product_type_b2b: "delete product type b2b",
  read_journey_plan_b2c: "read journey plan b2c",
  write_journey_plan_b2c: "write journey plan b2c",
  delete_journey_plan_b2c: "delete journey plan b2c",
  read_request_journey_plan_b2c: "read request journey plan b2c",
  write_status_request_journey_plan_b2c:
    "write status request journey plan b2c",
  read_promotion_b2c: "read promotion b2c",
  write_promotion_b2c: "write promotion b2c",
  delete_promotion_b2c: "delete promotion b2c",
  read_register_promotion_b2c: "read register promotion b2c",
  read_store_b2c: "read store b2c",
  write_store_b2c: "write store b2c",
  delete_store_b2c: "delete store b2c",
  read_issue_tracking_b2c: "read issue tracking b2c",
  write_issue_tracking_b2c: "write issue tracking b2c",
  delete_issue_tracking_b2c: "delete issue tracking b2c",
  read_product_b2c: "read product b2c",
  write_product_b2c: "write product b2c",
  delete_product_b2c: "delete product b2c",
  read_brand_b2c: "read brand b2c",
  write_brand_b2c: "write brand b2c",
  delete_brand_b2c: "delete brand b2c",
  read_business_unit_b2c: "read business unit b2c",
  write_business_unit_b2c: "write business unit b2c",
  delete_business_unit_b2c: "delete business unit b2c",
  read_business_unit_category_b2c: "read business unit category b2c",
  write_business_unit_category_b2c: "write business unit category b2c",
  delete_business_unit_category_b2c: "delete business unit category b2c",
  read_business_unit_sub_category_b2c: "read business unit sub category b2c",
  write_business_unit_sub_category_b2c: "write business unit sub category b2c",
  delete_business_unit_sub_category_b2c:
    "delete business unit sub category b2c",
  read_business_unit_channel_b2c: "read business unit channel b2c",
  write_business_unit_channel_b2c: "write business unit channel b2c",
  delete_business_unit_channel_b2c: "delete business unit channel b2c",
  read_region_b2c: "read region b2c",
  write_region_b2c: "write region b2c",
  delete_region_b2c: "delete region b2c",
  read_territory_b2c: "read territory b2c",
  write_territory_b2c: "write territory b2c",
  delete_territory_b2c: "delete territory b2c",
  read_subterritory_b2c: "read subterritory b2c",
  write_subterritory_b2c: "write subterritory b2c",
  delete_subterritory_b2c: "delete subterritory b2c",
  read_area_b2c: "read area b2c",
  write_area_b2c: "write area b2c",
  delete_area_b2c: "delete area b2c",
  read_user_b2c: "read user b2c",
  write_user_b2c: "write user b2c",
  delete_user_b2c: "delete user b2c",
  write_access_role_b2c: "write access role b2c",
  delete_access_role_b2c: "delete access role b2c",
  read_superior_b2c: "read superior b2c",
  write_superior_b2c: "write superior b2c",
  delete_superior_b2c: "delete superior b2c",
  read_push_notification_b2c: "read push notification b2c",
  write_push_notification_b2c: "write push notification b2c",
  delete_push_notification_b2c: "delete push notification b2c",
  read_account_b2c: "read account b2c",
  write_account_b2c: "write account b2c",
  write_setting_journey_b2c: "write setting journey b2c",
  read_setting_journey_b2c: "read setting journey b2c",
  read_location_b2c: "read location b2c",
  write_location_b2c: "write location b2c",
  delete_location_b2c: "delete location b2c",
  read_product_type_b2c: "read product type b2c",
  write_product_type_b2c: "write product type b2c",
  delete_product_type_b2c: "delete product type b2c",
  read_grafik_online: "read grafik online",
  read_grafik_headquarter: "read grafik head quarter online",
  read_dashboard_asl: "read dashboard asl",
  read_bcp_asl: "read bcp asl",
  write_check_in_asl: "write check in asl",
  write_update_location_asl: "write update location asl",
  read_journey_plan_asl: "read journey plan asl",
  read_request_journey_plan_asl: "read request journey plan asl",
  write_request_journey_plan_asl: "write request journey plan asl",
  delete_request_journey_plan_asl: "delete request journey plan asl",
  read_promotion_asl: "read promotion asl",
  read_push_notification_asl: "read push notification asl",
  read_account_asl: "read account asl",
  read_profile_asl: "read profile asl",
  write_profile_asl: "write profile asl",
  read_dealer_prospect_b2b: "read dealer prospect b2b",
  write_dealer_prospect_sc: "write dealer prospect sc",
  read_setting_radius_b2c: "read setting radius b2c",
  write_setting_radius_b2c: "write setting radius b2c",
  read_grafik_b2b_tab_a: "read grafik b2b tab a",
  read_grafik_b2b_tab_b: "read grafik b2b tab b",
  read_assistant_store_leader_b2c: "read assistant store leader b2c",
  read_grafik_b2c_tab_a: "read grafik b2c tab a",
  read_grafik_b2c_tab_o2o: "read grafik b2c tab o2o",
  read_grafik_b2c_tab_forecast: "read grafik b2c tab forecast",
  read_access_role_b2c: "read access role b2c",
  write_assistant_store_leader_b2c: "write assistant store leader b2c",
  delete_assistant_store_leader_b2c: "delete assistant store leader b2c",
  write_live_display_unit_b2c: "write live display unit b2c",
  read_live_display_unit_b2c: "read live display unit b2c",
  delete_live_display_unit_b2c: "delete live display unit b2c",
  read_superior_online: "read superior online",
  write_superior_online: "write superior online",
  delete_superior_online: "delete superior online",
  read_promotion_superior_b2b: "read promotion superior b2b",
  read_promotion_superior_b2c: "read promotion superior b2c",
  write_setting_radius_b2b: "write setting radius b2b",
  read_setting_radius_b2b: "read setting radius b2b",
  read_stock_opname_b2c: "read stock opname b2c",
  write_stock_opname_b2c: "write stock opname b2c",
  write_request_stock_opname_b2c: "write request stock opname revision b2c",
};
