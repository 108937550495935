import Table from "antd/es/table";
import React, {useEffect} from "react";
import {useState} from "@hookstate/core";
import {AxiosError, AxiosResponse} from "axios";
import Moment from "react-moment";
import DealerRepository from "../../../../../../../../../../repositories/DealerRepository";
import moment from "moment";
import {TChannels} from "../../../../../../../../../../utils";


interface Props {
    dealerId: number | string
    active: boolean,
    search: string,
    dateRange: moment.Moment[],
    sourceChannel?: TChannels
}

const TableStock: React.FC<Props> = (
    {
        dealerId,
        active,
        search = '',
        dateRange,
        sourceChannel = 'b2b'
    }
) => {
    const tablePage = useState<number>(1)
    const tablePerPage = useState<number>(10)
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: '',
        sortOrder: ''
    })
    const tableData = useState([]);
    const totalData = useState<number>(0)
    const channel = useState<TChannels>(sourceChannel)

    useEffect(() => {
        if (active) {
            getTableData(true)
        }

        // eslint-disable-next-line
    }, [active, search, dateRange])

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: search,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),
            start_date: dateRange[0].format('YYYY-MM-DD'),
            end_date: dateRange[1].format('YYYY-MM-DD')
        }

        await DealerRepository.allStocks(channel.get(), dealerId, params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {

            setTableLoading(false)
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        tablePage.set(pagination.current)
        tablePerPage.set(pagination.pageSize)

        if (sorter.order) {
            tableSort.sortField.set(sorter.field)
            tableSort.sortOrder.set(sorter.order)
        } else {
            tableSort.sortField.set(null)
            tableSort.sortOrder.set(null)
        }

        getTableData()
    }

    const columns: any = [
        {
            title: 'Tanggal',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            render: (text: any, record: any) => {
                return (
                    <Moment format="DD MMMM YYYY">
                        {text}
                    </Moment>
                )
            },
        },
        {
            title: 'Kode',
            dataIndex: 'product_code',
            key: 'product_code',
            sorter: true,
        },
        {
            title: 'Nama',
            dataIndex: 'product_name',
            key: 'product_name',
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: 'product_type',
            key: 'product_type',
            sorter: true,
        },
        {
            title: 'Stok Produk',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: true,
        }
    ]


    return (
        <>
            <Table
                rowKey={"report_id"}
                bordered
                columns={columns}
                dataSource={tableData.get()}
                loading={tableLoading}
                onChange={handleTableChange}
                pagination={{
                    current: tablePage.get(),
                    simple: true,
                    pageSize: tablePerPage.get(),
                    total: totalData.get()
                }}
            />
        </>
    )
}

export default TableStock;