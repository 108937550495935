import Table from "antd/lib/table";
import React, {useEffect} from "react";
import moment from "moment";
import {NumericFormat} from "react-number-format";
import Typography from "antd/es/typography";
import DealerRepository from "../../../../../../../../../../repositories/DealerRepository";
import {TChannels} from "../../../../../../../../../../utils";
import {useState} from "@hookstate/core";
import {useSearchParams} from "react-router-dom";
import {AxiosError, AxiosResponse} from "axios";
import Col from "antd/es/grid/col";
import WuiSelectSingle from "../../../../../../../../../../@framework/wui/components/Select/Single";
import {SALES_CONSULTANT, SALES_PROMOTER} from "../../../../../../../../../../constant";
import SuperiorRepository from "../../../../../../../../../../repositories/SuperiorRepository";
import Button from "antd/es/button";
import Row from "antd/es/grid/row";
import SalesRepository from "../../../../../../../../../../repositories/SalesRepository";
import {useIsMounted} from "../../../../../../../../../../@framework/utilities/hooks";
import {useTranslation} from "react-i18next";
import {Divider, Space} from "antd";

interface Props {
  channel?: TChannels;
  dealerId?: number | string
  salesId?: number | string
  type: "sales" | "dealer"
}

const { Title } = Typography;

const TableGAP: React.FC<Props> = (
    {
      channel = 'b2b',
      dealerId= 0,
      salesId= 0,
      type = "dealer"
    }
) => {
  // GAP Sales vs Target
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isMounted = useIsMounted()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  const tableSalesTargetPage = useState<number>(parseInt(searchParams.get('page') || '1'))
  const tableSalesTargetPerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
  const [tableSalesTargetLoading, setTableSalesTargetLoading] = React.useState<boolean>(false)
  const tableSalesTargetSort = useState<{
    sortField: any,
    sortOrder: any,
  }>({
    sortField: searchParams.get('sortField'),
    sortOrder: searchParams.get('sortOrder')
  })
  const tableSalesTargetData = useState([]);
  const totalSalesTargetData = useState<number>(0)
  const filterSales = useState(null);

  let salesTargetColumns: any = [
    {
      title: 'Periode',
      dataIndex: 'period',
      key: 'period',
      render: (text: any, record: any) => {
        return moment(text).format("MMM YYYY");
      },
    },
    {
      title: 'Sales',
      dataIndex: 'sales_code',
      key: 'sales_code',
    },
    {
      title: 'Nama SC',
      dataIndex: 'sales_name',
      key: 'sales_name',
    },
    {
      title: 'Brand',
      dataIndex: 'brand_name',
      key: 'brand_name',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
      render: (text: any) => {
        return (
            <NumericFormat
                value={text}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
            />
        );
      },
    },
    {
      title: 'Pencapaian Penjualan',
      dataIndex: 'achievement',
      key: 'achievement',
      render: (text: any) => {
        return (
            <NumericFormat
                value={text}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
            />
        );
      },
    },
    {
      title: 'Presentase',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (text: any) => {
        return (
            <NumericFormat
                suffix={'%'}
                value={text}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
            />
        );
      },
    },
    {
      title: 'Difference',
      dataIndex: 'difference',
      key: 'difference',
      render: (text: any) => {
        return (
            <NumericFormat
                value={text}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
            />
        );
      },
    }
  ]

  const handleTableSalesTargetChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    tableSalesTargetPage.set(pagination.current)
    tableSalesTargetPerPage.set(pagination.pageSize)

    if (sorter.order) {
      tableSalesTargetSort.sortField.set(sorter.field)
      tableSalesTargetSort.sortOrder.set(sorter.order)
    } else {
      tableSalesTargetSort.sortField.set(null)
      tableSalesTargetSort.sortOrder.set(null)
    }

    getDataSalesTarget()
  }

  const getDataSalesTarget = async (reset: boolean = false) => {
    setTableSalesTargetLoading(true)

    if (reset) tableSalesTargetPage.set(1);

    let params = {
      page: tableSalesTargetPage.get(),
      sales: salesId,
      order_by: tableSalesTargetSort.sortField.get(),
      sorted_by: tableSalesTargetSort.sortOrder.get(),
      per_page: tableSalesTargetPerPage.get()
    }

    if(filterSales.get()){
      Object.assign(params, {
        sales: filterSales.get()
      })
    }

    await DealerRepository.allSalesTarget(channel, dealerId, params).then((res: AxiosResponse) => {
      if (res.data.data) {
        tableSalesTargetData.set(res.data.data)
      }

      totalSalesTargetData.set(res.data?.total || 0)

      setTableSalesTargetLoading(false)
    }).catch((e: AxiosError) => {

      setTableSalesTargetLoading(false)
    })
  }

  const handleReset = () => {
    filterSales.set(null)
  }

  useEffect(() => {
    if (!isMounted) {
      getDataSalesTarget(true)
    }
    // eslint-disable-next-line
  }, [filterSales.get()])

  // GAP Program
  const [tableGapProgramLoading, setTableGapProgramLoading] = React.useState<boolean>(false)
  const tableGapProgramData = useState([]);

  const gapProgramColumns: any = [
    {
      title: 'GAP Program yang berjalan',
      colSpan: 2,
      dataIndex: 'promotion_title',
      key: 'promotion_title',
      align: 'left'
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      colSpan: 0,
      key: 'created_at',
      render: (text: any, record: any) => {
        return moment(text).format("DD MMM YYYY");
      },
    }
  ]

  const getDataGapProgram = async (reset: boolean = false) => {
    setTableGapProgramLoading(true)

    let params = {
      page: tableSalesTargetPage.get(),
      per_page: 999,
      dealers: dealerId,
      is_running: true,
      with: ['promotion']
    }

    await DealerRepository.allGapProgram(channel, params).then((res: AxiosResponse) => {
      if (res.data.data) {
        tableGapProgramData.set(res.data.data)
      }

      setTableGapProgramLoading(false)
    }).catch((e: AxiosError) => {

      setTableGapProgramLoading(false)
    })

  }

  useEffect(() => {
    if (type === 'dealer') {
      salesTargetColumns.unshift({
        title: 'Kode Dealer',
        dataIndex: 'dealer_code',
        key: 'dealer_code',
      })
    }

    getDataSalesTarget()
    getDataGapProgram()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <Title level={5}><strong>GAP Sales vs Target</strong></Title>

        <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
            size={15}
        >
          { type === 'dealer' && (
              <Row className="mb6" gutter={[10, 10]}>
                <Col className="gutter-row" xs={24} md={12} lg={6}>
                  <WuiSelectSingle
                      labelInValue={false}
                      value={filterSales.get()}
                      onChange={(value: any) => {
                        filterSales.set(value)
                      }}
                      style={{ width: '100%' }}
                      placeholder={t("select.placeholder", {
                        item: "Sales",
                      })}
                      repository={SalesRepository}
                      selectParams={{level: [SALES_CONSULTANT]}}>
                  </WuiSelectSingle>
                </Col>
                <Col>
                  <Button type="link" onClick={handleReset}>Reset</Button>
                </Col>
              </Row>
          )}

          <Table
              rowKey={"id"}
              bordered
              columns={salesTargetColumns}
              dataSource={tableSalesTargetData.get()}
              loading={tableSalesTargetLoading}
              onChange={handleTableSalesTargetChange}
              pagination={{
                current: tableSalesTargetPage.get(),
                simple: true,
                pageSize: tableSalesTargetPerPage.get(),
                total: totalSalesTargetData.get()
              }}
              scroll={{ x: "max-content" }}
          />
        </Space>
      </div>

      <Table
          rowKey="id"
          columns={gapProgramColumns}
          dataSource={tableGapProgramData.get()}
          loading={tableGapProgramLoading}
          pagination={false}
      />
    </>
  );
};

export default TableGAP;
