import Table from "antd/es/table";
import React, { useCallback, useEffect } from "react";
import { StockOpnameDetailProductTableProps } from "./interface";
import { Space, Typography } from "antd";
import Input from "antd/es/input";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import useDebounce from "../../@framework/utilities/hooks/useDebounce";
import { StockOpnameProduct } from "../stock-opname-product-export/interface";

const { Text } = Typography;

const StockOpnameDetailProductTable = ({
  data,
  revisionData = null,
  onChangeTable,
  tableLoading = false,
  status = "Good Stock",
  tabKey = "",
  totalData = 0,
}: StockOpnameDetailProductTableProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 400);

  const submittedColumns = [
    {
      title: "Kode Produk",
      dataIndex: "product_code",
      key: "product_code",
      render: (text: any) => <Text>{text}</Text>,
    },
    {
      title: "Nama Produk",
      dataIndex: "product_name",
      key: "product_name",
      render: (text: any) => <Text>{text}</Text>,
    },
    { title: "Stok Sistem", dataIndex: "system_stock", key: "system_stock" },
    {
      title: "Stok Toko",
      dataIndex: "store_stock",
      key: "store_stock",
      render: (text: any) => <Text>{text}</Text>,
    },
    {
      title: "Selisih",
      dataIndex: "diff",
      key: "diff",
      render: (text: any, record: StockOpnameProduct) => {
        const storeStock = record.store_stock || 0;
        const systemStock = record.system_stock || 0;
        return <Text>{systemStock - storeStock}</Text>;
      },
    },
    {
      title: "Keterangan",
      dataIndex: "description",
      key: "description",
      render: (text: string) => <Text>{text}</Text>,
    },
  ];

  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(5);
  const tableSort = useState({
    sortField: null,
    sortOrder: null,
  });

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setPage(pagination.current);
      setPerPage(pagination.pageSize);

      if (sorter.order) {
        tableSort.sortField.set(sorter.field);
        tableSort.sortOrder.set(sorter.order);
      } else {
        tableSort.sortField.set(null);
        tableSort.sortOrder.set(null);
      }

      onChangeTable({
        page: pagination.current,
        per_page: pagination.pageSize,
        search: debouncedSearch,
        status: status,
        ...(sorter.order
          ? {
              order_by: sorter.field,
              sorted_by: sorter.order,
            }
          : {}),
        revision_number: revisionData?.revision_number || 0,
      });
    },
    [debouncedSearch, status] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (revisionData && status && tabKey === status) {
      onChangeTable({
        page: page,
        status: status,
        per_page: perPage,
        search: debouncedSearch,
        order_by: tableSort.sortField.get(),
        sorted_by: tableSort.sortOrder.get(),
        revision_number: revisionData.revision_number,
      });
    }
  }, [debouncedSearch, tabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Input
        allowClear
        placeholder={t("common.filter.search.placeholder")}
        prefix={<SearchOutlined />}
        value={search}
        onChange={(value) => {
          setSearch(value.target.value);
          setPage(1)
        }}
        style={{ marginBottom: 20 }}
      />

      {revisionData && (
        <Space size={8} direction="vertical" style={{ marginBottom: 20 }}>
          <Text>
            {`Last Updated : ${moment(revisionData.updated_at).format(
              "DD MMMM YYYY | HH:mm"
            )} WIB`}
          </Text>
          <Text>
            Stock Date :{" "}
            {moment(revisionData.stock_date).format("DD MMMM YYYY")}
          </Text>
        </Space>
      )}

      <Table
        rowKey={"id"}
        style={{
          overflowX: "auto",
        }}
        columns={submittedColumns}
        dataSource={data.get()}
        onChange={handleTableChange}
        loading={tableLoading}
        pagination={{
          current: page,
          pageSize: perPage,
          total: totalData,
        }}
      />
    </>
  );
};

export default StockOpnameDetailProductTable;
