import React from "react";
import WuiContainer from "../../../../@framework/wui/components/Container";
import Button from "antd/es/button";
import {PlusOutlined} from "@ant-design/icons";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";


const AppSettingsUsers: React.FC<any> = () => {

    const breadcrumbs = [
        {
            label: 'Settings',
            link: '/settings'
        },
        {
            label: 'Users',
            link: '/settings/users'
        },
    ]

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle breadcrumbs={breadcrumbs} title={'Setting Users'}>
                    <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
                </WuiSectionTitle>
            </WuiContainer>
        </>
    )
}

export default AppSettingsUsers
