import { useState } from "@hookstate/core";
import { Col, Row } from "antd";
import { Tag } from "antd/es";
import Button from "antd/es/button";
import Card from "antd/es/card";
import PageHeader from "antd/es/page-header";
import Space from "antd/es/space";
import Text from "antd/es/typography/Text";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import RequestJourneyPlanRepository from "../../../../../../repositories/RequestJourneyPlanRepository";

let title = "Permintaan";

const statusTagColor: any = {
  Ditolak: "error",
  Pending: "warning",
  Diterima: "success",
};

const StatusTag = ({ status }: any) => {
  const allowedStatus = ["Diterima", "Ditolak", "Pending"];
  if (!allowedStatus.includes(status)) {
    return <Text>-</Text>;
  }
  return <Tag color={statusTagColor[status]}>{status}</Tag>;
};

const AppJourneyPlanB2CFormRequestApproval: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  // Form
  const disable = useState(false);
  const tabKey = useState("1");
  const [data, setData] = React.useState<any>({});

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);
    await RequestJourneyPlanRepository.show(id, "b2c", {
      with: ["store"],
    })
      .then((res: AxiosResponse) => {
        const resData = res.data?.data || {};
        setData(resData);
        disable.set(false);
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };
  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />
        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card>
              <Space
                style={{
                  width: "100%",
                  maxHeight: tabKey.get() === "1" ? "100%" : 0,
                  opacity: tabKey.get() === "1" ? 1 : 0,
                }}
                direction="vertical"
                size={25}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={_.get(data, "assistant_level", "-")} />
                  <div className="input-section">
                    <Text strong>{_.get(data, "assistant_name", "-")}</Text>
                  </div>
                </div>
              </Space>
            </Card>

            <br />

            <Card>
              <Space
                style={{
                  width: "100%",
                  maxHeight: tabKey.get() === "1" ? "100%" : 0,
                  opacity: tabKey.get() === "1" ? 1 : 0,
                }}
                direction="vertical"
                size={25}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Tanggal Kunjungan"} />
                  <div className="input-section">
                    <Text strong>
                      {data.visit_date
                        ? moment(data.visit_date).format("DD MMMM YYYY")
                        : "-"}
                    </Text>
                  </div>
                </div>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <div className="wui-form-group type-column">
                      <WuiFormTitle title={"Toko yang dituju"} />
                      <div className="input-section">
                        <Text strong>{_.get(data, "store_name", "-")}</Text>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="wui-form-group type-column">
                      <WuiFormTitle title={"Alasan"} />
                      <div className="input-section">
                        <Text strong>{_.get(data, "reason", "-")}</Text>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Space>
            </Card>

            <br />

            <Card title="Approval">
              <Space
                style={{
                  width: "100%",
                  maxHeight: tabKey.get() === "1" ? "100%" : 0,
                  opacity: tabKey.get() === "1" ? 1 : 0,
                }}
                direction="vertical"
                size={25}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Supervisor"} />
                  <div className="input-section">
                    <Text strong>{_.get(data, "approval_name", "-")}</Text>
                  </div>
                </div>
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Tanggal"} />
                  <div className="input-section">
                    <Text strong>
                      {data.approved_at
                        ? moment(data.approved_at).format("DD MMMM YYYY")
                        : "-"}
                    </Text>
                  </div>
                </div>
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Status"} />
                  <div className="input-section">
                    <StatusTag status={_.get(data, "status", "-")} />
                  </div>
                  {data.remark && (
                    <div className="input-section">
                      <Text>{_.get(data, "remark", "-")}</Text>
                    </div>
                  )}
                </div>
              </Space>
            </Card>
          </Col>
        </Row>

        <div className="wui-form-btn-group">
          <Button
            className="wui-btn"
            size={"large"}
            onClick={() => navigate(-1)}
          >
            {t("common.button.back")}
          </Button>
        </div>
      </WuiContainer>
    </>
  );
};

export default AppJourneyPlanB2CFormRequestApproval;
