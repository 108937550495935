import { useState } from "@hookstate/core";
import { Col, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import Button from "antd/es/button";
import Card from "antd/es/card";
import PageHeader from "antd/es/page-header";
import Space from "antd/es/space";
import Text from "antd/es/typography/Text";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../../@framework/wui/components/Container";
import WuiFormTitle from "../../../../../../../@framework/wui/components/Form/Title";
import JourneyPlanRepository from "../../../../../../../repositories/JourneyPlanRepository";
import AssistantStoreLeaderRepository from "../../../../../../../repositories/AssistantStoreLeaderRepository";
import {
  handleBackendError,
  openNotification,
} from "../../../../../../../functions/global";
import WuiSelectSingle from "../../../../../../../@framework/wui/components/Select/Single";
import { permissions } from "../../../../../../../constant/permissions";
import Accessible from "../../../../../../../@framework/wui/components/Accessible";

const { Option } = Select;

let title = "Journey Plan";

const AppJourneyPlanB2CFormPlanDetails: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  const [storeOptions, setStoreOptions] = React.useState<any>([]);

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const tabKey = useState("1");
  const [data, setData] = React.useState<any>({});
  const isEdit = useState(false);
  const loading = useState(false);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEdit.get()) {
      const formData = {
        assistant_id: {
          value: data?.assistant?.id,
          label: data?.assistant?.name,
        },
        store_id: {
          value: data?.store?.id,
          label: data?.store?.name,
        },
        date: moment(data?.date),
        description: data?.description,
      };

      form.setFieldsValue(formData);

      getAslStores(data?.assistant?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit.get()]);

  const getData = async () => {
    disable.set(true);

    await JourneyPlanRepository.show(id, "b2c", {
      with: [
        "user",
        "assistant",
        "store",
        "requestJourneyPlanApproved.approval",
      ],
    })
      .then((res: AxiosResponse) => {
        const resData = res.data?.data || {};

        setData(resData);

        disable.set(false);
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  const getAslStores = (id: any, clear: boolean = true) => {
    AssistantStoreLeaderRepository.show(id, "b2c", { with: ["stores"] })
      .then((res: AxiosResponse) => {
        let data = res.data?.data;

        if (data.assistant_stores) {
          if (clear) {
            form.setFieldsValue({
              ...form.getFieldsValue(true),
              journey_plans: [
                {
                  date: "",
                  stores: [
                    {
                      store: null,
                      reason: "",
                    },
                  ],
                },
              ],
            });
          }

          setStoreOptions(data.assistant_stores);
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      assistant_id: data.assistant_id?.value,
      store_id: data.store_id?.value,
      date: `${moment(form.getFieldValue("date"))
        .startOf("day")
        .format("YYYY-MM-DD[T]HH:mm:ss")}+00:00`,
      description: data.description,
    };

    JourneyPlanRepository.update(id, "b2c", payload)
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  const renderForm = () => {
    return (
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onFinish}
        onValuesChange={(changedValues) => {
          if (changedValues.assistant_id) {
            getAslStores(changedValues.assistant_id.value);
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card title={""} className="mb16">
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
                size={0}
              >
                <div className="wui-form-group type-column">
                  <div className="input-section">
                    <Form.Item
                      name="assistant_id"
                      label={"Tugaskan ke"}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: "Tugaskan ke",
                          }),
                        },
                      ]}
                    >
                      <WuiSelectSingle
                        repository={AssistantStoreLeaderRepository}
                        selectParams={{
                          channel: "b2c",
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Space>
            </Card>

            <Card>
              <div className="wui-form-group type-column">
                <WuiFormTitle title={"Tanggal"} required={true} />

                <div className="input-section">
                  <Form.Item
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", { item: "Tanggal" }),
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={disable.get()}
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current < moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      size={"large"}
                      onChange={() => {}}
                      placeholder={t("common.text.input", { item: "Tanggal" })}
                    />
                  </Form.Item>
                </div>
              </div>

              <Row gutter={16}>
                <Col xs={24}>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="store_id"
                        label={"Toko yang dituju"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Toko yang dituju",
                            }),
                          },
                        ]}
                      >
                        <Select
                          labelInValue={true}
                          disabled={storeOptions.length === 0}
                          size={"large"}
                          placeholder="Pilih toko"
                          style={{
                            width: "100%",
                          }}
                        >
                          {storeOptions.map((item: any) => (
                            <Option value={item.id} key={item.id}>
                              <div className="custom-dealer-option">
                                <div>
                                  {item?.name} - {item?.code}
                                </div>
                                <div className={"address"}>{item?.address}</div>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Alasan"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Alasan",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Alasan",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <div className="wui-form-btn-group">
          <Button
            className="wui-btn"
            size={"large"}
            onClick={() => navigate(-1)}
          >
            {t("common.button.cancel")}
          </Button>
          <Accessible access={permissions.write_journey_plan_b2c}>
            <Spin spinning={loading.get()}>
              <Button
                className="wui-btn"
                htmlType="submit"
                type="primary"
                size={"large"}
                disabled={disable.get()}
              >
                {t("common.button.save")}
              </Button>
            </Spin>
          </Accessible>
        </div>
      </Form>
    );
  };

  const renderDetail = () => {
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card title="Informasi">
              <Space
                style={{
                  width: "100%",
                  maxHeight: tabKey.get() === "1" ? "100%" : 0,
                  opacity: tabKey.get() === "1" ? 1 : 0,
                }}
                direction="vertical"
                size={25}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Tanggal Kunjungan"} />
                  <div className="input-section">
                    <Text strong>
                      {data.date
                        ? moment(data.date).format("DD MMMM YYYY")
                        : "-"}
                    </Text>
                  </div>
                </div>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <div className="wui-form-group type-column">
                      <WuiFormTitle title={"Toko yang dituju"} />
                      <div className="input-section">
                        <Text strong>{_.get(data, "store.name", "-")}</Text>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="wui-form-group type-column">
                      <WuiFormTitle title={"Alasan"} />
                      <div className="input-section">
                        <Text strong>{_.get(data, "description", "-")}</Text>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Alamat Toko"} />
                  <div className="input-section">
                    <Text strong>{_.get(data, "store.address", "-")}</Text>
                  </div>
                </div>
              </Space>
            </Card>

            <br />

            <Card title="Informasi Assistant Leader">
              <Space
                style={{
                  width: "100%",
                  maxHeight: tabKey.get() === "1" ? "100%" : 0,
                  opacity: tabKey.get() === "1" ? 1 : 0,
                }}
                direction="vertical"
                size={25}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Name"} />
                  <div className="input-section">
                    <Text strong>{_.get(data, "assistant.name", "-")}</Text>
                  </div>
                </div>
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Email"} />
                  <div className="input-section">
                    <Text strong>{_.get(data, "assistant.email", "-")}</Text>
                  </div>
                </div>
              </Space>
            </Card>

            <br />

            {_.get(data, "request_journey_plan_approved") && (
              <Card title="Approval">
                <Space
                  style={{
                    width: "100%",
                    maxHeight: tabKey.get() === "1" ? "100%" : 0,
                    opacity: tabKey.get() === "1" ? 1 : 0,
                  }}
                  direction="vertical"
                  size={25}
                >
                  <div className="wui-form-group type-column">
                    <WuiFormTitle title={"Supervisor"} />
                    <div className="input-section">
                      <Text strong>
                        {_.get(
                          data,
                          "request_journey_plan_approved.approval.name"
                        )}
                      </Text>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <WuiFormTitle title={"Tanggal"} />
                    <div className="input-section">
                      <Text strong>
                        {moment(
                          _.get(
                            data,
                            "request_journey_plan_approved.approval.approved_at"
                          )
                        ).format("DD MMM YYYY | HH:mm")}{" "}
                      </Text>
                    </div>
                  </div>
                </Space>
              </Card>
            )}
          </Col>
        </Row>
        <div className="wui-form-btn-group">
          <Button
            className="wui-btn"
            size={"large"}
            onClick={() => navigate(-1)}
          >
            {t("common.button.back")}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          extra={
            id ? (
              <Accessible access={permissions.write_journey_plan_b2c}>
                <Button
                  disabled={isEdit.get()}
                  type="primary"
                  onClick={() => isEdit.set(true)}
                >
                  Ubah
                </Button>
              </Accessible>
            ) : (
              ""
            )
          }
        />

        {isEdit.get() ? renderForm() : renderDetail()}
      </WuiContainer>
    </>
  );
};

export default AppJourneyPlanB2CFormPlanDetails;
