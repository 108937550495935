import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import PageHeader from "antd/es/page-header";
import WuiContainer from "../../../../@framework/wui/components/Container";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Button from "antd/es/button";
import { Spin } from "antd";
import Text from "antd/es/typography/Text";
import WuiSelectSingle, {
  valueProps,
} from "../../../../@framework/wui/components/Select/Single";
import WuiUploadImages from "../../../../@framework/wui/components/UploadImages";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import BrandRepository from "../../../../repositories/BrandRepository";
import Divider from "antd/es/divider";
import Select from "antd/es/select";
import StoreRepository from "../../../../repositories/StoreRepository";
import WuiSelectMulti from "../../../../@framework/wui/components/Select/Multi";
import ProductRepository from "../../../../repositories/ProductRepository";
import LiveDisplayUnitRepository from "../../../../repositories/LiveDisplayUnitRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  haveAccess,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import useAccess from "../../../../@framework/utilities/hooks/useAccess";
import moment from "moment";
import WuiFormTitle from "../../../../@framework/wui/components/Form/Title";
import { permissions } from "../../../../constant/permissions";
import Accessible from "../../../../@framework/wui/components/Accessible";

const { Option } = Select;

const productStatusOptions = [
  { id: "Transfer", name: "Transfer" },
  { id: "Blank", name: "Blank" },
  { id: "Installed", name: "Installed" },
  { id: "On Service", name: "On Service" },
];

type FormData = {
  store_code_id: valueProps;
  store_name_id: valueProps;
  images: any[];
  live_display_unit_tables: {
    id: number | string;
    name: string;
    brand_ids: { id: string; name: string }[];
    products: {
      id: number | string;
      product_id: valueProps;
      material_code: string;
      imei: string;
      status: any;
      images: any[];
    }[];
  }[];
};

let title = "LDU";

const AppLDUForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEdit = useState(!id);
  const navigate = useNavigate();
  const acl = useAccess();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const [data, setData] = React.useState<any>(null);

  const breadcrumbs = [
    {
      label: "LDU",
      link: "/ldu",
    },
    id
      ? {
          label: "Detail LDU",
          link: `/ldu/${id}`,
        }
      : {
          label: "Tambah LDU",
          link: "/ldu/form",
        },
  ];

  // Form
  const [form] = Form.useForm();

  const [tempBrandIds, setTempBrandIds] = React.useState<any[]>([]);

  const setBrandIds = useCallback((index: number, value: any[]) => {
    const mappingValue = value.length > 0 ? value.map((item) => item.id) : [];

    setTempBrandIds((prevState) => {
      const tempState = [...prevState];
      const findIndexState = tempState.findIndex(
        (temp) => temp.key === `key-${index}`
      );
      if (findIndexState > -1) {
        tempState[findIndexState].brandIds = mappingValue;
      } else {
        tempState.push({
          key: `key-${index}`,
          brandIds: mappingValue,
        });
      }

      return tempState;
    });
  }, []);

  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: FormData) => {
    loading.set(true);
    const formData = new FormData();
    if (id) {
      formData.append("_method", "PUT");
    }

    formData.append("store_id", data.store_code_id.value as string);

    if (data?.images && data?.images.length > 0) {
      data.images.forEach((item) => {
        if (item?.status && item?.status === "done") {
          formData.append("images_keep[]", item.id);
          formData.append("images[]", item);
        } else {
          formData.append("images[]", item);
        }
      });
    }

    if (
      data?.live_display_unit_tables &&
      data?.live_display_unit_tables.length > 0
    ) {
      data?.live_display_unit_tables.forEach((table, tableIndex) => {
        formData.append(
          `live_display_unit_tables[${tableIndex}][name]`,
          table.name
        );
        table.brand_ids.forEach((brand) => {
          formData.append(
            `live_display_unit_tables[${tableIndex}][brand_ids][]`,
            brand.id as string
          );
        });

        if (table?.id) {
          formData.append(
            `live_display_unit_tables[${tableIndex}][id]`,
            table.id as string
          );
        } else {
          if (id) {
            formData.append(`live_display_unit_tables[${tableIndex}][id]`, "0");
          }
        }

        if (table.products && table.products.length > 0) {
          table.products.forEach((product, productIndex) => {
            if (product?.id) {
              formData.append(
                `live_display_unit_tables[${tableIndex}][products][${productIndex}][id]`,
                product.id as string
              );
            } else {
              if (id) {
                formData.append(
                  `live_display_unit_tables[${tableIndex}][products][${productIndex}][id]`,
                  "0"
                );
              }
            }

            formData.append(
              `live_display_unit_tables[${tableIndex}][products][${productIndex}][material_code]`,
              product.material_code
            );
            formData.append(
              `live_display_unit_tables[${tableIndex}][products][${productIndex}][imei]`,
              product.imei
            );

            if (product?.status && typeof product?.status === "string") {
              formData.append(
                `live_display_unit_tables[${tableIndex}][products][${productIndex}][status]`,
                product.status as string
              );
            } else {
              formData.append(
                `live_display_unit_tables[${tableIndex}][products][${productIndex}][status]`,
                product?.status?.value as string
              );
            }

            formData.append(
              `live_display_unit_tables[${tableIndex}][products][${productIndex}][product_id]`,
              product.product_id.value as string
            );

            product.images.forEach((item) => {
              if (item?.status && item?.status === "done") {
                formData.append(
                  `live_display_unit_tables_${tableIndex}_products_${productIndex}_images_keep[]`,
                  item.id
                );
                formData.append(
                  `live_display_unit_tables_${tableIndex}_products_${productIndex}_images[]`,
                  item
                );
                formData.append(
                  `live_display_unit_tables[${tableIndex}][products][${productIndex}][images][]`,
                  item
                );
              } else {
                formData.append(
                  `live_display_unit_tables_${tableIndex}_products_${productIndex}_images[]`,
                  item
                );
                formData.append(
                  `live_display_unit_tables[${tableIndex}][products][${productIndex}][images][]`,
                  item
                );
              }
            });
          });
        }
      });
    }

    await (id
      ? LiveDisplayUnitRepository.update(id, formData)
      : LiveDisplayUnitRepository.create(formData)
    )
      .then((response: AxiosResponse) => {
        navigate(-1);

        if (id) {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        }

        loading.set(false);
      })
      .catch((err: AxiosError) => {
        handleBackendError(err, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData().then();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = useCallback(async () => {
    if (!id) {
      return;
    }

    disable.set(true);

    await LiveDisplayUnitRepository.show(id, {
      with: [
        "tables.products.productImages",
        "images",
        "store",
        "tables.brands",
        "tables.products.product",
      ],
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        setData(responseData);

        form.setFieldsValue({
          store_code_id: {
            value: responseData.store.id,
            label: responseData.store.code,
          },
          store_name_id: {
            value: responseData.store.id,
            label: responseData.store.name,
          },
          images: toFileList(responseData.images),
          live_display_unit_tables: responseData.tables.map(
            (table: any, index: number) => {
              if (table?.brands.length > 0) {
                setBrandIds(index, table.brands);
              }

              return {
                id: table.id,
                name: table.name,
                brand_ids: table.brands.map((brand: any) => ({
                  id: brand.id,
                  name: brand.name,
                })),
                products: table.products.map((tableProduct: any) => ({
                  id: tableProduct.id,
                  imei: tableProduct.imei,
                  material_code: tableProduct.material_code,
                  product_id: {
                    value: tableProduct["product"].id,
                    label: tableProduct["product"].name,
                  },
                  status: {
                    value: tableProduct.status,
                    label: tableProduct.status,
                  },
                  images: toFileList(tableProduct.product_images),
                })),
              };
            }
          ),
        });

        if (haveAccess(acl, permissions.write_live_display_unit_b2c)) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e);
      });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeStore = useCallback(
    (data: any, isCode: boolean) => {
      if (isCode) {
        form.setFieldValue("store_name_id", {
          value: data.value,
        });
      } else {
        form.setFieldValue("store_code_id", {
          value: data.value,
        });
      }
    },
    [form]
  );

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={""} breadcrumbs={breadcrumbs} />

        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          extra={
            id ? (
              <Accessible access={permissions.write_live_display_unit_b2c}>
              <Button
                disabled={isEdit.get()}
                type="primary"
                onClick={() => isEdit.set(true)}
              >
                Ubah
              </Button>
              </Accessible>
            ) : (
              ""
            )
          }
        />

        <Form
          hideRequiredMark={!isEdit.get()}
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="store_code_id"
                        label={"Kode"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Kode",
                            }),
                          },
                        ]}
                      >
                        {isEdit.get() ? (
                          <WuiSelectSingle
                            disabled={disable.get()}
                            repository={StoreRepository}
                            onChange={(data) => onChangeStore(data, true)}
                            selectParams={{
                              channel: "b2c",
                              has_business_unit: true
                            }}
                            labelKey="code"
                          />
                        ) : (
                          <div className="input-section">
                            <Text>{data?.store?.code || "-"}</Text>
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="store_name_id"
                        label={"Nama Toko"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Nama Toko",
                            }),
                          },
                        ]}
                      >
                        {isEdit.get() ? (
                          <WuiSelectSingle
                            disabled={disable.get()}
                            repository={StoreRepository}
                            onChange={(data) => onChangeStore(data, false)}
                            selectParams={{
                              channel: "b2c",
                              has_business_unit: true
                            }}
                            delay={1000}
                          />
                        ) : (
                          <div className="input-section">
                            <Text>{data?.store?.name || "-"}</Text>
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      {isEdit.get() && (
                        <Form.Item
                          name="images"
                          label={"Gambar"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Gambar",
                              }),
                            },
                          ]}
                        >
                          <WuiUploadImages
                            max={5}
                            disabled={disable.get() || !isEdit.get()}
                          />
                        </Form.Item>
                      )}

                      {!isEdit.get() && data && (
                        <>
                          <WuiFormTitle title={"Gambar"} />

                          <div
                            className="input-section"
                            style={{ marginTop: 8 }}
                          >
                            <WuiUploadImages
                              max={5}
                              value={toFileList(data.images)}
                              disabled={disable.get() || !isEdit.get()}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Space>
              </Card>

              <Form.List name="live_display_unit_tables">
                {(fields, { add, remove }) => (
                  <>
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size={16}
                      className={"mb16"}
                    >
                      {fields.map((field, index) => (
                        <Card
                          key={field.key}
                          title={`Table ${field.name + 1}`}
                          extra={
                            isEdit.get() && (
                              <DeleteOutlined
                                onClick={() => remove(field.name)}
                              />
                            )
                          }
                        >
                          <Space
                            style={{
                              width: "100%",
                            }}
                            direction="vertical"
                            size={5}
                          >
                            <div className="wui-form-group type-column">
                              <div className="input-section">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Nama Table",
                                      }),
                                    },
                                  ]}
                                >
                                  {isEdit.get() ? (
                                    <Input
                                      size={"large"}
                                      placeholder={t("common.text.input", {
                                        item: "Nama Table",
                                      })}
                                    />
                                  ) : (
                                    <div className="input-section">
                                      <Text>
                                        {data?.tables[index]?.name || "-"}
                                      </Text>
                                    </div>
                                  )}
                                </Form.Item>
                              </div>
                            </div>

                            <div className="wui-form-group type-column">
                              <div className="input-section">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "brand_ids"]}
                                  label={"Brand"}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Brand",
                                      }),
                                    },
                                  ]}
                                >
                                  {isEdit.get() ? (
                                    <WuiSelectMulti
                                      disabled={disable.get()}
                                      repository={BrandRepository}
                                      onChange={(value) =>
                                        setBrandIds(field.name, value)
                                      }
                                    />
                                  ) : (
                                    <div className="input-section">
                                      <Text>
                                        {data?.tables[index]?.brands
                                          .map((brand: any) => brand.name)
                                          .join(", ") || "-"}
                                      </Text>
                                    </div>
                                  )}
                                </Form.Item>
                              </div>
                            </div>

                            <Card>
                              <Form.List name={[field.name, "products"]}>
                                {(products, { add, remove }) => (
                                  <>
                                    <Space
                                      style={{
                                        width: "100%",
                                      }}
                                      direction="vertical"
                                      size={16}
                                    >
                                      {products.map((product) => (
                                        <Space
                                          key={product.key}
                                          style={{
                                            width: "100%",
                                          }}
                                          direction="vertical"
                                          size={14}
                                        >
                                          <Row
                                            justify={"space-between"}
                                            align={"middle"}
                                          >
                                            <Col>Produk {product.name + 1}</Col>
                                            {isEdit.get() && (
                                              <Col>
                                                <DeleteOutlined
                                                  onClick={() =>
                                                    remove(product.name)
                                                  }
                                                />
                                              </Col>
                                            )}
                                          </Row>

                                          <div className="wui-form-group type-column">
                                            <div className="input-section">
                                              <Form.Item
                                                {...product}
                                                name={[
                                                  product.name,
                                                  "product_id",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t(
                                                      "validation.required",
                                                      {
                                                        item: "Product",
                                                      }
                                                    ),
                                                  },
                                                ]}
                                              >
                                                {isEdit.get() ? (
                                                  <WuiSelectSingle
                                                    disabled={disable.get()}
                                                    repository={
                                                      ProductRepository
                                                    }
                                                    selectParams={{
                                                      brand: tempBrandIds.find(
                                                        (temp) =>
                                                          temp.key ===
                                                          `key-${field.name}`
                                                      )?.brandIds,
                                                    }}
                                                    onChange={(
                                                      value,
                                                      options: any
                                                    ) => {
                                                      form.setFieldValue(
                                                        [
                                                          `live_display_unit_tables`,
                                                          `${field.name}`,
                                                          `products`,
                                                          `${product.name}`,
                                                          `material_code`,
                                                        ],
                                                        String(
                                                          options["data-code"]
                                                        )
                                                      );
                                                    }}
                                                    customOptions={(
                                                      item: any
                                                    ) => {
                                                      return (
                                                        <Option
                                                          key={item.id}
                                                          value={item.id}
                                                          data-code={item?.code}
                                                        >
                                                          {item?.name}
                                                        </Option>
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <div className="input-section">
                                                    <Text>
                                                      {data?.tables[index]
                                                        .products[product.name][
                                                        "product"
                                                      ].name || "-"}
                                                    </Text>
                                                  </div>
                                                )}
                                              </Form.Item>
                                            </div>
                                          </div>

                                          <Row gutter={[16, 16]}>
                                            <Col span={5}>
                                              {isEdit.get() && (
                                                <Form.Item
                                                  {...product}
                                                  name={[
                                                    product.name,
                                                    "images",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: t(
                                                        "validation.required",
                                                        {
                                                          item: "Gambar",
                                                        }
                                                      ),
                                                    },
                                                  ]}
                                                >
                                                  <WuiUploadImages max={1} />
                                                </Form.Item>
                                              )}

                                              {!isEdit.get() && data && (
                                                <WuiUploadImages
                                                  max={1}
                                                  value={toFileList(
                                                    data?.tables[index]
                                                      .products[product.name]
                                                      .product_images
                                                  )}
                                                  disabled={
                                                    disable.get() ||
                                                    !isEdit.get()
                                                  }
                                                />
                                              )}
                                            </Col>
                                            <Col span={19}>
                                              <Row gutter={[16, 16]}>
                                                <Col span={12}>
                                                  <div className="wui-form-group type-column">
                                                    <div className="input-section">
                                                      <Form.Item
                                                        {...product}
                                                        noStyle
                                                        shouldUpdate={(
                                                          prevValues,
                                                          currentValues
                                                        ) =>
                                                          prevValues
                                                            ?.live_display_unit_tables[
                                                            field.name
                                                          ]?.products[
                                                            product.name
                                                          ]?.product_id !==
                                                          currentValues
                                                            ?.live_display_unit_tables[
                                                            field.name
                                                          ]?.products[
                                                            product.name
                                                          ]?.product_id
                                                        }
                                                      >
                                                        <Form.Item
                                                          {...product}
                                                          name={[
                                                            product.name,
                                                            "material_code",
                                                          ]}
                                                          label={
                                                            "Material Code"
                                                          }
                                                          style={{
                                                            marginBottom: 10,
                                                          }}
                                                        >
                                                          {isEdit.get() ? (
                                                            <Input
                                                              disabled
                                                              placeholder={t(
                                                                "common.text.input",
                                                                {
                                                                  item: "Material Code",
                                                                }
                                                              )}
                                                            />
                                                          ) : (
                                                            <div className="input-section">
                                                              <Text>
                                                                {data?.tables[
                                                                  index
                                                                ].products[
                                                                  product.name
                                                                ]
                                                                  .material_code ||
                                                                  "-"}
                                                              </Text>
                                                            </div>
                                                          )}
                                                        </Form.Item>
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col span={12}>
                                                  <div className="wui-form-group type-column">
                                                    <div className="input-section">
                                                      <Form.Item
                                                        {...product}
                                                        name={[
                                                          product.name,
                                                          "imei",
                                                        ]}
                                                        label={"IMEI"}
                                                        style={{
                                                          marginBottom: 10,
                                                        }}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: t(
                                                              "validation.required",
                                                              {
                                                                item: "IMEI",
                                                              }
                                                            ),
                                                          },
                                                        ]}
                                                      >
                                                        {isEdit.get() ? (
                                                          <Input
                                                            placeholder={t(
                                                              "common.text.input",
                                                              {
                                                                item: "IMEI",
                                                              }
                                                            )}
                                                          />
                                                        ) : (
                                                          <div className="input-section">
                                                            <Text>
                                                              {data?.tables[
                                                                index
                                                              ].products[
                                                                product.name
                                                              ].imei || "-"}
                                                            </Text>
                                                          </div>
                                                        )}
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                </Col>
                                              </Row>

                                              {id &&
                                                data &&
                                                data?.tables[index]?.products[
                                                  product.name
                                                ] && (
                                                  <Space direction="vertical">
                                                    <Text>
                                                      Latest Updated:{" "}
                                                      {moment(
                                                        data?.tables[index]
                                                          .products[
                                                          product.name
                                                        ].last_update
                                                      ).format(
                                                        "DD MMMM YYYY | HH:mm"
                                                      )} WIB
                                                    </Text>
                                                    <Text>
                                                      Created:{" "}
                                                      {moment(
                                                        data?.tables[index]
                                                          .products[
                                                          product.name
                                                        ].created_at
                                                      ).format(
                                                        "DD MMMM YYYY | HH:mm"
                                                      )}{" "} WIB
                                                    </Text>
                                                  </Space>
                                                )}
                                            </Col>
                                          </Row>

                                          <div className="wui-form-group type-column">
                                            <div className="input-section">
                                              <Form.Item
                                                {...product}
                                                name={[product.name, "status"]}
                                                label={"Status Produk"}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t(
                                                      "validation.required",
                                                      {
                                                        item: "Status",
                                                      }
                                                    ),
                                                  },
                                                ]}
                                                className={"mb0"}
                                              >
                                                {isEdit.get() ? (
                                                  <Select
                                                    size={"large"}
                                                    style={{ width: "100%" }}
                                                  >
                                                    {productStatusOptions.map(
                                                      (option, index) => (
                                                        <Option
                                                          key={`${index}_status`}
                                                          value={option.id}
                                                        >
                                                          {option.name}
                                                        </Option>
                                                      )
                                                    )}
                                                  </Select>
                                                ) : (
                                                  <div className="input-section">
                                                    <Text>
                                                      {data?.tables[index]
                                                        .products[product.name]
                                                        .status || "-"}
                                                    </Text>
                                                  </div>
                                                )}
                                              </Form.Item>
                                            </div>
                                          </div>

                                          <Divider className={"mb0 mt0"} />
                                        </Space>
                                      ))}

                                      {isEdit.get() && (
                                        <Button
                                          type="dashed"
                                          onClick={() => add()}
                                          block
                                          icon={<PlusOutlined />}
                                        >
                                          Tambah Produk
                                        </Button>
                                      )}
                                    </Space>
                                  </>
                                )}
                              </Form.List>
                            </Card>
                          </Space>
                        </Card>
                      ))}
                    </Space>

                    {isEdit.get() && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Tambah Table
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            {isEdit.get() ? (
              <Accessible access={permissions.write_live_display_unit_b2c}>
                <Spin spinning={loading.get()}>
                  <Button
                    className="wui-btn"
                    htmlType="submit"
                    type="primary"
                    size={"large"}
                    disabled={disable.get()}
                  >
                    {t("common.button.save")}
                  </Button>
                </Spin>
              </Accessible>
            ) : (
              ""
            )}
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppLDUForm;
