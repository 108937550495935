import React, { useEffect } from "react";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import TimePicker from "antd/es/time-picker";
import moment from "moment";
import Form from "antd/lib/form";
import WuiUploadImages from "../../../../../@framework/wui/components/UploadImages";
import { Spin } from "antd";
import {useState} from "@hookstate/core";
import { handleBackendError, haveAccess, openNotification, toFileList } from "../../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import PushNotificationRepository from "../../../../../repositories/PushNotificationRepository";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";
import Text from "antd/es/typography/Text";
import {permissions} from "../../../../../constant/permissions";

let title = "Push Notifikasi";

const AppSettingsPushNotificationForm: React.FC<any> = () => {
    const {t} = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', {item: title}) : t('common.text.addNewItem', {item: title})
    
    // Form
    const [form] = Form.useForm();
    const disable = useState(false);
    const loading = useState(false);
    const acl = useAccess()

    const onFinish = async (data: any) => {
        loading.set(true)
        let scheduledAt: string = `${moment(data.date).format('YYYY-MM-DD')} ${moment(data.time).format('HH:mm')}`
        scheduledAt = `${moment(scheduledAt).subtract({hours: 7}).format('YYYY-MM-DD[T]HH:mm:ss')}+00:00`

        const formData = new FormData();
        if(id){
            formData.append('_method', 'PUT')
        }
        formData.append('title', data.title)
        formData.append('message', data.message)
        formData.append('content_url', data.content_url || '')
        formData.append('scheduled_at', scheduledAt);

        (data.content_attachment || []).forEach((item: any) => {
            if(item.status === 'done'){
                formData.append('content_attachment_keep[]', item.id)
            } else {
                formData.append('content_attachment[]', item)
            }
        })

        await (!id ? (PushNotificationRepository.create(formData)) : PushNotificationRepository.update(id, formData)).then((res: AxiosResponse) => {
            navigate(-1)
          
            if (!id) {
                openNotification('success', t('notification.success.createItem', {item: title}))
            } else {
                openNotification('success', t('notification.success.updateItem', {item: title}))
                loading.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            loading.set(false);
        })
    }

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await PushNotificationRepository.show(id, {with: ['contentAttachment']}).then((res: AxiosResponse) => {
            const data = res.data?.data || {}

            form.setFieldsValue({
                title: data.title,
                message: data.message,
                date: moment(data.scheduled_at),
                time: moment(data.scheduled_at),
                content_url: data.content_url,
                content_attachment: toFileList(data.content_attachment),
            })
            
            if(haveAccess(acl, [permissions.write_push_notification_b2b])){
                disable.set(false)
            }

        }).catch((e: AxiosError) => {

        })
    }

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />

                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Card>
                        <Space style={{
                            width: '100%'
                        }} direction="vertical" size={5}>
                            <div className="wui-form-group type-column">
                                <div className="input-section">
                                    <Form.Item
                                        name="title"
                                        label={"Judul"}
                                        rules={[{
                                            required: true,
                                            message: t('validation.required', {item: "Judul"})
                                        }]}
                                    >
                                        <Input disabled={disable.get()} size={"large"} placeholder={t('common.text.input', {item: 'Judul'})} />
                                    </Form.Item>
                                </div>
                            </div>
                            
                            <div className="wui-form-group type-column">
                                <div className="input-section">
                                    <Form.Item
                                        name="message"
                                        label={"Pesan"}
                                        rules={[{
                                            required: true,
                                            message: t('validation.required', {item: "Pesan"})
                                        }]}
                                    >
                                        <Input disabled={disable.get()} size={"large"} placeholder={t('common.text.input', {item: 'Pesan'})} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Space>
                    </Card>

                    <br />


                    <Card title={'Jadwal'}>
                        <Space style={{
                            width: '100%'
                        }} direction="vertical" size={5}>
                            <div className="wui-form-group type-column">
                                <div className="input-section">
                                    <Form.Item
                                        name="date"
                                        label={"Tanggal"}
                                        rules={[{
                                            required: true,
                                            message: t('validation.required', {item: "Tanggal"})
                                        }]}
                                    >
                                        <DatePicker 
                                            disabled={disable.get()} 
                                            disabledDate={(current) => {
                                                let customDate = moment().format("YYYY-MM-DD");
                                                return current && current < moment(customDate, "YYYY-MM-DD");
                                            }} format="DD-MM-YYYY"
                                            style={{width: 400, maxWidth: '100%'}} size={"large"} onChange={() => {}} placeholder={t('common.text.input', {item: 'Tanggal'})}/>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="wui-form-group type-column">
                                <div className="input-section">
                                    <Form.Item
                                        name="time"
                                        label={"Jam"}
                                        rules={[{
                                            required: true,
                                            message: t('validation.required', {item: "Jam"})
                                        }]}
                                    >
                                        <TimePicker disabled={disable.get()} style={{width: 400, maxWidth: '100%'}} size={"large"} placeholder={t('common.text.input', {item: 'Jam'})} format="HH:mm" />
                                    </Form.Item>
                                </div>
                            </div>
                        </Space>
                    </Card>
                    
                    <br />

                    <Card title={'Konten'}>
                        <Space style={{
                            width: '100%'
                        }} direction="vertical" size={25}>
                            <div className="wui-form-group type-column">
                                <div className="input-section">
                                    <Form.Item
                                        name="content_url"
                                        label={"URL"}
                                    >
                                        <Input disabled={disable.get()} size={"large"}placeholder={t('common.text.input', {item: 'URL'})} />
                                    </Form.Item>
                                </div>
                            </div> 
                            
                            <div className="wui-form-group type-column">
                                <div className="input-section">
                                    <Form.Item
                                        name="content_attachment"
                                        label={"Gambar"}
                                        >
                                        {!disable.get() ?
                                            <WuiUploadImages disabled={disable.get()}/> :
                                            (
                                                (form.getFieldValue('content_attachment') || []).length > 0 ? 
                                                <WuiUploadImages disabled={disable.get()}/> : 
                                                <Text>Tidak ada gambar</Text>
                                            )
                                        }
                                    </Form.Item>
                                </div>
                            </div>
                        </Space>
                    </Card>

                    <div className="wui-form-btn-group">
                        <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.cancel')}</Button>
                        <Accessible access={[permissions.write_push_notification_b2b]}>
                            <Spin spinning={loading.get()}>
                                <Button className="wui-btn" htmlType="submit" type="primary" size={"large"} disabled={disable.get()}>{t('common.button.save')}</Button>
                            </Spin>
                        </Accessible>
                    </div>
                </Form>
            </WuiContainer>
        </>
    )
}

export default AppSettingsPushNotificationForm
