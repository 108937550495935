import React, { useCallback, useEffect } from "react";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Form from "antd/lib/form";
import { Checkbox, Spin, Table, Typography } from "antd";
import { useState } from "@hookstate/core";
import {
  handleBackendError,
  haveAccess,
  openNotification,
} from "../../../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import AccessRoleRepository from "../../../../../../repositories/AccessRoleRepository";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import Select from "antd/es/select";
import {SALES_ADMIN, SALES_LEVEL_OPTIONS, STORE_LEADER} from "../../../../../../constant";

let title = "Hak Akses";

const { Option } = Select;
const { Title, Text } = Typography;

const AppSettingsAccountAccessForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  let permissionIds: any = {};
  const permissionIdValues = useState([]);
  const permissionRawDataObj: any = useState({});
  const permissionRawDataArr = useState([]);
  const acl = useAccess();

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      name: data.name,
      level: data.level,
      permission_ids: permissionIdValues.get(),
    };

    await (!id
      ? AccessRoleRepository.create(payload)
      : AccessRoleRepository.update(id, payload)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await AccessRoleRepository.show(id, { with: "permissions" })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        form.setFieldsValue({
          name: data.name,
          level: data.level,
        });
        levelValue.set(data.level);
        permissionIdValues.set(data.permissions.map((item: any) => item.id));
        initCheckbox();

        if (haveAccess(acl, "write access role b2b")) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});
  };

  const init = async () => {
    disable.set(true);

    await AccessRoleRepository.permissionOptions({ page: 1, per_page: 9999 })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || [];
        let dataObj: any = {};
        data.forEach((item: any) => {
          const name = (item.name || "").toLowerCase();
          let type = "";
          if (name.includes("write")) {
            type = "write";
          } else if (name.includes("read")) {
            type = "read";
          } else if (name.includes("delete")) {
            type = "delete";
          }
          let key = name.replace(`${type} `, "");
          if (!permissionIds[key]) {
            permissionIds[key] = {};
          }
          permissionIds[key][type] = item.id;
          dataObj[item.id] = item;
        });

        permissionRawDataObj.set(dataObj);
        permissionRawDataArr.set(data);

        if (
          haveAccess(acl, [
            "write access role b2b",
            "write access role b2c",
            "write access role online",
          ])
        ) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});

    if (id) {
      await getData();
    } else {
      initCheckbox();
    }
  };

  const initCheckbox = async () => {
    let filteredIds = permissionRawDataArr
      .get()
      .filter((item: any) => item.platform === "website")
      .map((it: any) => it.id);
    let masterKey: string[] = Object.keys(permissionIds);
    const masterAccessType = ["read", "write", "delete"];

    const master: any = generateCheckboxId(
      masterKey,
      masterAccessType,
      filteredIds
    );
    masterAccess.set(master);

    const level = SALES_LEVEL_OPTIONS.filter(item => item !== SALES_ADMIN  && item !== STORE_LEADER);

    let mobile: any = [];
    level.forEach((lv: any) => {
      let ids = permissionRawDataArr
        .get()
        .filter((item: any) => item.platform === "mobile" && item.level === lv)
        .map((it: any) => it.id);
      let accData: any = generateCheckboxId(masterKey, masterAccessType, ids);
      mobile.push({
        title: lv,
        data: accData,
      });
    });


    const overidePermissionName = mobile.map((item: any) => {
      if (item.title === "Superior") {
        item.data = item.data.map((permission: any) => {
          if (permission.channel === "B2C" && permission.name === "Sales") {
            permission.name = "My Team";
          }
          return permission;
        });
      }

      return item;
    });

    mobileAccess.set(overidePermissionName);
  };

  const generateCheckboxId = (
    keys: Array<string>,
    types: Array<string>,
    filteredIds: Array<number>
  ) => {
    let result: any = [];
    keys.forEach((item) => {
      const key = item.toLowerCase();
      let obj: any = {
        key: key,
        name: item,
      };
      types.forEach((type) => {
        let id: number = -1;

        try {
          id = permissionIds[key][type];
          if (filteredIds.includes(id)) {
            obj[`${type}Id`] = id;
            const objData = permissionRawDataObj.get()[id];
            obj.platform = objData["platform"];
            obj.level = objData["level"];
            obj.name = objData["label"];
            obj.channel = objData["channel"];
            obj.disable = false;

            if (
              !haveAccess(acl, "write access role b2b") &&
              objData["channel"].toLowerCase() === "b2b"
            ) {
              obj.disable = true;
            }

            if (
              !haveAccess(acl, "write access role b2c") &&
              objData["channel"].toLowerCase() === "b2c"
            ) {
              obj.disable = true;
            }

            if (
              !haveAccess(acl, "write access role online") &&
              objData["channel"].toLowerCase() === "online"
            ) {
              obj.disable = true;
            }
          } else {
            obj.broken = true;
          }
        } catch (err) {}
        let arr: any = permissionIdValues.get() || [];
        obj[type] = arr.includes(id);
      });
      if (obj.broken) {
        if (obj.readId || obj.writeId || obj.deleteId) {
          obj.broken = false;
        }
      }

      if (!obj.broken) {
        result.push(obj);
      }
    });
    return result;
  };

  const handleChange = (id: number) => {
    let arr: any = permissionIdValues.get() || [];
    if (arr.includes(id)) {
      arr = arr.filter((item: any) => item !== id);
    } else {
      arr = [...arr, id];
    }

    permissionIdValues.set(arr);
  };

  const handleChecked = (id: number) => {
    let arr: any = permissionIdValues.get() || [];

    if (arr.includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  const masterAccess = useState([]);
  const mobileAccess = useState([]);

  const masterAccessColumns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (text: any, record: any) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "Baca",
      dataIndex: "read",
      key: "read",
      render: (text: any, record: any) =>
        record.readId ? (
          <Checkbox
            disabled={record.disable}
            checked={handleChecked(record.readId)}
            onChange={() => handleChange(record.readId)}
          />
        ) : (
          <span />
        ),
    },
    {
      title: "Buat",
      dataIndex: "create",
      key: "create",
      render: (text: any, record: any) =>
        record.writeId ? (
          <Checkbox
            disabled={record.disable}
            checked={handleChecked(record.writeId)}
            onChange={() => handleChange(record.writeId)}
          />
        ) : (
          <span />
        ),
    },
    {
      title: "Hapus",
      dataIndex: "delete",
      key: "delete",
      render: (text: any, record: any) =>
        record.deleteId ? (
          <Checkbox
            disabled={record.disable}
            checked={handleChecked(record.deleteId)}
            onChange={() => handleChange(record.deleteId)}
          />
        ) : (
          <span />
        ),
    },
  ];

  const tabKey = useState("website");
  const levelValue = useState("");

  const filterChannel = useCallback((data: any[], channel: string) => {
    return data.filter(
      (item) => item["channel"].toLowerCase() === channel.toLowerCase()
    );
  }, []);

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Card>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
              size={25}
            >
              <div className="wui-form-group type-column">
                <div className="input-section">
                  <Form.Item
                    name="name"
                    label={"Peran"}
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", { item: "Peran" }),
                      },
                    ]}
                  >
                    <Input
                      disabled={disable.get()}
                      size={"large"}
                      placeholder={t("common.text.input", { item: "Peran" })}
                    />
                  </Form.Item>
                </div>
              </div>
            </Space>
          </Card>

          <br />

          <Card
            tabList={[
              { tab: "Website", key: "website" },
              { tab: "Mobile", key: "mobile" },
            ]}
            activeTabKey={tabKey.get()}
            onTabChange={(key) => tabKey.set(key)}
          >
            {tabKey.get() === "website" ? (
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
                size={10}
              >
                <Title level={5}>B2B</Title>
                <Text strong>Izin Peran</Text>
                <Table
                  dataSource={filterChannel(masterAccess.get(), "B2B")}
                  columns={masterAccessColumns}
                  pagination={false}
                />
                <br />

                <Title level={5}>B2C</Title>
                <Text strong>Izin Peran</Text>
                <Table
                  dataSource={filterChannel(masterAccess.get(), "B2C")}
                  columns={masterAccessColumns}
                  pagination={false}
                />
                <br />

                <Title level={5}>Online</Title>
                <Text strong>Izin Peran</Text>
                <Table
                  dataSource={filterChannel(masterAccess.get(), "online")}
                  columns={masterAccessColumns}
                  pagination={false}
                />
                <br />
              </Space>
            ) : (
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
                size={15}
              >
                <div className="wui-form-group type-column">
                  <div className="input-section">
                    <Form.Item name="level" label={"Level"}>
                      <Select
                        onChange={(value) => {
                          levelValue.set(value);
                        }}
                        disabled={disable.get()}
                        size={"large"}
                        style={{
                          width: "100%",
                        }}
                        placeholder="Pilih Level"
                      >
                        {SALES_LEVEL_OPTIONS.filter(
                          (level) => level !== SALES_ADMIN && level !== STORE_LEADER
                        ).map((level) => {
                          return (
                            <Option key={level} value={level}>
                              {level}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {mobileAccess.get().map((item: any) => {
                  return item.title === levelValue.get() ? (
                    <Space
                      style={{ width: "100%" }}
                      direction="vertical"
                      size={10}
                      key={item.title}
                    >
                      {filterChannel(item.data, "b2b").length > 0 && (
                        <>
                          <div>Izin Peran B2B</div>
                          <Table
                            dataSource={filterChannel(item.data, "b2b")}
                            columns={masterAccessColumns}
                            pagination={false}
                          />
                        </>
                      )}

                      {filterChannel(item.data, "b2c").length > 0 && (
                        <>
                          <br />
                          <div>Izin Peran B2C</div>
                          <Table
                            dataSource={filterChannel(item.data, "b2c")}
                            columns={masterAccessColumns}
                            pagination={false}
                          />
                        </>
                      )}

                      {filterChannel(item.data, "online").length > 0 && (
                        <>
                          <br />
                          <div>Izin Peran Online</div>
                          <Table
                            dataSource={filterChannel(item.data, "online")}
                            columns={masterAccessColumns}
                            pagination={false}
                          />
                        </>
                      )}
                    </Space>
                  ) : null;
                })}
              </Space>
            )}
          </Card>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible access="write access role b2b">
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsAccountAccessForm;
