import React, { useEffect } from "react";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import Form from "antd/lib/form";
import Tabs from "antd/es/tabs";
import { InputNumber, Spin } from "antd";
import { useState } from "@hookstate/core";
import { haveAccess, openNotification } from "../../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import RadiusRepository from "../../../../../repositories/RadiusRepository";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";
import { TChannels } from "../../../../../utils";
import { permissions } from "../../../../../constant/permissions";

const { TabPane } = Tabs;

let title = "Radius";

interface ITab {
  key: TChannels;
  permission: string;
}

const tabs: ITab[] = [
  {
    key: "b2b",
    permission: permissions.read_setting_radius_b2b,
  },
  {
    key: "b2c",
    permission: permissions.read_setting_radius_b2c,
  },
];

const breadcrumbs = [
  {
    label: "Pengaturan",
  },
  {
    label: "Radius",
    link: "/pengaturan/radius",
  },
];

const AppSettingsRadiusForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const acl = useAccess();
  const tabKey = useState<TChannels>("");
  const selectedTab = tabs.find((tab) => tab.key === tabKey.get());

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      radius: data.radius,
    };

    await RadiusRepository.update(payload, tabKey.get())
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        loading.set(false);
      })
      .catch((e: AxiosError) => {
        openNotification("error", t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    getData().then();
  }, [tabKey.get()]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    if (!haveAccess(acl, `read setting radius ${tabKey.get()}`)) {
      return;
    }

    disable.set(true);

    await RadiusRepository.show(tabKey.get())
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        form.setFieldsValue({
          radius: data?.radius || 0,
        });

        if (haveAccess(acl, `write setting radius ${tabKey.get()}`)) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});
  };

  useEffect(() => {
    setTabKey();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const setTabKey = () => {
    const searchParamsTab = searchParams.get("tab");
    const isValidSearchParams =
      searchParamsTab === "b2b" || searchParamsTab === "b2c";
    if (isValidSearchParams) {
      tabKey.set(searchParamsTab);
    } else {
      const defaultSelectedTab = tabs.find((tab) =>
        haveAccess(acl, tab.permission)
      );
      if (defaultSelectedTab) {
        tabKey.set(defaultSelectedTab.key);
      }
    }
  };

  const renderTabContent = () => {
    return (
      <Form form={form} layout={"vertical"} onFinish={onFinish}>
        <Card>
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
            size={25}
          >
            <div className="wui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name="radius"
                  label={"Meter"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", { item: "Radius" }),
                    },
                  ]}
                >
                  <InputNumber
                    type="number"
                    min={0}
                    disabled={disable.get()}
                    size={"large"}
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault()}
                  />
                </Form.Item>
              </div>
            </div>
          </Space>
        </Card>

        <div className="wui-form-btn-group">
          <Accessible access={selectedTab?.permission || ""}>
            <Spin spinning={loading.get()}>
              <Button
                className="wui-btn"
                htmlType="submit"
                type="primary"
                size={"large"}
                disabled={disable.get()}
              >
                {t("common.button.save")}
              </Button>
            </Spin>
          </Accessible>
        </div>
      </Form>
    );
  };

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle breadcrumbs={breadcrumbs} title={title} />

        {!!tabKey.get() && (
          <Tabs
            defaultActiveKey={tabKey.get()}
            onChange={(key) => {
              setSearchParams(`?tab=${key}`);
            }}
          >
            {tabs
              .filter((tab) => haveAccess(acl, tab.permission))
              .map((tab) => {
                return (
                  <TabPane tab={tab.key.toUpperCase()} key={tab.key}>
                    {renderTabContent()}
                  </TabPane>
                );
              })}
          </Tabs>
        )}
      </WuiContainer>
    </>
  );
};

export default AppSettingsRadiusForm;
