import Button from "antd/lib/button";
import Table from "antd/lib/table";
import Moment from "react-moment";

interface Props {
  dataSource: any[]
}

const TableMaps: React.FC<Props> = (
    {
      dataSource
    }
) => {
  const columns = [
    {
      title: 'Longlatitude',
      dataIndex: 'lng_lat',
      key: 'lng_lat',
      render: (text: any, record: any) => {
        let arr = text.split(',')
        if(arr){
          return (
              <Button
                  type={"primary"}
                  href={`https://www.google.com/maps/search/?api=1&query=${arr[1]},${arr[0]}`}
                  target="_blank"
              >
                Lihat Peta
              </Button>
          )
        } else{
          return <span></span>
        }

      },
    },
    {
      title: 'Tanggal Diperbaharui',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: any, record: any) => {
        return (
            <Moment format="DD MMMM YYYY, HH:ss">
              {text}
            </Moment>
        )
      },
    }
  ];

  return (
      <>
        <Table rowKey={"lng_lat"} dataSource={dataSource} columns={columns} pagination={false}/>
      </>
  )
}

export default TableMaps