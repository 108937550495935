import { AxiosError, AxiosResponse } from "axios";

const axios = require("axios");

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  (error: AxiosError) => {
    let errorResponse = null;
    if (error.response != null) {
      errorResponse = error.response.status;
    } else {
      errorResponse = error;
    }

    if (isTokenExpiredError(errorResponse)) {
      // handler if token is expired
      if (error?.response?.data === "Unauthorized.") {
        window.location.reload();
      }
    }

    return Promise.reject(error);

    // If the error is due to other reasons, we just throw it back to axios
  }
);
function isTokenExpiredError(errorResponse: any) {
  if (errorResponse === 401) {
    return true;
  }

  return false;
}

export default axios;

export const createCancelTokenHandler = (apiObject: any) => {
  // initializing the cancel token handler object
  const cancelTokenHandler: any = {};

  // for each property in apiObject, i.e. for each request
  Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
    // initializing the cancel token of the request
    const cancelTokenRequestHandler: any = {
      cancelToken: undefined,
    };

    // associating the cancel token handler to the request name
    cancelTokenHandler[propertyName] = {
      handleRequestCancellation: () => {
        // if a previous cancel token exists,
        // cancel the request
        if (cancelTokenRequestHandler.cancelToken) {
          cancelTokenRequestHandler.cancelToken.cancel(
            `${propertyName} canceled`
          );
        }

        // creating a new cancel token
        cancelTokenRequestHandler.cancelToken = axios.CancelToken.source();

        // returning the new cancel token
        return cancelTokenRequestHandler.cancelToken;
      },
    };
  });

  return cancelTokenHandler;
};
