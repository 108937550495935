export const ISSUE_TRACKING_OPEN = "Mulai";
export const ISSUE_TRACKING_PROCESSING = "Berjalan";
export const ISSUE_TRACKING_COMPLETED = "Selesai";

export const ISSUE_TRACKING_TAG_COLOR: DefaultMap = {
  [ISSUE_TRACKING_OPEN]: "warning",
  [ISSUE_TRACKING_PROCESSING]: "processing",
  [ISSUE_TRACKING_COMPLETED]: "success",
};

export const ISSUE_TRACKING_STATUS_OPTIONS = [
  {
    label: ISSUE_TRACKING_OPEN,
    value: ISSUE_TRACKING_OPEN,
  },
  {
    label: ISSUE_TRACKING_PROCESSING,
    value: ISSUE_TRACKING_PROCESSING,
  },
  {
    label: ISSUE_TRACKING_COMPLETED,
    value: ISSUE_TRACKING_COMPLETED,
  },
];

export const ISSUE_TRACKING_CATEGORY_FEEDBACK = 'Saran';
export const ISSUE_TRACKING_CATEGORY_COMPLAINT = 'Komplain';
export const ISSUE_TRACKING_CATEGORY_MARKET_INFO = 'Info Toko';
export const ISSUE_TRACKING_CATEGORY_RETURN = 'Return';
export const ISSUE_TRACKING_CATEGORY_STORE_VISIBILITY = "Store Visibility";
export const ISSUE_TRACKING_CATEGORY_MARKETING = "Marketing";
export const ISSUE_TRACKING_CATEGORY_NETWORK = "Networking";
export const ISSUE_TRACKING_CATEGORY_OTHER = "Other";

export const ISSUE_TRACKING_CATEGORY_OPTIONS_B2B = [
  {
    label: ISSUE_TRACKING_CATEGORY_FEEDBACK,
    value: ISSUE_TRACKING_CATEGORY_FEEDBACK,
  },
  {
    label: ISSUE_TRACKING_CATEGORY_COMPLAINT,
    value: ISSUE_TRACKING_CATEGORY_COMPLAINT,
  },
  {
    label: ISSUE_TRACKING_CATEGORY_MARKET_INFO,
    value: ISSUE_TRACKING_CATEGORY_MARKET_INFO,
  },
  {
    label: ISSUE_TRACKING_CATEGORY_RETURN,
    value: ISSUE_TRACKING_CATEGORY_RETURN,
  },
];

export const ISSUE_TRACKING_CATEGORY_OPTIONS_B2C = [
  {
    label: ISSUE_TRACKING_CATEGORY_STORE_VISIBILITY,
    value: ISSUE_TRACKING_CATEGORY_STORE_VISIBILITY,
  },
  {
    label: ISSUE_TRACKING_CATEGORY_MARKETING,
    value: ISSUE_TRACKING_CATEGORY_MARKETING,
  },
  {
    label: ISSUE_TRACKING_CATEGORY_NETWORK,
    value: ISSUE_TRACKING_CATEGORY_NETWORK,
  },
  {
    label: ISSUE_TRACKING_CATEGORY_OTHER,
    value: ISSUE_TRACKING_CATEGORY_OTHER,
  },
];

interface DefaultMap {
  [name: string]: string;
}

export const PROMOTION_TYPE_PROMOTIONAL = "Promotional";
export const PROMOTION_TYPE_PROGRAM = "Program";

export const SURVEY_TYPE_SHORT_ANSWER = "SHORT_ANSWER";
export const SURVEY_TYPE_MULTIPLE_CHOICE = "MULTIPLE_CHOICE";
export const SURVEY_TYPE_CHECK_BOX = "CHECK_BOX";
export const SURVEY_TYPE_DROPDOWN = "DROPDOWN";

export const SURVEY_TYPE_OPTION_LABELS: DefaultMap = {
  [SURVEY_TYPE_SHORT_ANSWER]: "Text",
  [SURVEY_TYPE_MULTIPLE_CHOICE]: "Multiple Choices",
  [SURVEY_TYPE_CHECK_BOX]: "Checkboxes",
  [SURVEY_TYPE_DROPDOWN]: "Dropdown",
};

export const SURVEY_TYPE_OPTIONS = [
  SURVEY_TYPE_SHORT_ANSWER,
  SURVEY_TYPE_MULTIPLE_CHOICE,
  SURVEY_TYPE_CHECK_BOX,
  SURVEY_TYPE_DROPDOWN,
];

export const ASSISTANT_STORE_LEADER = "Assistant Store Leader";
export const STORE_LEADER = "Store Leader";
export const SALES_PROMOTER = "Sales Promoter";
export const SALES_CONSULTANT = "Sales Consultant";
export const SALES_SUPERIOR = "Superior";
export const SALES_ADMIN = "Admin";

export const AREA_HIERARCHY_CLUSTER = "Cluster";
export const AREA_HIERARCHY_AREA = "Area";
export const AREA_HIERARCHY_SUBTERRITORY = "Subterritory";
export const AREA_HIERARCHY_TERRITORY = "Territory";
export const AREA_HIERARCHY_REGION = "Region";

export const AREA_HIERARCHY_OPTIONS = [
  AREA_HIERARCHY_CLUSTER,
  AREA_HIERARCHY_AREA,
  AREA_HIERARCHY_SUBTERRITORY,
  AREA_HIERARCHY_TERRITORY,
  AREA_HIERARCHY_REGION,
];

export const B2C_AREA_HIERARCHY_OPTIONS = [
  AREA_HIERARCHY_AREA,
  AREA_HIERARCHY_TERRITORY,
  AREA_HIERARCHY_REGION,
];

export const SALES_LEVEL_OPTIONS = [
  SALES_SUPERIOR,
  SALES_ADMIN,
  SALES_PROMOTER,
  SALES_CONSULTANT,
  STORE_LEADER,
  ASSISTANT_STORE_LEADER
];

export const SELECT_ALL_VALUE = "ALL";

export const STOCK_OPNAME_STATUS_REQUEST_REVISION = "Request Revision";
export const STOCK_OPNAME_STATUS_SAVED = "Saved";
export const STOCK_OPNAME_STATUS_APPROVED = "Approved for Revision";
export const STOCK_OPNAME_STATUS_REJECTED = "Rejected for Revision";

export const STOCK_OPNAME_STATUS_COLOR: DefaultMap = {
  [STOCK_OPNAME_STATUS_REQUEST_REVISION]: "warning",
  [STOCK_OPNAME_STATUS_SAVED]: "processing",
  [STOCK_OPNAME_STATUS_APPROVED]: "success",
  [STOCK_OPNAME_STATUS_REJECTED]: "error",
};

export const CHANNEL_TYPE_B2B = "B2B";
export const CHANNEL_TYPE_B2C = "B2C";
export const CHANNEL_TYPE_B2B_B2C = "B2B & B2C";

export const CHANNEL_TYPE_SELECT_OPTIONS = [
  {
    id: 1,
    name: CHANNEL_TYPE_B2B,
  },
  {
    id: 2,
    name: CHANNEL_TYPE_B2C,
  },
];

export const CHANNEL_TABS = [
  {
    id: 1,
    name: CHANNEL_TYPE_B2B,
    value: [CHANNEL_TYPE_B2B]
  },
  {
    id: 2,
    name: CHANNEL_TYPE_B2C,
    value: [CHANNEL_TYPE_B2C]
  },
  {
    id: 3,
    name: CHANNEL_TYPE_B2B_B2C,
    value: [CHANNEL_TYPE_B2B, CHANNEL_TYPE_B2C]
  }
]

export const KPI_MASTER_STATUS_DRAFT = "Draft";
export const KPI_MASTER_STATUS_COMPLETED = "Selesai";
export const KPI_MASTER_STATUS_EXPIRED = "Expired";

export const KPI_MASTER_STATUS_SELECT_OPTIONS = [
  {
    id: 1,
    name: KPI_MASTER_STATUS_DRAFT,
  },
  {
    id: 2,
    name: KPI_MASTER_STATUS_COMPLETED,
  },
  {
    id: 3,
    name: KPI_MASTER_STATUS_EXPIRED,
  },
];

export const KPI_MASTER_STATUS_COLOR: DefaultMap = {
  [KPI_MASTER_STATUS_DRAFT]: "warning",
  [KPI_MASTER_STATUS_COMPLETED]: "success",
  [KPI_MASTER_STATUS_EXPIRED]: "error",
};

export const MONTH_JANUARY = "January";
export const MONTH_FEBRUARY = "February";
export const MONTH_MARCH = "March";
export const MONTH_APRIL = "April";
export const MONTH_MAY = "May";
export const MONTH_JUNE = "June";
export const MONTH_JULY = "July";
export const MONTH_AUGUST = "August";
export const MONTH_SEPTEMBER = "September";
export const MONTH_OCTOBER = "October";
export const MONTH_NOVEMBER = "November";
export const MONTH_DECEMBER = "December";

export const MONTH_OPTIONS = [
  MONTH_JANUARY,
  MONTH_FEBRUARY,
  MONTH_MARCH,
  MONTH_APRIL,
  MONTH_MAY,
  MONTH_JUNE,
  MONTH_JULY,
  MONTH_AUGUST,
  MONTH_SEPTEMBER,
  MONTH_OCTOBER,
  MONTH_NOVEMBER,
  MONTH_DECEMBER,
];

export const MONTH_MULTI_OPTIONS = [
  {
    id: 1,
    name: MONTH_JANUARY
  },
  {
    id: 2,
    name: MONTH_FEBRUARY
  },
  {
    id: 3,
    name: MONTH_MARCH
  },
  {
    id: 4,
    name: MONTH_APRIL
  },
  {
    id: 5,
    name: MONTH_MAY
  },
  {
    id: 6,
    name: MONTH_JUNE
  },
  {
    id: 7,
    name: MONTH_JULY
  },
  {
    id: 8,
    name: MONTH_AUGUST
  },
  {
    id: 9,
    name: MONTH_SEPTEMBER
  },
  {
    id: 10,
    name: MONTH_OCTOBER
  },
  {
    id: 11,
    name: MONTH_NOVEMBER
  },
  {
    id: 12,
    name: MONTH_DECEMBER
  }
];

export const KPI_MASTER_DEFAULT_OPERATORS = [
  {
    value: '<',
    label: '<'
  },
  {
    value: '<=',
    label: '<='
  },
  {
    value: '>',
    label: '>'
  },
  {
    value: '>=',
    label: '>='
  },
  {
    value: '=',
    label: '='
  }
];

export const DASHBOARD_B2C_O2O_STATUS_PENDING = "Pending";
export const DASHBOARD_B2C_O2O_STATUS_COMPLETED = "Completed";

export const DASHBOARD_B2C_O2O_STATUS_OPTIONS = [
  {
    id: 1,
    name: DASHBOARD_B2C_O2O_STATUS_PENDING
  },
  {
    id: 2,
    name: DASHBOARD_B2C_O2O_STATUS_COMPLETED
  }
]

export const DASHBOARD_B2C_O2O_STATUS_COLOR: DefaultMap = {
  [DASHBOARD_B2C_O2O_STATUS_PENDING]: "warning",
  [DASHBOARD_B2C_O2O_STATUS_COMPLETED]: "success",
};

export const TABLEAU_CODE_OPTIONS = [
  "HOR",
  "HOT",
  "TSH",
  "ASH",
  "SL",
  "ASL",
  "SC",
];