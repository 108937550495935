import React, { useEffect } from "react";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import WuiFormTitle from "../../../../../@framework/wui/components/Form/Title";
import WuiUploadImages from "../../../../../@framework/wui/components/UploadImages";
import { Col, Row } from "antd";
import { useState } from "@hookstate/core";
import { toFileList } from "../../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import HandoverRepository from "../../../../../repositories/HandoverRepository";
import Text from "antd/es/typography/Text";
import moment from "moment";


let title = "Serah Terima Barang";

const AppPromotorHandoverForm: React.FC<any> = () => {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', { item: title }) : t('common.text.addNewItem', { item: title })
    // Form
    const disable = useState(false);
    const tabKey = useState('1')
    const [data, setData] = React.useState<any>({
        userWithReceipts: [],
        userWithStuffs: [],
        receipts: []
    });

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await HandoverRepository.show(id, { with: [
            'receipts',
            'userWithReceipts',
            'userWithStuffs',
            'sales'
        ] }).then((res: AxiosResponse) => {
            const data = res.data?.data || {}

            setData({
                ...data,
                sales_name: data.sales?.name,
                date: moment(data.date).format('D MMM YYYY | HH:mm'),
                receipts: toFileList(data.receipts),
                userWithReceipts: toFileList(data.user_with_receipts),
                userWithStuffs: toFileList(data.user_with_stuffs),
            })

            disable.set(false)
        }).catch((e: AxiosError) => {
            console.log(e)
        })
    }
    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />
                <Row gutter={[16, 16]}>
                    <Col className="gutter-row" md={{
                        span: 16,
                        order: 1
                    }} xs={{
                        span: 24,
                        order: 2
                    }}>
                        <Card title="Informasi">
                            <Space style={{
                                width: '100%',
                                maxHeight: tabKey.get() === '1' ? '100%' : 0,
                                opacity: tabKey.get() === '1' ? 1 : 0,
                            }} direction="vertical" size={25}>
                                <div className="wui-form-group">
                                    <WuiFormTitle title={"ID"} />

                                    <div className="input-section">
                                        <Text>{data.number}</Text>
                                    </div>
                                </div>
                                <div className="wui-form-group">
                                    <WuiFormTitle title={"Nama Sales"} />

                                    <div className="input-section">
                                        <Text>{data.sales_name}</Text>
                                    </div>
                                </div>
                                <div className="wui-form-group">
                                    <WuiFormTitle title={"Tanggal Permintaan"} />

                                    <div className="input-section">
                                        <Text>{data.date}</Text>
                                    </div>
                                </div>
                            </Space>
                        </Card>

                        <br />

                        <Card title="Foto Struck">
                            <Space style={{
                                width: '100%',
                                maxHeight: tabKey.get() === '1' ? '100%' : 0,
                                opacity: tabKey.get() === '1' ? 1 : 0,
                            }} direction="vertical" size={25}>
                                <div className="wui-form-group">
                                    <div className="input-section">
                                        {!disable.get() && data.receipts.length > 0 &&
                                            <WuiUploadImages disabled={true} value={data.receipts}/>
                                        }
                                        {!disable.get() && data.receipts.length === 0 &&
                                            <Text>Tidak ada foto</Text>
                                        }
                                    </div>
                                </div>

                            </Space>
                        </Card>

                        <br />

                        <Card title="Foto User dengan Struck">
                            <Space style={{
                                width: '100%',
                                maxHeight: tabKey.get() === '1' ? '100%' : 0,
                                opacity: tabKey.get() === '1' ? 1 : 0,
                            }} direction="vertical" size={25}>
                                <div className="wui-form-group">
                                    <div className="input-section">
                                        {!disable.get() && data.userWithReceipts.length > 0 &&
                                            <WuiUploadImages disabled={true} value={data.userWithReceipts}/>
                                        }
                                        {!disable.get() && data.userWithReceipts.length === 0 &&
                                            <Text>Tidak ada foto</Text>
                                        }
                                    </div>
                                </div>

                            </Space>
                        </Card>

                        <br />

                        <Card title="Foto User dengan Barang">
                            <Space style={{
                                width: '100%',
                                maxHeight: tabKey.get() === '1' ? '100%' : 0,
                                opacity: tabKey.get() === '1' ? 1 : 0,
                            }} direction="vertical" size={25}>
                                <div className="wui-form-group">
                                    <div className="input-section">
                                        {!disable.get() && data.userWithStuffs.length > 0 &&
                                            <WuiUploadImages disabled={true} value={data.userWithStuffs}/>
                                        }
                                        {!disable.get() && data.userWithStuffs.length === 0 &&
                                            <Text>Tidak ada foto</Text>
                                        }
                                    </div>
                                </div>

                            </Space>
                        </Card>
                    </Col>
                </Row>

                <div className="wui-form-btn-group">
                    <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.back')}</Button>
                </div>
            </WuiContainer>
        </>
    )
}

export default AppPromotorHandoverForm
