import { api, createCancelTokenHandler } from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/b2c/product-stocks`, payload,{
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  export: function (params: any = null) {
      return api({
          url: `${endPoint()}/api/v1/b2c/product-stocks/export`,
          params,
          method: 'GET',
          responseType: 'blob',
      })
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
