import React, { useEffect, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "@hookstate/core";
import RequestJourneyPlanRepository from "../../../../../../../repositories/RequestJourneyPlanRepository";
import StoreRepository from "../../../../../../../repositories/StoreRepository";
import AssistantStoreLeaderRepository from "../../../../../../../repositories/AssistantStoreLeaderRepository";
import { AxiosError, AxiosResponse } from "axios";
import Moment from "react-moment";
import Space from "antd/es/space";
import Table from "antd/es/table";
import { useTranslation } from "react-i18next";
import { haveAccess } from "../../../../../../../functions/global";
import useAccess from "../../../../../../../@framework/utilities/hooks/useAccess";
import { permissions } from "../../../../../../../constant/permissions";
import moment from "moment";
import _ from "lodash";
import { Button, Col, Collapse, Row } from "antd";
import WuiFilterDateRange from "../../../../../../../@framework/wui/components/Filter/DateRange";
import WuiSelectMulti from "../../../../../../../@framework/wui/components/Select/Multi";
import WuiSelectSingle from "../../../../../../../@framework/wui/components/Select/Single";

var qs = require("qs");

const { Panel } = Collapse;

interface Props {
  active: boolean;
}

const TabPermintaan: React.FC<Props> = ({ active }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const acl = useAccess();
  const [searchParams, setSearchParams] = useSearchParams();
  let fullPath = window.location.pathname;

  const [renderFilter, setRenderFilter] = React.useState(true);

  // For DateRange Picker
  const [filterDate, setFilterDate] = React.useState<any>([
    searchParams.get("start_date")
      ? moment(searchParams.get("start_date"), "YYYY-MM-DD")
      : moment().startOf("month"),
    searchParams.get("end_date")
      ? moment(searchParams.get("end_date"), "YYYY-MM-DD")
      : moment().endOf("month"),
  ]);

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates);
  };
  // End DateRange Picker

  // Filters
  const localSelectDealers = JSON.parse(
    localStorage.getItem("journey.dealers") || "[]"
  );
  const [selectedDealers, setSelectedDealers] = React.useState<any[]>([]);

  // Filters
  const localSelectSales = JSON.parse(
    localStorage.getItem("journey.sales") || "[]"
  );
  const [selectedSales, setSelectedSales] = React.useState<any[]>([]);

  useEffect(() => {
    setFilterDataToQuery(true);
    // eslint-disable-next-line
  }, [selectedDealers, selectedSales, filterDate]);

  useEffect(() => {
    if (active) {
      setFilterDataToQuery();
    } else {
      setSearchParams("");
    }
    // eslint-disable-next-line
  }, [active]);

  // Table Config
  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);

  const setFilterDataToQuery = (isReset: boolean = false) => {
    let params = {
      tab: "Permintaan",
    };
    let reset = isReset;

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedDealers) {
      const multiValue = _.map(selectedDealers, "id");

      Object.assign(params, {
        dealers: multiValue,
      });
    }

    if (selectedSales) {
      const multiValue = _.map(selectedSales, "value");

      Object.assign(params, {
        sales: multiValue,
      });
    }

    if (filterDate) {
      Object.assign(params, {
        start_date: filterDate[0].format("YYYY-MM-DD"),
        end_date: filterDate[1].format("YYYY-MM-DD"),
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const getFilterDataFromQuery = () => {
    if (!localSelectDealers) {
      searchParams.delete("dealers");
    } else {
      const dealers = _.map(searchParams.getAll("dealers"), (item) =>
        parseInt(item)
      );
      if (dealers) {
        let dealerFromStorage = _.filter(localSelectDealers, (data) => {
          return dealers.includes(data.id);
        });
        setSelectedDealers(dealerFromStorage);
      } else {
        localStorage.removeItem("journey.dealers");
      }
    }

    if (!localSelectSales) {
      searchParams.delete("sales");
    } else {
      const sales = _.map(searchParams.getAll("sales"), (item) =>
        parseInt(item)
      );
      if (sales) {
        let salesFromStorage = _.filter(localSelectSales, (data) => {
          return sales.includes(data.value);
        });
        setSelectedSales(salesFromStorage);
      } else {
        localStorage.removeItem("journey.sales");
      }
    }
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
      start_date: filterDate[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      end_date: filterDate[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      stores: _.map(selectedDealers, "id"),
      assistant: _.map(selectedSales, "value"),
    };

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "descend";
    }

    await RequestJourneyPlanRepository.all("b2c", params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  const columns: any = [
    {
      title: "Tanggal Kunjungan",
      dataIndex: "visit_date",
      key: "visit_date",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "visit_date" && tableSort.sortOrder.get(),
      render: (text: any, record: any) => {
        return <Moment format="DD MMMM YYYY">{text}</Moment>;
      },
    },
    {
      title: "Kode Permintaan",
      dataIndex: "code",
      key: "code",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "code" && tableSort.sortOrder.get(),
    },
    {
      title: "Assistant Leader",
      dataIndex: "assistant_name",
      key: "assistant_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "assistant_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Toko",
      dataIndex: "store_name",
      key: "store_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "store_name" && tableSort.sortOrder.get(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "status" && tableSort.sortOrder.get(),
    },
    {
      title: "Tanggal Dibuat",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "created_at" && tableSort.sortOrder.get(),
      render: (text: any, record: any) => {
        return <Moment format="DD MMMM YYYY | HH:mm">{text}</Moment>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link
            to={
              record.status === "Pending" &&
              haveAccess(acl, permissions.write_status_request_journey_plan_b2c)
                ? fullPath + "/permintaan/approval/" + record.assistant_id
                : fullPath + "/permintaan/" + record.id
            }
          >
            Lihat Detail
          </Link>
        </Space>
      ),
    },
  ];
  // End Table Config

  const handleReset = () => {
    setSelectedSales([]);
    setSelectedDealers([]);
    setFilterDate([moment().startOf("month"), moment().endOf("month")]);
  };

  return (
    <>
      <Collapse
        className="mb16"
        defaultActiveKey={["1"]}
        expandIconPosition={"right"}
      >
        <Panel header="Filter" key="1">
          <Row className="mb6" gutter={[15, 15]}>
            <Col className="gutter-row" xs={24} md={12} lg={8}>
              <WuiFilterDateRange
                defaultValue={filterDate}
                callback={handleDateRangeCallback}
              />
            </Col>

            <Col className="gutter-row" xs={24} md={24} lg={12}>
              {
                //ini karena gw kaga bisa hapus pilihannya kalo ganti sales. butuh handle lbh baik
                renderFilter && (
                  <WuiSelectMulti
                    value={selectedDealers}
                    onChange={(val: any) => {
                      setSelectedDealers(val);
                      localStorage.setItem(
                        "journey.dealers",
                        JSON.stringify(val)
                      );
                    }}
                    selectParams={{
                      assistant: _.map(selectedSales, "value"),
                      channel: "b2c",
                    }}
                    repository={StoreRepository}
                    placeholder={t("select.placeholder", { item: "Toko" })}
                  />
                )
              }
            </Col>

            <Col className="gutter-row" xs={24} md={24} lg={12}>
              <WuiSelectSingle
                style={{ width: "100%" }}
                value={selectedSales.length > 0 ? selectedSales[0] : null}
                onChange={(val: any) => {
                  if(val) {
                    setSelectedSales([val]);
                    localStorage.setItem("journey.sales", JSON.stringify([val])); 
                  } else {
                    setSelectedSales([]);
                    localStorage.setItem("journey.sales", JSON.stringify([])); 
                  }
                  setRenderFilter(false);
                  setSelectedDealers([]);
                  localStorage.removeItem("journey.dealers");
                  setTimeout(() => setRenderFilter(true), 200);
                }}
                repository={AssistantStoreLeaderRepository}
                placeholder={t("select.placeholder", {
                  item: "Assistant Leader",
                })}
                selectParams={{
                  channel: "b2c",
                }}
                allowClear
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Table
        rowKey={"id"}
        bordered
        columns={columns}
        dataSource={tableData.get()}
        loading={tableLoading}
        onChange={handleTableChange}
        pagination={{
          current: tablePage.get(),
          simple: true,
          pageSize: tablePerPage.get(),
          total: totalData.get(),
        }}
      />
    </>
  );
};

export default TabPermintaan;
