import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
// import {useIsMounted} from "../../../../@framework/utilities/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import Accessible from "../../../../@framework/wui/components/Accessible";
import Button from "antd/es/button";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Input from "antd/es/input";
import WuiContainer from "../../../../@framework/wui/components/Container";
import { useState } from "@hookstate/core";
import Space from "antd/es/space";
import useDebounce from "../../../../@framework/utilities/hooks/useDebounce";
import Collapse from "antd/es/collapse";
import Table from "antd/es/table";
import WuiSelectMulti from "../../../../@framework/wui/components/Select/Multi";
import _ from "lodash";
import LiveDisplayUnitRepository from "../../../../repositories/LiveDisplayUnitRepository";
import { AxiosError, AxiosResponse } from "axios";
import StoreRepository from "../../../../repositories/StoreRepository";
import BusinessUnitChannelRepository from "../../../../repositories/BusinessUnitChannelRepository";
import { permissions } from "../../../../constant/permissions";
import LiveDisplayUnitCategoryRepository from "../../../../repositories/LiveDisplayUnitCategoryRepository";

const { Panel } = Collapse;

var qs = require("qs");

const breadcrumbs = [
  {
    label: "LDU",
    link: "/ldu",
  },
];

type LDUList = {
  id: number;
  store_id: number;
  created_at: string;
  store_code: string;
  store_name: string;
  channel_name: string;
  category_name: string;
};

const AppLDUList: React.FC<any> = () => {
  const { t } = useTranslation();
  // const isMounted = useIsMounted()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let fullPath = window.location.pathname;

  // Table
  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState<LDUList[]>([]);
  const totalData = useState<number>(0);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      search: debouncedSearch,
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
    };

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "descend";
    }

    const storeIds = Array.from(
      new Set([
        ..._.map(selectedStoreCode, "id"),
        ..._.map(selectedStoreName, "id"),
      ])
    );
    if (storeIds.length > 0) {
      Object.assign(params, {
        stores: storeIds,
      });
    }

    const storeChannelIds = _.map(selectedChannel, "id");
    if (storeChannelIds.length > 0) {
      Object.assign(params, {
        store_channel: storeChannelIds,
      });
    }

    const storeCategoryIds = _.map(selectedCategory, "id");
    if (storeCategoryIds.length > 0) {
      Object.assign(params, {
        category: storeCategoryIds,
      });
    }

    await LiveDisplayUnitRepository.all(params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });
  };

  const columns: any = [
    {
      title: "Kode Toko",
      dataIndex: "store_code",
      key: "store_code",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "store_code" && tableSort.sortOrder.get(),
    },
    {
      title: "Nama Toko",
      dataIndex: "store_name",
      key: "store_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "store_name" && tableSort.sortOrder.get(),
    },
    {
      title: "Channel",
      dataIndex: "channel_name",
      key: "channel_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "channel_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Kategory",
      dataIndex: "category_name",
      key: "category_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "category_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={fullPath + "/" + record.id}>Lihat Detail</Link>
        </Space>
      ),
    },
  ];

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  // For Filter
  const localSelectedStoreCode = JSON.parse(
    localStorage.getItem("ldu.storeCode") || "[]"
  );
  const [selectedStoreCode, setSelectedStoreCode] = React.useState<any[]>([]);

  const localSelectedStoreName = JSON.parse(
    localStorage.getItem("ldu.storeName") || "[]"
  );
  const [selectedStoreName, setSelectedStoreName] = React.useState<any[]>([]);

  const localSelectedChannel = JSON.parse(
    localStorage.getItem("ldu.channel") || "[]"
  );
  const [selectedChannel, setSelectedChannel] = React.useState<any[]>([]);

  const localSelectedCategory = JSON.parse(
    localStorage.getItem("ldu.category") || "[]"
  );
  const [selectedCategory, setSelectedCategory] = React.useState<any[]>([]);

  useEffect(() => {
    setFilterDataToQuery();
    // eslint-disable-next-line
  }, [
    debouncedSearch,
    selectedStoreCode,
    selectedStoreName,
    selectedChannel,
    selectedCategory,
  ]);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if (tablePage.get() !== 1 && !reset) {
      Object.assign(params, {
        page: tablePage.get(),
      });
    }

    if (tablePerPage.get() !== 10 && !reset) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get() && !reset) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get() && !reset) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedStoreCode) {
      const multiValue = _.map(selectedStoreCode, "id");
      Object.assign(params, {
        merchantCode: multiValue,
      });
    }

    if (selectedStoreName) {
      const multiValue = _.map(selectedStoreName, "id");
      Object.assign(params, {
        merchantName: multiValue,
      });
    }

    if (selectedChannel) {
      const multiValue = _.map(selectedChannel, "id");
      Object.assign(params, {
        channel: multiValue,
      });
    }

    if (selectedCategory) {
      const multiValue = _.map(selectedCategory, "id");
      Object.assign(params, {
        category: multiValue,
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }

    if (!localSelectedStoreCode) {
      searchParams.delete("merchantCode");
    } else {
      const items = _.map(searchParams.getAll("merchantCode"), (item) =>
        parseInt(item)
      );
      if (items) {
        let itemFromStorage = _.filter(localSelectedStoreCode, (data) => {
          return items.includes(data.id);
        });
        setSelectedStoreCode(itemFromStorage);
      } else {
        localStorage.removeItem("ldu.storeCode");
      }
    }

    if (!localSelectedStoreName) {
      searchParams.delete("merchantName");
    } else {
      const items = _.map(searchParams.getAll("merchantName"), (item) =>
        parseInt(item)
      );
      if (items) {
        let itemFromStorage = _.filter(localSelectedStoreName, (data) => {
          return items.includes(data.id);
        });
        setSelectedStoreName(itemFromStorage);
      } else {
        localStorage.removeItem("ldu.storeName");
      }
    }

    if (!localSelectedChannel) {
      searchParams.delete("channel");
    } else {
      const items = _.map(searchParams.getAll("channel"), (item) =>
        parseInt(item)
      );
      if (items) {
        let itemFromStorage = _.filter(localSelectedChannel, (data) => {
          return items.includes(data.id);
        });
        setSelectedChannel(itemFromStorage);
      } else {
        localStorage.removeItem("ldu.channel");
      }
    }

    if (!localSelectedCategory) {
      searchParams.delete("category");
    } else {
      const items = _.map(searchParams.getAll("category"), (item) =>
        parseInt(item)
      );
      if (items) {
        let itemFromStorage = _.filter(localSelectedCategory, (data) => {
          return items.includes(data.id);
        });
        setSelectedCategory(itemFromStorage);
      } else {
        localStorage.removeItem("ldu.category");
      }
    }
  };

  const handleReset = () => {
    setSelectedStoreCode([]);
    setSelectedStoreName([]);
    setSelectedChannel([]);
    setSelectedCategory([]);
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={"LDU"} breadcrumbs={breadcrumbs}>
          <Accessible access={permissions.write_live_display_unit_b2c}>
            <Button
              onClick={() => navigate(fullPath + "/form")}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("common.button.addNew")}
            </Button>
          </Accessible>
        </WuiSectionTitle>

        <Row gutter={[10, 10]} className="mb16">
          <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
            <Input
              allowClear
              placeholder={t("common.filter.search.placeholder")}
              prefix={<SearchOutlined />}
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
            />
          </Col>
        </Row>

        <Collapse
          className="mb16"
          defaultActiveKey={["1"]}
          expandIconPosition={"right"}
        >
          <Panel header="Filter" key="1">
            <Row className="mb6" gutter={[10, 10]}>
              <Col className="gutter-row" xs={24} md={6} lg={5}>
                <WuiSelectMulti
                  value={selectedStoreCode}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedStoreCode(val);
                    localStorage.setItem("ldu.storeCode", JSON.stringify(val));
                  }}
                  repository={StoreRepository}
                  selectParams={{
                    channel: "b2c",
                  }}
                  labelKey={"code"}
                  placeholder={t("select.placeholder", { item: "Kode Toko" })}
                />
              </Col>

              <Col className="gutter-row" xs={24} md={6} lg={5}>
                <WuiSelectMulti
                  value={selectedStoreName}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedStoreName(val);
                    localStorage.setItem("ldu.storeName", JSON.stringify(val));
                  }}
                  repository={StoreRepository}
                  selectParams={{
                    channel: "b2c",
                  }}
                  placeholder={t("select.placeholder", { item: "Nama Toko" })}
                />
              </Col>

              <Col className="gutter-row" xs={24} md={6} lg={5}>
                <WuiSelectMulti
                  value={selectedChannel}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedChannel(val);
                    localStorage.setItem("ldu.channel", JSON.stringify(val));
                  }}
                  repository={BusinessUnitChannelRepository}
                  placeholder={t("select.placeholder", { item: "Channel" })}
                />
              </Col>

              <Col className="gutter-row" xs={24} md={6} lg={5}>
                <WuiSelectMulti
                  value={selectedCategory}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedCategory(val);
                    localStorage.setItem("ldu.category", JSON.stringify(val));
                  }}
                  repository={LiveDisplayUnitCategoryRepository}
                  placeholder={t("select.placeholder", { item: "Kategori" })}
                />
              </Col>

              <Col>
                <Button type="link" onClick={handleReset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Table
          rowKey={"id"}
          bordered
          columns={columns}
          dataSource={tableData.get()}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={{
            current: tablePage.get(),
            simple: true,
            pageSize: tablePerPage.get(),
            total: totalData.get(),
          }}
        />
      </WuiContainer>
    </>
  );
};

export default AppLDUList;
