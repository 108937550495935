import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Form from "antd/lib/form";
import {useState} from "@hookstate/core";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import PageHeader from "antd/es/page-header";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import {Checkbox, Spin} from "antd";
import Input from "antd/es/input";
import {TChannels} from "../../../../../utils";
import {permissions} from "../../../../../constant/permissions";
import KPIItemRepository from "../../../../../repositories/KPIItemRepository";
import {AxiosError, AxiosResponse} from "axios";
import {handleBackendError, haveAccess, inputUppercase, openNotification} from "../../../../../functions/global";
import _ from "lodash"
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";

let title = "Item KPI";

const channelOptions: TChannels[] = ["B2B", "B2C"];

const permissionMapping: any = {
    b2b: {
        write: permissions.write_item_kpi_b2b,
    },
    b2c: {
        write: permissions.write_item_kpi_b2c,
    },
};

const AppKPIItemForm: React.FC<any> = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id
        ? t("common.text.editItem", { item: title })
        : t("common.text.addNewItem", { item: title });
    const acl = useAccess();

    const breadcrumbs = [
        {
            label: 'KPI',
            link: '/kpi'
        },
        {
            label: 'Item KPI',
            link: '/kpi/item-kpi'
        },
        (
            id ? {
                label: 'Detail Item KPI',
                link: `/kpi/item-kpi/${id}`
            } : {
                label: 'Tambah Item KPI',
                link: '/kpi/item-kpi/form'
            }
        )
    ]

    // Form
    const [form] = Form.useForm();
    const channels = Form.useWatch('channels', form);
    const disable = useState(false);
    const loading = useState(false);

    const onFinish = async (data: any) => {
        loading.set(true);

        let payload = {
            code: data.code,
            name: data.name,
            is_b2b: _.includes(channels, 'B2B'),
            is_b2c: _.includes(channels, 'B2C')
        }

        await (id ? KPIItemRepository.update(id, payload) : KPIItemRepository.create(payload)).then((res: AxiosResponse) => {
            navigate(-1);

            if (!id) {
                openNotification(
                    "success",
                    t("notification.success.createItem", { item: title })
                );
            } else {
                openNotification(
                    "success",
                    t("notification.success.updateItem", { item: title })
                );
                loading.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
            loading.set(false);
        });


        setTimeout(() => {
            loading.set(false)
        }, 1000)
    }

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true);

        await KPIItemRepository.show(id).then((res: AxiosResponse) => {
            const data = res.data?.data || {};

            let dataChannels = []

            if (data?.is_b2b) dataChannels.push('B2B')
            if (data?.is_b2c) dataChannels.push('B2C')

            form.setFieldsValue({
                name: data?.name,
                code: data?.code,
                channels: dataChannels
            })

            if (haveAccess(acl, [permissions.write_item_kpi_b2b, permissions.write_item_kpi_b2c])) {
                disable.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e);
        })
    }

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={''} breadcrumbs={breadcrumbs}/>

                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />

                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Row gutter={[16, 16]}>
                        <Col
                            className="gutter-row"
                            md={{
                                span: 16,
                                order: 1,
                            }}
                            xs={{
                                span: 24,
                                order: 2,
                            }}
                        >
                            <Card className="mb16">
                                <Space
                                    style={{
                                        width: "100%",
                                    }}
                                    direction="vertical"
                                    size={5}
                                >
                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="code"
                                                label="Kode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("validation.required", {
                                                            item: "Kode",
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    disabled={disable.get()}
                                                    size={"large"}
                                                    placeholder={t("common.text.input", { item: "Kode" })}
                                                    onInput={inputUppercase.formatter}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="name"
                                                label={"Nama Item"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("validation.required", {
                                                            item: "Nama",
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    disabled={disable.get()}
                                                    size={"large"}
                                                    placeholder={t("common.text.input", {
                                                        item: "Nama",
                                                    })}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="channels"
                                                label="Channel"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("validation.required", {
                                                            item: "Channel",
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Checkbox.Group>
                                                    {channelOptions.map((channel) => {
                                                        return (
                                                        <Checkbox
                                                            value={channel}
                                                            disabled={
                                                            disable.get() ||
                                                            !haveAccess(
                                                                acl,
                                                                permissionMapping[channel.toLowerCase()].write
                                                            )
                                                            }
                                                        >
                                                            {channel}
                                                        </Checkbox>
                                                        );
                                                    })}
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Space>
                            </Card>
                        </Col>
                    </Row>

                    <div className="wui-form-btn-group">
                        <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.cancel')}</Button>
                        <Accessible access={[permissions.write_item_kpi_b2b, permissions.write_item_kpi_b2c]}>
                            <Spin spinning={loading.get()}>
                                <Button className="wui-btn" htmlType="submit" type="primary" size={"large"} disabled={disable.get()}>{t('common.button.save')}</Button>
                            </Spin>
                        </Accessible>
                    </div>
                </Form>
            </WuiContainer>
        </>
    )
}

export default AppKPIItemForm