import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Input from "antd/es/input";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  haveAccess,
  openNotification,
} from "../../../../../../functions/global";
import Spin from "antd/lib/spin";
import { Col, Row } from "antd/lib/grid";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import { TableMaps } from "../Component";
import StoreRepository from "../../../../../../repositories/StoreRepository";
import { permissions } from "../../../../../../constant/permissions";
import { TChannels } from "../../../../../../utils";
import BusinessUnitRepository from "../../../../../../repositories/BusinessUnitRepository";
import LocationRepository from "../../../../../../repositories/LocationRepository";
import BusinessUnitCategoryRepository from "../../../../../../repositories/BusinessUnitCategoryRepository";
import BusinessUnitSubCategoryRepository from "../../../../../../repositories/BusinessUnitSubCategoryRepository";
import BusinessUnitChannelRepository from "../../../../../../repositories/BusinessUnitChannelRepository";
import AreaRepository from "../../../../../../repositories/AreaRepository";
import { $clone } from "../../../../../../@framework/utilities";

let title = "Store";

const AppSettingsDataMasterStoreForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const selectedChannel = useState<TChannels>("b2c");
  const locations = useState<any>([]);

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const acl = useAccess();

  const onFinish = async (data: any) => {
    loading.set(true);

    const params: any = {
      location_id: data.location.value,
      business_unit_id: data.business_unit.value,
      business_unit_category_id: data.category.value,
      business_unit_sub_category_id: data.sub_category.value,
      business_unit_channel_id: data.channel.value,
      area_id: data.area.value,
    };

    if (!id) {
      params.code = data.code;
      params.name = data.name;
      params.post_code = data.post_code;
      params.address = data.address;
    }

    await (!id
      ? StoreRepository.create(selectedChannel.get(), params)
      : StoreRepository.update(selectedChannel.get(), id, params)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await StoreRepository.show(selectedChannel.get(), id, {
      with: [
        "location",
        "businessUnit",
        "businessUnitCategory",
        "businessUnitSubCategory",
        "businessUnitChannel",
        "area.territory.region",
      ],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        if (data?.location) {
          data.location = {
            value: res.data?.data?.location?.id,
            label: res.data?.data?.location?.name,
          };
        }

        if (data?.business_unit) {
          data.business_unit = {
            value: res.data?.data?.business_unit?.id,
            label: res.data?.data?.business_unit?.name,
          };
        }

        if (data?.business_unit_category) {
          data.category = {
            value: res.data?.data?.business_unit_category?.id,
            label: res.data?.data?.business_unit_category?.name,
          };
        }

        if (data?.business_unit_sub_category) {
          data.sub_category = {
            value: res.data?.data?.business_unit_sub_category?.id,
            label: res.data?.data?.business_unit_sub_category?.name,
          };
        }

        if (data?.business_unit_channel) {
          data.channel = {
            value: res.data?.data?.business_unit_channel?.id,
            label: res.data?.data?.business_unit_channel?.name,
          };
        }

        if (data?.area) {
          data.area = {
            value: res.data?.data?.area?.id,
            label: res.data?.data?.area?.name,
          };
        }

        form.setFieldsValue({
          ...data,
        });

        if (res.data?.data?.lng_lat) {
          locations.set([
            {
              lng_lat: res.data?.data?.lng_lat,
              created_at: res.data?.data?.updated_at,
            },
          ]);
        }

        if (haveAccess(acl, permissions.write_store_b2c)) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card title={t("common.text.itemInformation", { item: title })}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="code"
                        label={"Kode"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Kode" }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get() || !!id}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Kode" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Nama"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Nama",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get() || !!id}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Nama",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="location"
                        label={"Lokasi"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Lokasi",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={LocationRepository}
                          disabled={disable.get()}
                          placeholder={t("select.placeholder", {
                            item: "Lokasi",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="business_unit"
                        label={"Business Unit"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Business Unit",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={BusinessUnitRepository}
                          disabled={disable.get()}
                          placeholder={t("select.placeholder", {
                            item: "Business Unit",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="category"
                        label={"Category"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Category",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={BusinessUnitCategoryRepository}
                          disabled={disable.get()}
                          placeholder={t("select.placeholder", {
                            item: "Category",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="sub_category"
                        label={"Sub Category"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Sub Category",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={BusinessUnitSubCategoryRepository}
                          disabled={disable.get()}
                          placeholder={t("select.placeholder", {
                            item: "Sub Category",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="channel"
                        label={"Channel"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Channel",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={BusinessUnitChannelRepository}
                          disabled={disable.get()}
                          placeholder={t("select.placeholder", {
                            item: "Channel",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
              <br />
              <Card>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="area"
                        label={"Area"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Area",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={AreaRepository}
                          disabled={disable.get()}
                          placeholder={t("select.placeholder", {
                            item: "Area",
                          })}
                          selectParams={{
                            channel: "b2c",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Space direction="vertical" style={{ width: "50%" }}>
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="post_code"
                          label={"Kode Pos"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Kode Pos",
                              }),
                            },
                          ]}
                        >
                          <Input
                            disabled={disable.get() || !!id}
                            size={"large"}
                            placeholder={t("common.text.input", {
                              item: "Kode Pos",
                            })}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Space>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="address"
                        label={"Alamat"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Alamat",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get() || !!id}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Alamat",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <TableMaps dataSource={$clone(locations.get())} />
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible access={permissions.write_store_b2c}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsDataMasterStoreForm;
