import Table from "antd/lib/table";
import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import SalesRepository from "../../../../../../../repositories/SalesRepository";

type TableData = {
  id: number;
  code: string;
  cluster_name: string;
  name: string;
};

type TableSalesConsultantProps = {
  brandId: string;
  search: string;
};

const TableSalesConsultant = ({
  brandId,
  search = "",
}: TableSalesConsultantProps) => {
  const [data, setData] = useState<TableData[]>([]);
  const [tableQuery, setTableQuery] = useState({
    page: 1,
    per_page: 5,
  });
  const [totalData, setTotalData] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = useCallback(async () => {
    setLoading(true);

    await SalesRepository.all({
      ...tableQuery,
      brand: brandId,
      ...(search ? { search: search } : {}),
    })
      .then((response: AxiosResponse) => {
        setData(response.data.data);
        setTotalData(response.data.total);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [brandId, tableQuery, search]);

  const handleTableChange = useCallback((pagination: any) => {
    setTableQuery({
      page: pagination.current,
      per_page: pagination.pageSize,
    });
  }, []);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [tableQuery, getData, search]);

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cluster",
      dataIndex: "cluster_name",
      key: "cluster_name",
    },
  ];

  return (
    <>
      <Table
        rowKey={"id"}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        columns={columns}
        pagination={{
          current: tableQuery.page,
          simple: true,
          pageSize: tableQuery.per_page,
          total: totalData,
        }}
      />
    </>
  );
};

export default TableSalesConsultant;
