import {api, createCancelTokenHandler} from "../@framework/services";
import {TChannels} from "../utils";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    all: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/issue-trackings`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
        });
    },
    show: function (channel: TChannels, id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/issue-tracking/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
        });
    },
    update: function (channel: TChannels, id: number | string, payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/issue-tracking/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
        });
    },
    delete: function (channel: TChannels, id: number | string, params: any = null) {
        return api.delete(`${endPoint()}/api/v1/${channel}/issue-tracking/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
        });
    }
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;