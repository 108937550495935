import { Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  StockOpnameProduct,
  StockOpnameProductDetailExportProps,
} from "./interface";
import "./_style.less";
import StockOpnameRepository from "../../repositories/StockOpnameRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

moment.updateLocale("en", {
  weekdays: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
});

const { Text, Title } = Typography;

const NoDataTable = ({ colSpan = 0 }: any) => {
  return (
    <tr>
      <td colSpan={colSpan} style={{ textAlign: "center" }}>
        No Data
      </td>
    </tr>
  );
};

const StockOpnameProductDetailExport = React.forwardRef(
  (
    props: StockOpnameProductDetailExportProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const {
      stockOpnameData,
      store,
      revisionNumber = 0,
      hasInit = false,
    } = props;
    const { id } = useParams();

    const [goodStockManualProduct, setGoodStockManualProduct] = useState<
      StockOpnameProduct[]
    >([]);
    const [damageManualProduct, setDamageManualProduct] = useState<
      StockOpnameProduct[]
    >([]);
    const [demoManualProduct, setDemoManualProduct] = useState<
      StockOpnameProduct[]
    >([]);
    const [goodStockImportProduct, setGoodStockImportProduct] = useState<
      StockOpnameProduct[]
    >([]);
    const [damageImportProduct, setDamageImportProduct] = useState<
      StockOpnameProduct[]
    >([]);
    const [demoImportProduct, setDemoImportProduct] = useState<
      StockOpnameProduct[]
    >([]);

    const renderTableProductManual = useCallback(
      (products: any, key: string) => {
        if (products.length < 1) {
          return <NoDataTable colSpan={6} />;
        }

        return products.map((product: any, index: number) => {
          const storeStock = product.store_stock || 0;
          const systemStock = product.system_stock || 0;

          return (
            <tr key={`${index}_${key}`}>
              <td>
                {typeof product.product_code === "string"
                  ? product.product_code
                  : product.product_code.label}
              </td>
              <td>
                {typeof product.product_name === "string"
                  ? product.product_name
                  : product.product_name.label}
              </td>
              <td>{product.system_stock}</td>
              <td>{product.store_stock}</td>
              <td>{systemStock - storeStock}</td>
              <td>{product.description}</td>
            </tr>
          );
        });
      },
      []
    );

    const renderTableProductImport = useCallback(
      (products: StockOpnameProduct[], key: string) => {
        if (products.length < 1) {
          return <NoDataTable colSpan={6} />;
        }

        return products.map((product, index) => {
          const storeStock = product.store_stock || 0;
          const systemStock = product.system_stock || 0;

          return (
            <tr key={`${index}_${key}`}>
              <td>{product.product_code}</td>
              <td>{product.product_name}</td>
              <td>{product.system_stock}</td>
              <td>{product.store_stock}</td>
              <td>{systemStock - storeStock}</td>
              <td>{product.description}</td>
            </tr>
          );
        });
      },
      []
    );

    const renderGoodStockImportProductTable = useMemo(() => {
      return renderTableProductImport(
        goodStockImportProduct,
        "import_product_good_stock"
      );
    }, [goodStockImportProduct]);

    const renderDamageImportProductTable = useMemo(() => {
      return renderTableProductImport(
        damageImportProduct,
        "import_product_damage"
      );
    }, [damageImportProduct]);

    const renderDemoImportProductTable = useMemo(() => {
      return renderTableProductImport(demoImportProduct, "import_product_demo");
    }, [demoImportProduct]);

    const renderGoodStockManualProductTable = useMemo(() => {
      return renderTableProductManual(
        goodStockManualProduct,
        "manual_product_good_stock"
      );
    }, [goodStockManualProduct]);

    const renderDamageManualProductTable = useMemo(() => {
      return renderTableProductManual(
        damageManualProduct,
        "manual_product_damage"
      );
    }, [damageManualProduct]);

    const renderDemoManualProductTable = useMemo(() => {
      return renderTableProductManual(demoManualProduct, "manual_product_demo");
    }, [demoManualProduct]);

    const renderTableHeader = useMemo(
      () => (
        <thead>
          <tr>
            <th>Kode Produk</th>
            <th>Nama Produk</th>
            <th>Stok Sistem</th>
            <th>Stok Toko</th>
            <th>Selisih</th>
            <th>Keterangan</th>
          </tr>
        </thead>
      ),
      []
    );

    const loadDataImportProduct = useCallback(async () => {
      const params = {
        page: 1,
        per_page: 9999,
        order_by: "created_at",
        sorted_by: "desc",
        revision_number: revisionNumber,
      };

      await StockOpnameRepository.importProducts(id, params)
        .then((response: AxiosResponse) => {
          const data: StockOpnameProduct[] = response.data.data;
          setDemoImportProduct(data.filter((item) => item.status === "Demo"));
          setGoodStockImportProduct(
            data.filter((item) => item.status === "Good Stock")
          );
          setDamageImportProduct(
            data.filter((item) => item.status === "Damage")
          );
        })
        .catch((e: AxiosError) => {});
    }, [id, revisionNumber]);

    const loadDataManualProduct = useCallback(async () => {
      const params = {
        page: 1,
        per_page: 9999,
        order_by: "created_at",
        sorted_by: "desc",
        revision_number: revisionNumber,
      };

      await StockOpnameRepository.manualProducts(id, params)
        .then((response: AxiosResponse) => {
          const data: StockOpnameProduct[] = response.data.data;
          setDemoManualProduct(data.filter((item) => item.status === "Demo"));
          setGoodStockManualProduct(
            data.filter((item) => item.status === "Good Stock")
          );
          setDamageManualProduct(
            data.filter((item) => item.status === "Damage")
          );
        })
        .catch((e: AxiosError) => {});
    }, [id, revisionNumber]);

    useEffect(() => {
      if (id && stockOpnameData && hasInit) {
        if (revisionNumber >= 0) {
          loadDataManualProduct().then();
          loadDataImportProduct().then();
        }
      }
    }, [id, stockOpnameData, revisionNumber, hasInit]);

    return (
      <div id="stock-opname-product-export-wrapper" ref={ref}>
        <div className="container">
          <Title level={4} className="document-title">
            Berita Acara Stock Opname
          </Title>

          <div className="stock-opname-result">
            <Text>Berikut adalah hasil stock opname yang dilakukan pada: </Text>
            <div className="result-list">
              <div className="result-list-keys">
                <div className="result-list-key">Hari</div>
                <div className="result-list-key">Tanggal</div>
                <div className="result-list-key">Outlet</div>
                <div className="result-list-key">Revisi</div>
              </div>
              <div className="result-list-values">
                <div className="result-list-value">
                  :{" "}
                  {stockOpnameData
                    ? moment(stockOpnameData.created_at).format("dddd")
                    : moment().format("dddd")}
                </div>
                <div className="result-list-value">
                  :{" "}
                  {stockOpnameData
                    ? moment(stockOpnameData.created_at).format("DD MMMM YYYY")
                    : moment().format("DD MMMM YYYY")}
                </div>
                <div className="result-list-value">
                  : {stockOpnameData ? stockOpnameData.store.name : store}
                </div>
                <div className="result-list-value">
                  {`: Revisi ${
                    stockOpnameData ? stockOpnameData.latest_revision_number : 0
                  }`}
                </div>
              </div>
            </div>
            <Text>Hasil Stock Opname dengan detail sebagai berikut:</Text>
          </div>

          <div className="table-list">
            <div className="table-item">
              <div className="table-title">Tabel Produk Good Stock</div>

              <table>
                {renderTableHeader}
                <tbody>{renderGoodStockImportProductTable}</tbody>
              </table>
            </div>
            <div className="table-item">
              <div className="table-title">Tabel Produk Damage</div>

              <table>
                {renderTableHeader}
                <tbody>{renderDamageImportProductTable}</tbody>
              </table>
            </div>
            <div className="table-item">
              <div className="table-title">Tabel Produk Demo</div>

              <table>
                {renderTableHeader}
                <tbody>{renderDemoImportProductTable}</tbody>
              </table>
            </div>

            <div className="table-item">
              <div className="table-title">Tabel Produk Manual Good Stock</div>

              <table>
                {renderTableHeader}
                <tbody>{renderGoodStockManualProductTable}</tbody>
              </table>
            </div>

            <div className="table-item">
              <div className="table-title">Tabel Produk Manual Damage</div>

              <table>
                {renderTableHeader}
                <tbody>{renderDamageManualProductTable}</tbody>
              </table>
            </div>

            <div className="table-item">
              <div className="table-title">Tabel Produk Manual Demo</div>

              <table>
                {renderTableHeader}
                <tbody>{renderDemoManualProductTable}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/*<div className="page-break" />*/}
      </div>
    );
  }
);

export default StockOpnameProductDetailExport;
