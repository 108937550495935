import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Input from "antd/es/input";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import { AxiosError, AxiosResponse } from "axios";
import {
    handleBackendError,
    haveAccess, inputUppercase,
    openNotification,
} from "../../../../../../functions/global";
import Spin from "antd/lib/spin";
import { Col, Row } from "antd/lib/grid";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import BrandRepository from "../../../../../../repositories/BrandRepository"
import ProductTypeRepository from '../../../../../../repositories/ProductTypeRepository'
import { permissions } from "../../../../../../constant/permissions";

let title = "Tipe Produk";

const AppSettingsDataMasterProductTypeForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const acl = useAccess();

  const onFinish = async (data: any) => {
    loading.set(true);
    let payload = {
        code: data.code,
        name: data.name,
        brand_id: data.brand.value
    }
    await (!id ? (ProductTypeRepository.create(payload)) : ProductTypeRepository.update(id, payload)).then((res: AxiosResponse) => {
        navigate(-1)
        if (!id) {
            openNotification('success', t('notification.success.createItem', {item: title}))
        } else {
            openNotification('success', t('notification.success.updateItem', {item: title}))
            loading.set(false);
        }
    }).catch((e: AxiosError) => {
        handleBackendError(e, t('notification.error.default'))
        loading.set(false);
    })
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true)
    await ProductTypeRepository.show(id, {
        with: 'brand'
    }).then((res: AxiosResponse) => {
        form.setFieldsValue({
            brand: {
                value: res.data?.data?.brand.id,
                label: res.data?.data?.brand.name
            },
            code: res.data?.data?.code,
            name: res.data?.data?.name
        })
        if(haveAccess(acl, [permissions.write_product_type_b2b, permissions.write_product_type_b2c])){
            disable.set(false)
        }
    }).catch((e: AxiosError) => {
        handleBackendError(e, t('notification.error.default'))
    })
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row>
            <Col md={16} xs={24}>
              <Card title={t("common.text.itemInformation", { item: title })}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="code"
                        label={"Kode"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Kode" }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Kode" })}
                          onInput={inputUppercase.formatter}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="brand"
                        label={"Brand"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Brand",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={BrandRepository}
                          disabled={disable.get()}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Tipe Produk"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Tipe Produk",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Tipe Produk",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>

            <Accessible access={[permissions.write_product_type_b2b, permissions.write_product_type_b2c]}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsDataMasterProductTypeForm;
