import { useState } from "@hookstate/core";
import { DatePicker, Spin } from "antd";
import { InputNumber, Select } from "antd/es";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Space from "antd/es/space";
import Form from "antd/lib/form";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import { MONTH_OPTIONS } from "../../../../../../constant";
import { permissions } from "../../../../../../constant/permissions";
import {
  handleBackendError,
  haveAccess,
  inputNumberThousandSeparator,
  openNotification,
} from "../../../../../../functions/global";
import KPITargetStoreRepository from "../../../../../../repositories/KPITargetStoreRepository";
import StoreRepository from "../../../../../../repositories/StoreRepository";

let title = "KPI Target Store";

const { Option } = Select;

const AppKPITargetStoreForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const acl = useAccess();

  // Form
  const [form] = Form.useForm();
  const watchYear = Form.useWatch("year", form);
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);
    const year = moment(data.year).format("YYYY");
    const monthData: any = {};
    MONTH_OPTIONS.forEach((month) => {
      monthData[`${month.toLowerCase()}_target`] =
        data[`${month.toLowerCase()}_target_${year}`];
    });
    let payload: any = {
      ...monthData,
      store_id: data?.store_id?.value,
      year: year,
    };

    await (!id
      ? KPITargetStoreRepository.create(payload)
      : KPITargetStoreRepository.update(id, payload)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await KPITargetStoreRepository.show(id, {
      with: ["store.businessUnit"],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        const year = data.year;
        const monthData: any = {};
        MONTH_OPTIONS.forEach((month) => {
          monthData[`${month.toLowerCase()}_target_${year}`] =
            data[`${month.toLowerCase()}_target`];
        });

        console.log(monthData, "month dat");

        form.setFieldsValue({
          ...monthData,
          store_id: {
            label: data.store.name,
            value: data.store.id,
            key: `${data?.store.id}-${data?.store.name}-business_unit${
              data?.store?.business_unit?.name ?? ""
            }`,
          },
          year: moment(String(data.year)),
        });

        if (haveAccess(acl, [permissions.write_store_kpi_b2c])) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});
  };

  let getBusinessUnitValue = (store: any): string => {
    const split = store?.key?.split("business_unit") ?? [];
    if (split.length < 2) return "";
    return split[1];
  };

  const alreadyPastMonth = useCallback((month: string): boolean => {
    const currentYear = moment().year();
    const currentMonth = moment().month();

    const selectedYear = form.getFieldValue("year")?.year();

    if (!selectedYear) return true;

    if (selectedYear < currentYear) {
      return true;
    } else if (
      // eslint-disable-next-line eqeqeq
      selectedYear == currentYear &&
      moment().month(month).month() < currentMonth
    ) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watchYear);

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row>
            <Col md={16} xs={24}>
              <Card>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name={"store_id"}
                        label={"Toko"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Toko",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          disabled={disable.get()}
                          repository={StoreRepository}
                          selectParams={{
                            channel: "b2c",
                            with: "businessUnit",
                          }}
                          customOptions={(item: any) => {
                            return (
                              <Option
                                key={`${item.id}-${item.name}-business_unit${
                                  item?.business_unit?.name ?? ""
                                }`}
                                value={item.id}
                                label={`${item.code} - ${item.name}`}
                              >
                                {`${item.code} - ${item.name}`}
                              </Option>
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item label="Business Unit" shouldUpdate>
                        {() => {
                          return (
                            <Input
                              value={getBusinessUnitValue(
                                form.getFieldValue("store_id")
                              )}
                              disabled={true}
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Nama",
                              })}
                            />
                          );
                        }}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name={"year"}
                        label={"Tahun"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Tahun",
                            }),
                          },
                        ]}
                      >
                        <DatePicker
                          style={{
                            width: "100%",
                          }}
                          allowClear={false}
                          picker="year"
                          placeholder={"Pilih Tahun"}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Row gutter={16}>
                    <Col className="mb8" xs={12}>
                      <div className="wui-form-group type-column">
                        <WuiFormTitle title="Bulan" />
                      </div>
                    </Col>
                    <Col className="mb8" xs={12}>
                      <div className="wui-form-group type-column">
                        <WuiFormTitle title="Target" />
                      </div>
                    </Col>
                    {MONTH_OPTIONS.map((month) => {
                      return (
                        <React.Fragment key={`${month}_${watchYear?.year()}`}>
                          <Col xs={12}>
                            <div className="wui-form-group type-column">
                              <div className="input-section">
                                <Input
                                  disabled={true}
                                  size={"large"}
                                  value={month}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="wui-form-group type-column">
                              <div className="input-section">
                                <Form.Item
                                  name={`${month.toLowerCase()}_target_${watchYear?.year()}`}
                                  rules={[
                                    {
                                      required: !alreadyPastMonth(month),
                                      message: t("validation.required", {
                                        item: month + " Target",
                                      }),
                                    },
                                  ]}
                                  initialValue={
                                    alreadyPastMonth(month) ? 0 : ""
                                  }
                                >
                                  <InputNumber
                                    style={{ width: "100%" }}
                                    disabled={
                                      disable.get() || alreadyPastMonth(month)
                                    }
                                    size={"large"}
                                    placeholder={t("common.text.input", {
                                      item: month + " Target",
                                    })}
                                    formatter={
                                      inputNumberThousandSeparator.formatter
                                    }
                                    parser={inputNumberThousandSeparator.parser}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                        </React.Fragment>
                      );
                    })}
                  </Row>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible access={permissions.write_store_kpi_b2c}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppKPITargetStoreForm;
