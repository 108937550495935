import { api, createCancelTokenHandler } from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    all: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/kpi-targets`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token,
        });
    },
    show: function (id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/kpi-target/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token,
        });
    },
    create: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-target`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token,
        });
    },
    update: function (id: number | string, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/multi/kpi-target/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token,
        });
    },
    import: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-target-import`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.import.name].handleRequestCancellation().token
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;