import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {Tabs} from "antd";
import {useState} from "@hookstate/core";
import AppDefaultTableau from "../../../../../../components/Tableau";
import _, {toLower} from "lodash";
import AccountRepository from "../../../../../../repositories/AccountRepository";
import {AxiosError, AxiosResponse} from "axios";
import {useAppSelector} from "../../../../../../stores/hooks";
import {$clone} from "../../../../../../@framework/utilities";

var qs = require("qs");

interface Props {
  active: boolean;
  setParams?: Function;
}

const { TabPane } = Tabs;

const DashboardB2CTabA: React.FC<Props> = (
    {
        active,
        setParams
    }
) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabKey = useState(searchParams.get('type') || 'BestStorePerformance')
    const {account} = useAppSelector((state) => state.system)

    // Tableau
    const tableauReady = useState<boolean>(false);
    const tableauFilter = useState<string>('')

    const setFilterDataToQuery = (isReset: boolean = false) => {
        let params = {
            tab: 'Tab A',
            type: tabKey.get()
        }

        const queryParams = qs.stringify(params, { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        }
    }

    const getDataTableauFilter = async () => {
        const userTableauCode = toLower(_.find(account?.user_superiors, {channel: 'B2B'})?.tableau_code || '')

        if (tabKey.get() === 'BestStorePerformance') {
            await AccountRepository.allSuperior().then((res: AxiosResponse) => {
                const tempData = res?.data?.data;

                let userHOR = _.find(tempData?.B2C, {area_hierarchy: 'Region'})

                let tempFilter = 'Channel=RETAIL'

                if (tempData?.B2C) {
                    tempData.B2C.map((item: any) => {
                        if (toLower(item?.tableau_code) === 'ash') {
                            tempFilter += `,sl=${item.name}`
                        } else if (toLower(item?.tableau_code) === 'tsh') {
                            tempFilter += `,tsh=${item.name}`
                        } else if (toLower(item?.tableau_code) === 'hot') {
                            tempFilter += `,hot=${item.name}`
                        }
                    })
                }

                if (userTableauCode === 'hot') tempFilter += `,hot=${account?.name}`
                else if (userTableauCode === 'tsh') tempFilter += `,tsh=${account?.name}`
                else if (userTableauCode === 'ash') tempFilter += `,sl=${account?.name}`
                else if (userTableauCode === 'sc') tempFilter += `,asl=${account?.name}`

                if (userHOR) {
                    tempFilter += `,Tableau Region=${userHOR?.area_hierarchy_name || ''}`
                }

                tableauFilter.set(tempFilter)

                tableauReady.set(true)
            }).catch((e: AxiosError) => {

            })
        } else if (tabKey.get() === 'DailySalesReportB2C') {
            tableauReady.set(true)
        }
    }

    useEffect(() => {
        if (active) {
            getDataTableauFilter()
            setFilterDataToQuery()
        }
        // eslint-disable-next-line
    }, [active])

    const getTabData = () => {
        const tab = {
            key: tabKey.get(),
            name: 'ErafosDashboard'
        }

        return tab;
    }

    

    return (
        <>
            <Tabs defaultActiveKey={tabKey.get()} onChange={(key) => {
                tabKey.set(key)
                setFilterDataToQuery()
                window.location.reload();
            }}>
                <TabPane tab="Best Store Performance Region" key="BestStorePerformance" forceRender={true}>
                </TabPane>

                <TabPane tab="Daily Sales Report B2C Region" key="DailySalesReportB2C">
                </TabPane>
            </Tabs>

            <div>
                {
                    tableauReady.get() ? (
                        <AppDefaultTableau tab={getTabData().key} name={getTabData().name} filters={$clone(tableauFilter.get())}/>
                    ) : ''
                }
            </div>
        </>
    )
};

export default DashboardB2CTabA
