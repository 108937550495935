import { PlusOutlined } from "@ant-design/icons"
import { Button, DatePicker } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import Select from "react-select"
 

const MultipleDateSelector = (props: any) => {
    const [open, setOpen] = useState(false)
    const value = props.value || []
    const [render, setRender] = useState(true)

    useEffect(() => {
        setRender(false)
        setTimeout(() => setRender(true))
        if(value.length > 0){
            props.onChange([])
        }
    }, [props.selectedMonth]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (date: any) => {
        let val = {
            label: moment(date).format('DD-MM-YYYY'),
            value: moment(date).format('YYYY-MM-DD')
        }
        if (!value.find((item: any) => item.value === val.value)) {
            let newValue = [...value, val]
            props.onChange(newValue)
        }
    }

    const handleSelectorChange = (data: any) => {
        props.onChange(data)
    }
    return <>
        <div className="multiple-dateselect">
            <div className="select-row">
                <Select placeholder="Pilih Tanggal" isSearchable={false} isDisabled={value.length === 0} isMulti value={value} menuIsOpen={false} className={'custom-select'} classNamePrefix='select' onChange={handleSelectorChange} isClearable={false} />
                <Button disabled={props.disabled || !props.selectedMonth} onBlur={() => setOpen(false)} className="add-btn" onClick={() => {setOpen(!open)}} icon={<PlusOutlined />} style={{ width: '50px' }}></Button>
            </div>
            {
                render && 
                <div>
                    <DatePicker defaultPickerValue={moment(props.selectedMonth).startOf('month')} showToday={false} open={open} onChange={handleChange} disabledDate={(current) => {
                        let maxDate = moment(props.selectedMonth).endOf('month')
                        let minDate = moment(props.selectedMonth).startOf('month')
                        let now = moment().format('YYYY MM DD');
                        if (moment(now) > minDate) {
                            minDate = moment(now)
                        }
                        return current && (current < minDate || current > maxDate);
                    }} />
                </div>
            }
            
        </div>
    </>
}

export default MultipleDateSelector