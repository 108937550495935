import { Empty, Select, Spin } from "antd";
import { VuiSelectProps } from "./interface";
import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import useDebounce from "../../@framework/utilities/hooks/useDebounce";

const VuiSelect = (props: VuiSelectProps) => {
  const {
    value,
    repository = null,
    repositoryName = "select",
    repositoryParams = {},
    mode,
    className = "",
    disabled = false,
    placeholder = "Select",
    labelKey = "name",
    valueKey = "id",
    defaultOptions = [],
    onChange,
    isCombineOption = false,
  } = props;

  const [options, setOptions] = useState<any[]>(defaultOptions);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 400);
  const [hasInit, setHasInit] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (repository && repositoryName) {
      setIsLoading(true);

      repository[repositoryName]({
        ...repositoryParams,
        ...(debouncedSearch ? { search: debouncedSearch } : {}),
      })
        .then((response: AxiosResponse) => {
          if (isCombineOption) {
            setOptions([...defaultOptions, ...response.data.data]);
          } else {
            setOptions(response.data.data);
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    setHasInit(true);
  }, [
    repository,
    repositoryParams,
    repositoryName,
    debouncedSearch,
    defaultOptions,
    isCombineOption,
  ]);

  useEffect(() => {
    if (hasInit) {
      (async () => {
        await loadData();
      })();
    }
  }, [debouncedSearch]);

  return (
    <Select
      showSearch
      style={{ width: "100%" }}
      allowClear
      mode={mode}
      size="large"
      value={value}
      disabled={disabled}
      options={options}
      placeholder={placeholder}
      labelInValue
      className={className}
      dropdownMatchSelectWidth={false}
      fieldNames={{
        value: valueKey,
        label: labelKey,
      }}
      onChange={(value) => {
        if (typeof onChange === "function") {
          if (value) {
            onChange({
              ...value,
              anotherData: options.find((item) => item.id === value.value),
            });
          } else {
            onChange(value);
          }
        }
      }}
      onSearch={(value) => {
        setSearch(value);
      }}
      filterOption={false}
      notFoundContent={
        isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 150,
            }}
          >
            <Spin />
          </div>
        ) : (
          <Empty />
        )
      }
      onDropdownVisibleChange={(open) => {
        if (open) {
          (async () => {
            await loadData();
          })();
        } else {
          setSearch("");
          if (repository) {
            setOptions([]);
          }
        }
      }}
    />
  );
};

export default VuiSelect;
