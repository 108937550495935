import { useState } from "@hookstate/core";
import Tabs from "antd/lib/tabs";
import React, {useEffect, useRef} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import WuiContainer from "../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import DashboardB2BTabA from "./Tabs/A";
import DashboardB2BTabB from "./Tabs/B";
import {haveAccess} from "../../../../functions/global";
import {permissions} from "../../../../constant/permissions";
import useAccess from "../../../../@framework/utilities/hooks/useAccess";
import { defaultMenus } from "../../../../constant/menu";

const { TabPane } = Tabs;

const breadcrumbs = [
    {
        label: 'Dashboard B2B',
        link: '/dashboard/B2B'
    },
]

const AppDashboardB2B: React.FC<any> = () => {
    const initialRoute = useRef<any>(null)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const tabKey = useState(searchParams.get('tab') || 'Tab A')

    const queryParams = useState({})
    const acl = useAccess()

    useEffect(() => {
        if(!haveAccess(acl, permissions.read_grafik_b2b_tab_a)){
            tabKey.set('Tab B')
        }

        init()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const init = () => {
      if (
        !haveAccess(acl, [
          permissions.read_grafik_b2b_tab_a,
          permissions.read_grafik_b2b_tab_b,
        ])
      ) {
        getInitialRoute(defaultMenus);
      }
    };


    const getInitialRoute = (menus: any[]) => {
      menus?.forEach((item: any) => {
        if (!!initialRoute.current) {
          navigate(initialRoute.current, { replace: true });
        } else {
          if (haveAccess(acl, item.access)) {
            if (item.subMenus) {
              getInitialRoute(item.subMenus);
            } else {
              initialRoute.current = item.link;
            }
          }
        }
      });
    };


    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={'Dashboard B2B'} breadcrumbs={breadcrumbs}/>

                <Tabs defaultActiveKey={tabKey.get()} onChange={(key) => {
                    tabKey.set(key)
                }}>
                    {
                        haveAccess(acl, permissions.read_grafik_b2b_tab_a) &&
                        <TabPane tab="Tab A" key="Tab A">
                            <DashboardB2BTabA active={tabKey.get() === 'Tab A'} setParams={queryParams.set}/>
                        </TabPane>
                    }

                    {
                        haveAccess(acl, permissions.read_grafik_b2b_tab_b) &&
                        <TabPane tab="Tab B" key="Tab B">
                            <DashboardB2BTabB active={tabKey.get() === 'Tab B'} setParams={queryParams.set}/>
                        </TabPane>
                    }
                </Tabs>
            </WuiContainer>
        </>
    )
}

export default AppDashboardB2B;