import { api, createCancelTokenHandler } from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    select: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/select/kpi-items`, {
            params,
        });
    },
    all: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/kpi-items`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token,
        });
    },
    show: function (id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/kpi-item/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token,
        });
    },
    create: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-item`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token,
        });
    },
    update: function (id: number | string, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/multi/kpi-item/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token,
        });
    },
    delete: function (id: number | string, params: any = null) {
        return api.delete(`${endPoint()}/api/v1/multi/kpi-item/${id}`, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
                .token,
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;