import { permissions } from "./permissions";

type AccessRoute = {
  url: string;
  access: string | string[];
};

export const accessRoute: AccessRoute[] = [
  {
    url: "/dashboard/B2B",
    access: [
      permissions.read_grafik_b2b_tab_a,
      permissions.read_grafik_b2b_tab_b,
    ],
  },
  {
    url: "/dashboard/B2C",
    access: [
      permissions.read_grafik_b2c_tab_a,
      permissions.read_grafik_b2c_tab_o2o,
      permissions.read_grafik_b2c_tab_forecast,
    ],
  },
  {
    url: "/dashboard/online",
    access: permissions.read_grafik_online,
  },
  {
    url: "/dashboard/head-quarter",
    access: permissions.read_grafik_headquarter,
  },
  {
    url: "/journey-plan/B2B",
    access: permissions.ignore,
  },
  {
    url: "/journey-plan/B2C",
    access: permissions.ignore,
  },
  {
    url: "/promo",
    access: [permissions.read_promotion_b2b, permissions.read_promotion_b2c],
  },
  {
    url: "/issue-tracking",
    access: [
      permissions.read_issue_tracking_b2b,
      permissions.read_issue_tracking_b2c,
    ],
  },
  {
    url: "/promotor",
    access: [
      permissions.read_handover_stuff_b2b,
      permissions.read_uniform_request_b2b,
    ],
  },
  {
    url: "/survei",
    access: [permissions.read_survey_b2b],
  },
  {
    url: "/ldu",
    access: permissions.read_live_display_unit_b2c,
  },
  {
    url: "/kpi/master-kpi",
    access: permissions.ignore,
  },
  {
    url: "/kpi/user-kpi",
    access: [
      permissions.read_user_kpi_b2b,
      permissions.read_user_kpi_b2c,
    ],
  },
  {
    url: "/kpi/item-kpi",
    access: permissions.ignore,
  },
  {
    url: "/kpi/target",
    access: permissions.ignore,
  },
  {
    url: "/kpi/hasil-perhitungan",
    access: permissions.ignore,
  },
  {
    url: "/stock-opname",
    access: permissions.read_stock_opname_b2c,
  },
  {
    url: "/pengaturan/data-master/dealer",
    access: [permissions.read_dealer_b2b],
  },
  {
    url: "/pengaturan/data-master/dealer/prospek-dealer-baru",
    access: [permissions.read_dealer_prospect_b2b],
  },
  {
    url: "/pengaturan/data-master/business-unit",
    access: [permissions.read_business_unit_b2c],
  },
  {
    url: "/pengaturan/data-master/business-unit/category",
    access: [permissions.read_business_unit_category_b2c],
  },
  {
    url: "/pengaturan/data-master/business-unit/sub-category",
    access: [permissions.read_business_unit_sub_category_b2c],
  },
  {
    url: "/pengaturan/data-master/business-unit/channel",
    access: [permissions.read_business_unit_channel_b2c],
  },
  {
    url: "/pengaturan/data-master/store",
    access: [permissions.read_store_b2c],
  },
  {
    url: "/pengaturan/data-master/lokasi",
    access: [permissions.read_location_b2c],
  },
  {
    url: "/pengaturan/data-master/tipe-produk",
    access: [
      permissions.read_product_type_b2b,
      permissions.read_product_type_b2c,
    ],
  },
  {
    url: "/pengaturan/data-master/produk",
    access: [permissions.read_product_b2b, permissions.read_product_b2c],
  },
  {
    url: "/pengaturan/data-master/brand",
    access: [permissions.read_brand_b2b, permissions.read_brand_b2c],
  },
  {
    url: "/pengaturan/data-master/region",
    access: [permissions.read_region_b2b, permissions.read_region_b2c],
  },
  {
    url: "/pengaturan/data-master/territory",
    access: [permissions.read_territory_b2b, permissions.read_territory_b2c],
  },
  {
    url: "/pengaturan/data-master/sub-territory",
    access: [
      permissions.read_subterritory_b2b,
      permissions.read_subterritory_b2c,
    ],
  },
  {
    url: "/pengaturan/data-master/area",
    access: [permissions.read_area_b2b, permissions.read_area_b2c],
  },
  {
    url: "/pengaturan/data-master/cluster",
    access: [permissions.read_cluster_b2b],
  },
  {
    url: "/pengaturan/akun/sales",
    access: [permissions.read_sales_b2b],
  },
  {
    url: "/pengaturan/akun/store-leader",
    access: [permissions.ignore],
  },
  {
    url: "/pengaturan/akun/pengguna",
    access: [permissions.read_user_b2b, permissions.read_user_b2c],
  },
  {
    url: "/pengaturan/akun/hak-akses",
    access: [
      permissions.read_access_role_b2b,
      permissions.read_access_role_b2c,
    ],
  },
  {
    url: "/pengaturan/akun/peran",
    access: [permissions.read_superior_b2b, permissions.read_superior_b2c],
  },
  {
    url: "/pengaturan/push-notifikasi",
    access: [
      permissions.read_push_notification_b2b,
      permissions.read_push_notification_asl,
      permissions.read_push_notification_promoter,
      permissions.read_push_notification_superior,
      permissions.read_push_notification_sc,
      permissions.read_push_notification_b2c,
    ],
  },
  {
    url: "/pengaturan/radius",
    access: [
      permissions.read_setting_radius_b2b,
      permissions.read_setting_radius_b2c,
    ],
  },
  {
    url: "/pengaturan/journey",
    access: [
      permissions.read_setting_journey_b2b,
      permissions.read_setting_journey_b2c,
    ],
  },
];
