import { api, createCancelTokenHandler } from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    all: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/kpi-users`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token,
        });
    },
    show: function (id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/multi/kpi-user/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token,
        });
    },
    create: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-user`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token,
        });
    },
    update: function (id: number | string, payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-user/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token,
        });
    },
    importFraud: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-user-import/fraud`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.importFraud.name].handleRequestCancellation().token
        });
    },
    importBadDebt: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-user-import/bad-debt`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.importBadDebt.name].handleRequestCancellation().token
        });
    },
    importOntimePayment: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-user-import/ontime-payment`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.importOntimePayment.name].handleRequestCancellation().token
        });
    },
    importGrowthExisting: function (payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/multi/kpi-user-import/growth-existing`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.importGrowthExisting.name].handleRequestCancellation().token
        });
    }
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;