import React, { useEffect, useMemo } from "react";
import WuiContainer from "../../../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import RequestJourneyPlanRepository from "../../../../../../../repositories/RequestJourneyPlanRepository";
import WuiFormTitle from "../../../../../../../@framework/wui/components/Form/Title";
import {
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Result,
  Row,
  Spin,
} from "antd";
import { useState } from "@hookstate/core";
import {
  handleBackendError,
  openNotification,
} from "../../../../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import Text from "antd/es/typography/Text";
import moment from "moment";
import Accessible from "../../../../../../../@framework/wui/components/Accessible";
import { permissions } from "../../../../../../../constant/permissions";
import _ from "lodash";

let title = "Detail Permintaan";

const AppJourneyPlanB2CFormRequestApproval: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedIds, setSelectedIds] = React.useState<any[]>([]);
  const disable = useState(false);
  const acceptBtnLoading = useState(false);

  const assistantName = useMemo(() => {
    if (data.length > 0) {
      return data[0].assistant_name;
    }

    return "-";
  }, [data]);

  // pagination
  const page = useState<number>(1);
  const perPage = useState<number>(10);
  const totalData = useState<number>(0);

  //Form
  const [formModal] = Form.useForm();

  // Modal
  const rejectBtnLoading = useState(false);
  const rejectModalShow = useState(false);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [page.get()]); // eslint-disable-line react-hooks/exhaustive-deps

  const rejectOnCancel = () => {
    rejectModalShow.set(false);
  };

  const rejectOnOk = async (formData: any) => {
    rejectBtnLoading.set(true);

    let payload = {
      assistant_id: id,
      request_journey_ids: selectedIds,
      status: "Ditolak",
      remark: formData.remark,
    };

    await RequestJourneyPlanRepository.updateMultipleStatus("b2c", payload)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        rejectBtnLoading.set(false);
        rejectModalShow.set(false);
        formModal.resetFields();
        if (page.get() === 1) {
          getData();
        } else {
          page.set(1);
        }

        setSelectedIds([]);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        rejectBtnLoading.set(false);
        rejectModalShow.set(false);
        formModal.resetFields();
      });
  };

  const acceptRequest = async () => {
    acceptBtnLoading.set(true);
    disable.set(true);
    let payload = {
      assistant_id: id,
      request_journey_ids: selectedIds,
      status: "Diterima",
    };

    await RequestJourneyPlanRepository.updateMultipleStatus("b2c", payload)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        acceptBtnLoading.set(false);
        disable.set(false);
        if (page.get() === 1) {
          getData();
        } else {
          page.set(1);
        }

        setSelectedIds([]);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        acceptBtnLoading.set(false);
        disable.set(false);
      });
  };

  const getData = async () => {
    disable.set(true);
    setLoading(true);
    const params = {
      assistant: id,
      status: "Pending",
      order_by: "visit_date",
      sorted_by: "asc",
      page: page.get(),
      per_page: perPage.get(),
    };
    await RequestJourneyPlanRepository.all("b2c", params)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || [];
        setData(data);
        totalData.set(res.data?.total || 0);
        disable.set(false);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        console.log(e);
        handleBackendError(e);
        setLoading(false);
      });
  };

  const renderContent = () => {
    return (
      <Spin spinning={loading}>
        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card>
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
                size={25}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Pengguna"} />
                  <div className="input-section">
                    <Text strong>{assistantName}</Text>
                  </div>
                </div>
              </Space>
            </Card>
            <br />
            <Checkbox.Group
              value={selectedIds}
              onChange={(val) => setSelectedIds(val)}
              style={{ width: "100%" }}
            >
              {data.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <Card>
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size={20}
                      >
                        <div className="input-section">
                          <Checkbox value={item.id}>
                            <Text strong>
                              {moment(item.visit_date).format("DD MMMM YYYY")}
                            </Text>
                          </Checkbox>
                        </div>
                        <div className="wui-form-group type-column">
                          <WuiFormTitle title={"Kode Permintaan"} />
                          <div className="input-section">
                            <Text strong>{_.get(item, "code", "-")}</Text>
                          </div>
                        </div>
                        <Row>
                          <Col xs={24} md={10}>
                            <div className="wui-form-group type-column">
                              <WuiFormTitle title={"Toko yang dituju"} />
                              <div className="input-section">
                                <Text strong>
                                  {_.get(item, "store_name", "-")}
                                </Text>
                              </div>
                            </div>
                          </Col>
                          <Col xs={24} md={14}>
                            <div className="wui-form-group type-column">
                              <WuiFormTitle title={"Alasan"} />
                              <div className="input-section">
                                <Text strong>{_.get(item, "reason", "-")}</Text>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Space>
                    </Card>

                    <br />
                  </React.Fragment>
                );
              })}
            </Checkbox.Group>

            <Card>
              <Space style={{ width: "100%" }} direction="vertical" align="end">
                <Pagination
                  simple
                  defaultCurrent={1}
                  current={page.get()}
                  total={totalData.get()}
                  onChange={(p) => page.set(p)}
                />
              </Space>
            </Card>
          </Col>
        </Row>

        <Accessible access={permissions.write_status_request_journey_plan_b2c}>
          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              disabled={disable.get() || selectedIds.length < 1}
              size={"large"}
              onClick={() => rejectModalShow.set(true)}
            >
              {t("common.button.reject")}
            </Button>

            <Spin spinning={acceptBtnLoading.get()}>
              <Button
                className="wui-btn"
                type="primary"
                size={"large"}
                disabled={disable.get() || selectedIds.length < 1}
                onClick={acceptRequest}
              >
                {t("common.button.accept")}
              </Button>
            </Spin>
          </div>
        </Accessible>
      </Spin>
    );
  };

  const renderNotFound = () => {
    return (
      <Card>
        <Result
          status="info"
          title="There is no request to approve."
          extra={
            <Button type="primary" key="console" onClick={() => navigate(-1)}>
              Go Back
            </Button>
          }
        />
      </Card>
    );
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />
        {data.length === 0 && !loading ? renderNotFound() : renderContent()}
      </WuiContainer>

      <Modal
        title={`Tolak Permintaan`}
        centered
        visible={rejectModalShow.get()}
        onOk={formModal.submit}
        onCancel={rejectOnCancel}
        footer={[
          <Button key="back" onClick={rejectOnCancel}>
            {t("common.button.back")}
          </Button>,
          <Button
            form="formModal"
            key="submit"
            htmlType={"submit"}
            type="primary"
            loading={rejectBtnLoading.get()}
          >
            {t("common.button.send")}
          </Button>,
        ]}
      >
        <Form
          id={"formModal"}
          form={formModal}
          layout={"vertical"}
          onFinish={rejectOnOk}
        >
          <div className="content">
            <div className="wui-form-group type-column">
              <WuiFormTitle title={"Alasan Penolakan"} />
              <div className="input-section">
                <Form.Item
                  name="remark"
                  rules={[
                    {
                      required: true,
                      message: "Alasan harus diisi",
                    },
                  ]}
                >
                  <Input
                    disabled={disable.get()}
                    size={"large"}
                    placeholder={t("common.text.input", { item: "Penolakan" })}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AppJourneyPlanB2CFormRequestApproval;
