import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Input from "antd/es/input";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import BrandRepository from "../../../../../../repositories/BrandRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  haveAccess, inputUppercase,
  openNotification,
  toFileList,
} from "../../../../../../functions/global";
import Spin from "antd/lib/spin";
import { Col, Row } from "antd/lib/grid";
import WuiUploadImages from "../../../../../../@framework/wui/components/UploadImages";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import TableSalesConsultant from "../Component/Table/TableMaps";
import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Typography } from "antd";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";
import {permissions} from "../../../../../../constant/permissions";

const { Text } = Typography;

let title = "Brand";

const AppSettingsDataMasterBrandForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const acl = useAccess();
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce<string>(search, 500);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = new FormData();

    if (id) {
      formData.append("_method", "PUT");
    }
    formData.append("code", data.code);
    formData.append("name", data.name);

    if (data.logo) {
      data.logo.forEach((file: any) => {
        if (file?.status === "done") {
          formData.append("logo_keep", file.id);
          formData.append("logo", file);
        } else {
          formData.append("logo", file);
        }
      });
    }

    await (!id
      ? BrandRepository.create(formData)
      : BrandRepository.update(id, formData)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await BrandRepository.show(id, { with: ["logo"] })
      .then((res: AxiosResponse) => {
        form.setFieldsValue({
          code: res.data?.data?.code,
          name: res.data?.data?.name,
          logo: toFileList(res.data?.data?.logo),
        });

        if (haveAccess(acl, permissions.write_brand_b2b)) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card title={t("common.text.itemInformation", { item: title })}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  {id && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item name="id" label={"ID"}>
                          <Input
                            defaultValue={id}
                            disabled
                            size={"large"}
                            placeholder={t("common.text.input", { item: "ID" })}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="code"
                        label={"Kode"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Kode" }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Kode" })}
                          onInput={inputUppercase.formatter}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Nama"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Nama" }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Nama" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Checkbox onChange={() => {}}>
                        <Text>Brand Utama</Text>
                      </Checkbox>
                    </div>
                  </div>
                </Space>
              </Card>
              <br />
              <Card title={"Sales Consultant"}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={24}
                >
                  <Row>
                    <Col md={12} xs={24}>
                      <Input
                        allowClear
                        placeholder={t("common.filter.search.placeholder")}
                        prefix={<SearchOutlined />}
                        value={search}
                        onChange={(value) => {
                          setSearch(value.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <TableSalesConsultant brandId={id} search={debouncedSearch} />
                </Space>
              </Card>
            </Col>

            <Col
              className="gutter-row"
              md={{
                span: 8,
                order: 2,
              }}
              xs={{
                span: 24,
                order: 1,
              }}
            >
              <Card title={t("common.text.image")}>
                <Form.Item name="logo">
                  {!disable.get() && <WuiUploadImages />}
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible access={[permissions.write_brand_b2b]}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsDataMasterBrandForm;
