import { api } from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  select: function (params: any = null) {
    let url = `${endPoint()}/api/v1/multi/select/superior-channels`;

    return api.get(url, {
      params,
    });
  },
};

export default Repository;
