import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "antd/es/page-header";
import React, { useEffect } from "react";
import WuiContainer from "../../../../../../../@framework/wui/components/Container";
import { Col, Row } from "antd/lib/grid";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import WuiFormTitle from "../../../../../../../@framework/wui/components/Form/Title";
import Form from "antd/lib/form";
import WuiSelectSingle from "../../../../../../../@framework/wui/components/Select/Single";
import SalesRepository from "../../../../../../../repositories/SalesRepository";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import JourneyPlanRepository from "../../../../../../../repositories/JourneyPlanRepository";
import Select from "antd/lib/select";
import WuiSelectMulti from "../../../../../../../@framework/wui/components/Select/Multi";
import moment from "moment";
import { handleBackendError, haveAccess, openNotification } from "../../../../../../../functions/global";
import Accessible from "../../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../../@framework/utilities/hooks/useAccess";
import { DatePicker } from "antd";
import { permissions } from "../../../../../../../constant/permissions";

const { Option } = Select

let title = "Journey Plan";

const AppJourneyPlanFormPlanDetails: React.FC<any> = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', { item: title }) : t('common.text.addNewItem', { item: title })

    // Form
    const [form] = Form.useForm();
    const disable = useState(false);
    const loading = useState(false);
    const acl = useAccess()

    const [dealerOptions, setDealerOptions] = React.useState<any>([])


    useEffect(() => {
        if (id) {
            getData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await JourneyPlanRepository.show(id, 'b2b', { with: ['brands', 'sales', 'dealer'] }).then((res: AxiosResponse) => {
            const data = res.data?.data || {}
            
            form.setFieldsValue({
                ...data,
                sales: {
                    value: data.sales?.id,
                    label: data.sales?.name,
                },
                brand: (data.brands || []).map((item: any) => (
                    {
                        name: item.name,
                        id: item.id
                    }
                )),
                date: moment(data.date),
                dealer: {
                    label: `${data.dealer?.name} - ${data.dealer?.code}`,
                    value: data.dealer?.id,
                    address: data.dealer?.address,
                }
            })

            if(data.sales?.id){
                getSalesBrandAndDealers(data.sales?.id, true)
            }
            if(haveAccess(acl, permissions.write_journey_plan_b2b)){
                disable.set(false)
            }
        }).catch((e: AxiosError) => {
            console.log(e)
        })
    }

    const getSalesBrandAndDealers = (id: any, init: boolean = false) => {
        SalesRepository.show(id, { with: ['brands', 'dealers'] }).then((res: AxiosResponse) => {
            let data = res.data?.data;
            if (data.brands) {
                if(!init){
                    form.setFieldsValue(
                        {
                            ...form.getFieldsValue(true),
                            brand: (data.brands || []).map((item: any) => (
                                {
                                    name: item.name,
                                    id: item.id
                                }
                            )),
                        }
                    )
                }
                
            }

            if (data.dealers) {
                if(!init){
                    form.setFieldsValue(
                        {
                            ...form.getFieldsValue(true),
                           dealer: null
                        }
                    )
                }

                setDealerOptions(data.dealers)
            }
        }).catch((e: AxiosError) => {
            console.log(e)
        })
    }

    const onFinish = async (data: any) => {
        loading.set(true);

        let payload = {
            sales_id: data.sales?.value,
            brand_ids: (data.brand || []).map((item: any) => item.id),
            dealer_id: data.dealer?.value,
            date: `${moment(form.getFieldValue('date')).format('YYYY-MM-DD[T]HH:mm:ss')}+00:00`
        }

        await (!id ? (JourneyPlanRepository.create('b2b', payload)) : JourneyPlanRepository.update(id, 'b2b', payload)).then((res: AxiosResponse) => {
            navigate(-1)
       
            if (!id) {
                openNotification('success', t('notification.success.createItem', { item: title }))
            } else {
                openNotification('success', t('notification.success.updateItem', { item: title }))
                loading.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            loading.set(false);
        })
    }

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />

                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                    onValuesChange={(changedValues, allValues) => {
                        if (changedValues.sales) {
                            getSalesBrandAndDealers(changedValues.sales.value)
                        }
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" md={{
                            span: 16,
                            order: 1
                        }} xs={{
                            span: 24,
                            order: 2
                        }}>
                            <Card title={''} className="mb16">
                                <Space style={{
                                    width: '100%'
                                }} direction="vertical" size={0}>
                                    <div className="wui-form-group type-column">
                                        <WuiFormTitle title={"Tugaskan ke"} />
                                        <div className="input-section">
                                            <Form.Item
                                                name="sales"
                                                rules={[{
                                                    required: true,
                                                    message: 'Sales harus diisi'
                                                }]}
                                            >
                                                <WuiSelectSingle repository={SalesRepository} disabled={disable.get()} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="wui-form-group type-column">
                                        <WuiFormTitle title={"Brand"} />
                                        <div className="input-section">
                                            <Form.Item
                                                name="brand"
                                                rules={[{
                                                    required: true,
                                                    message: 'Data brand tidak valid'
                                                }]}
                                            >
                                                <WuiSelectMulti disabled={true} placeholder="Tidak ada brand" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                </Space>
                            </Card>

                            <Card>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Tanggal"} required={true} />

                                    <div className="input-section">
                                        <Form.Item
                                            name="date"
                                            rules={[{
                                                required: true,
                                                message: t('validation.required', { item: "Tanggal" })
                                            }]}
                                        >
                                            <DatePicker disabled={disable.get()} disabledDate={(current) => {
                                                let customDate = moment().format("YYYY-MM-DD");
                                                return current && current < moment(customDate, "YYYY-MM-DD");
                                            }}
                                                format="DD-MM-YYYY" style={{ width: 400, maxWidth: '100%' }} size={"large"} onChange={() => { }} placeholder={t('common.text.input', { item: 'Tanggal' })} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Dealer yang dituju"} />
                                    <div className="input-section">
                                        <Form.Item
                                            name={'dealer'}
                                            rules={[{
                                                required: true,
                                                message: 'Dealer harus diisi'
                                            }]}
                                        >
                                            <Select  labelInValue={true} disabled={dealerOptions.length === 0 || disable.get()} size={"large"} placeholder={ dealerOptions.length > 0 ? "Pilih dealer" : 'Tidak ada pilihan dealer'} style={{
                                                width: '100%'
                                            }}>
                                                {
                                                    dealerOptions.map((item: any) =>
                                                        <Option value={item.id} key={item.id}>
                                                            <div className="custom-dealer-option">
                                                                <div>{item?.name}  - {item?.code}</div>
                                                                <div className={"address"}>{item?.address}</div>
                                                            </div>
                                                        </Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>


                    <div className="wui-form-btn-group">
                        <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.cancel')}</Button>
                        <Accessible access={permissions.write_journey_plan_b2b}>
                            <Spin spinning={loading.get()}>
                                <Button className="wui-btn" htmlType="submit" type="primary" size={"large"} disabled={disable.get()}>{t('common.button.save')}</Button>
                            </Spin>
                        </Accessible>
                    </div>
                </Form>
            </WuiContainer>
        </>
    )
}

export default AppJourneyPlanFormPlanDetails