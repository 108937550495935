import { useState } from "@hookstate/core";
import { Input, Typography } from "antd";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import React from "react";
import { openNotification } from "../../functions/global";

const { Text } = Typography;

interface Props {
  show: boolean;
  onOk: (reason: string) => Promise<unknown>;
  onCancel: () => void;
}

const RejectRevisionStockOpnameModal: React.FC<Props> = ({
  show,
  onOk,
  onCancel,
}) => {
  const loading = useState(false);
  const [reason, setReason] = React.useState<string>("");

  const handleOnOk = async () => {
    if (reason === "") {
      openNotification("error", "Masukan alasan penolakan");
      return;
    }

    loading.set(true);

    onOk(reason)
      .then((res) => {
        loading.set(false);
      })
      .catch(() => {
        loading.set(false);
      });
  };

  return (
    <Modal
      title={"Tolak Stock Opname"}
      centered
      visible={show}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Kembali
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading.get()}
          onClick={handleOnOk}
        >
          Kirim
        </Button>,
      ]}
    >
      <div style={{ textAlign: "left" }}>
        <Text style={{ fontSize: 15, marginBottom: 6 }}>Alasan Penolakan</Text>

        <Input
          placeholder={"Masukkan alasan penolakan anda"}
          onChange={(e) => setReason(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default RejectRevisionStockOpnameModal;
