import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";
import PageHeader from "antd/es/page-header";
import {useNavigate, useParams} from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Input from "antd/es/input";
import Form from "antd/lib/form";
import {useState} from "@hookstate/core";
import Button from "antd/es/button";
import SubTerritoryRepository from "../../../../../../repositories/SubTerritoryRepository";
import {AxiosError, AxiosResponse} from "axios";
import {handleBackendError, haveAccess, inputUppercase, openNotification} from "../../../../../../functions/global";
import Spin from "antd/lib/spin";
import {Col, Row} from "antd/lib/grid";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import TerritoryRepository from "../../../../../../repositories/TerritoryRepository";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import { permissions } from "../../../../../../constant/permissions";

let title = "Sub Territory";

const AppSettingsDataMasterSubTerritoryForm: React.FC<any> = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', {item: title}) : t('common.text.addNewItem', {item: title})

    // Form
    const [form] = Form.useForm();
    const disable = useState(false);
    const loading = useState(false);
    const acl = useAccess()

    const onFinish = async (data: any) => {
        loading.set(true);

        let payload = {
            territory_id: data.territory?.value,
            code: data.code,
            name: data.name
        }

        await (!id ? (SubTerritoryRepository.create(payload)) : SubTerritoryRepository.update(id, payload)).then((res: AxiosResponse) => {
            navigate(-1)
         
            if (!id) {
                openNotification('success', t('notification.success.createItem', {item: title}))
            } else {
                openNotification('success', t('notification.success.updateItem', {item: title}))
                loading.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            loading.set(false);
        })
    }

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await SubTerritoryRepository.show(id, {
            with: 'territory'
        }).then((res: AxiosResponse) => {
            form.setFieldsValue({
                territory: {
                    value: res.data?.data?.territory.id,
                    label: res.data?.data?.territory.name
                },
                code: res.data?.data?.code,
                name: res.data?.data?.name
            })

            if(haveAccess(acl, permissions.write_subterritory_b2b)){
                disable.set(false)
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
        })
    }

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />

                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >

                    <Row>
                        <Col md={16} xs={24}>
                            <Card title={t('common.text.itemInformation', {item: title})}>
                                <Space style={{
                                    width: '100%'
                                }} direction="vertical" size={5}>
                                    {
                                        id && 
                                        <div className="wui-form-group type-column">
                                            <div className="input-section">
                                            <Form.Item
                                                name="id"
                                                label={"ID"}
                                            >
                                                    <Input defaultValue={id} disabled  size={"large"} placeholder={t('common.text.input', {item: 'ID'})} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }
                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="territory"
                                                label={"Territory"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', {item: 'Territory'})
                                                }]}
                                            >
                                                <WuiSelectSingle disabled={disable.get()} repository={TerritoryRepository} selectParams={{ channel: 'b2b' }}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="code"
                                                label={"Kode"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', {item: 'Kode'})
                                                }]}
                                            >
                                                <Input disabled={disable.get()} size={"large"} placeholder={t('common.text.input', {item: 'Kode'})} onInput={inputUppercase.formatter} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="name"
                                                label={"Nama"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', {item: 'Nama'})
                                                }]}
                                            >
                                                <Input disabled={disable.get()} size={"large"} placeholder={t('common.text.input', {item: 'Nama'})} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Space>
                            </Card>
                        </Col>
                    </Row>


                    <div className="wui-form-btn-group">
                        <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.cancel')}</Button>
                        <Accessible access={permissions.write_subterritory_b2b}>
                        <Spin spinning={loading.get()}>
                            <Button className="wui-btn" htmlType="submit" type="primary" size={"large"} disabled={disable.get()}>{t('common.button.save')}</Button>
                        </Spin>
                        </Accessible>
                    </div>
                </Form>
            </WuiContainer>
        </>
    )
}

export default AppSettingsDataMasterSubTerritoryForm;