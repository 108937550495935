import Collapse from "antd/lib/collapse";
import { Col, Row } from "antd/lib/grid";
import WuiFilterDateRange from "../../../../../../../@framework/wui/components/Filter/DateRange";
import Table from "antd/es/table";
import React, { useEffect, useMemo } from "react";
import moment from "moment";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import Space from "antd/es/space";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Text from "antd/es/typography/Text";
import {
  handleBackendError,
  openNotification,
} from "../../../../../../../functions/global";
import WuiModalConfirmation from "../../../../../../../@framework/wui/components/Modal/Confirmation";
import { useTranslation } from "react-i18next";
import WuiSelectMulti from "../../../../../../../@framework/wui/components/Select/Multi";
import StoreRepository from "../../../../../../../repositories/StoreRepository";
import _ from "lodash";
import JourneyPlanRepository from "../../../../../../../repositories/JourneyPlanRepository";
import Moment from "react-moment";
import AssistantStoreLeaderRepository from "../../../../../../../repositories/AssistantStoreLeaderRepository";
import WuiSelectSingle from "../../../../../../../@framework/wui/components/Select/Single";
import Button from "antd/es/button";
import Accessible from "../../../../../../../@framework/wui/components/Accessible";
import { permissions } from "../../../../../../../constant/permissions";

var qs = require("qs");

const { Panel } = Collapse;

interface Props {
  active: boolean;
  setParams?: Function;
}

const TabPlan: React.FC<Props> = ({ active, setParams }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let fullPath = window.location.pathname;

  const [renderFilter, setRenderFilter] = React.useState(true);

  // For DateRange Picker
  const [filterDate, setFilterDate] = React.useState<any>([
    searchParams.get("start_date")
      ? moment(searchParams.get("start_date"), "YYYY-MM-DD")
      : moment().startOf("month"),
    searchParams.get("end_date")
      ? moment(searchParams.get("end_date"), "YYYY-MM-DD")
      : moment().endOf("month"),
  ]);

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates);
  };
  // End DateRange Picker

  // Filters
  const localSelectDealers = JSON.parse(
    localStorage.getItem("journey.dealers") || "[]"
  );
  const [selectedDealers, setSelectedDealers] = React.useState<any[]>([]);

  // Filters
  const localSelectSales = JSON.parse(
    localStorage.getItem("journey.sales") || "[]"
  );
  const [selectedSales, setSelectedSales] = React.useState<any[]>([]);

  useEffect(() => {
    setFilterDataToQuery(true);
    // eslint-disable-next-line
  }, [selectedDealers, selectedSales, filterDate]);

  useEffect(() => {
    if (active) {
      setFilterDataToQuery();
    } else {
      setSearchParams("");
    }
    // eslint-disable-next-line
  }, [active]);

  // Table Config
  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);

  const setFilterDataToQuery = (isReset: boolean = false) => {
    let params = {
      tab: "Plan",
    };
    let reset = isReset;

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedDealers) {
      const multiValue = _.map(selectedDealers, "id");

      Object.assign(params, {
        dealers: multiValue,
      });
    }

    if (selectedSales) {
      const multiValue = _.map(selectedSales, "value");

      Object.assign(params, {
        sales: multiValue,
      });
    }

    if (filterDate) {
      Object.assign(params, {
        start_date: filterDate[0].format("YYYY-MM-DD"),
        end_date: filterDate[1].format("YYYY-MM-DD"),
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const getFilterDataFromQuery = () => {
    if (!localSelectDealers) {
      searchParams.delete("dealers");
    } else {
      const dealers = _.map(searchParams.getAll("dealers"), (item) =>
        parseInt(item)
      );
      if (dealers) {
        let dealerFromStorage = _.filter(localSelectDealers, (data) => {
          return dealers.includes(data.id);
        });
        setSelectedDealers(dealerFromStorage);
      } else {
        localStorage.removeItem("journey.dealers");
      }
    }

    if (!localSelectSales) {
      searchParams.delete("sales");
    } else {
      const sales = _.map(searchParams.getAll("sales"), (item) =>
        parseInt(item)
      );
      if (sales) {
        let salesFromStorage = _.filter(localSelectSales, (data) => {
          return sales.includes(data.value);
        });
        setSelectedSales(salesFromStorage);
      } else {
        localStorage.removeItem("journey.sales");
      }
    }
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
      start_date: filterDate[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      end_date: filterDate[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      stores: _.map(selectedDealers, "id"),
      assistant: _.map(selectedSales, "value"),
    };

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "descend";
    }

    if (setParams) {
      setParams(params);
    }

    await JourneyPlanRepository.all("b2c", params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  const columns: any = [
    {
      title: "Tanggal Kunjungan",
      dataIndex: "date",
      key: "date",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "date" && tableSort.sortOrder.get(),
      render: (text: any, record: any) => {
        return <Moment format="DD MMMM YYYY">{text}</Moment>;
      },
    },
    {
      title: "Assistant Leader",
      dataIndex: "assistant_name",
      key: "assistant_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "assistant_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Toko",
      dataIndex: "store_name",
      key: "store_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "store_name" && tableSort.sortOrder.get(),
    },
    {
      title: "Tanggal Dibuat",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "created_at" && tableSort.sortOrder.get(),
      render: (text: any, record: any) => {
        return <Moment format="DD MMMM YYYY | HH:mm">{text}</Moment>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={fullPath + "/" + record.id}>Lihat Detail</Link>
          <Accessible access={permissions.delete_journey_plan_b2c}>
            <Text
              className="cursor-pointer"
              type={"danger"}
              onClick={() => setDeleteModal(record.id)}
            >
              Hapus
            </Text>
          </Accessible>
        </Space>
      ),
    },
  ];
  // End Table Config

  // Modal
  const showDeleteModal = useState(false);
  const selectedDeleteId = useState(0);

  const setDeleteModal = (id: any) => {
    selectedDeleteId.set(id);
    showDeleteModal.set(true);
  };

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
      await JourneyPlanRepository.delete(selectedDeleteId.get(), "b2c")
        .then((res: AxiosResponse) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: "Journey Plan" })
          );
          getTableData(true);
        })
        .catch((e: AxiosError) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          handleBackendError(e, t("notification.error.default"));
        });
    }
  };
  // End Modal

  const handleReset = () => {
    setSelectedSales([]);
    setSelectedDealers([]);
    setFilterDate([moment().startOf("month"), moment().endOf("month")]);
  };

  return (
    <>
      <Collapse
        className="mb16"
        defaultActiveKey={["1"]}
        expandIconPosition={"right"}
      >
        <Panel header="Filter" key="1">
          <Row className="mb6" gutter={[15, 15]}>
            <Col className="gutter-row" xs={24} md={12} lg={8}>
              <WuiFilterDateRange
                defaultValue={filterDate}
                callback={handleDateRangeCallback}
              />
            </Col>

            <Col className="gutter-row" xs={24} md={24} lg={12}>
              {
                //ini karena gw kaga bisa hapus pilihannya kalo ganti sales. butuh handle lbh baik
                renderFilter && (
                  <WuiSelectMulti
                    value={selectedDealers}
                    onChange={(val: any) => {
                      setSelectedDealers(val);
                      localStorage.setItem(
                        "journey.dealers",
                        JSON.stringify(val)
                      );
                    }}
                    selectParams={{
                      assistant: _.map(selectedSales, "value"),
                      channel: "b2c",
                    }}
                    repository={StoreRepository}
                    placeholder={t("select.placeholder", { item: "Toko" })}
                  />
                )
              }
            </Col>

            <Col className="gutter-row" xs={24} md={24} lg={12}>
              <WuiSelectSingle
                style={{ width: "100%" }}
                value={selectedSales.length > 0 ? selectedSales[0] : null}
                onChange={(val: any) => {
                  if(val) {
                    setSelectedSales([val]);
                    localStorage.setItem("journey.sales", JSON.stringify([val])); 
                  } else {
                    setSelectedSales([]);
                    localStorage.setItem("journey.sales", JSON.stringify([])); 
                  }
                  setRenderFilter(false);
                  setSelectedDealers([]);
                  localStorage.removeItem("journey.dealers");
                  setTimeout(() => setRenderFilter(true), 200);
                }}
                repository={AssistantStoreLeaderRepository}
                placeholder={t("select.placeholder", {
                  item: "Assistant Leader",
                })}
                selectParams={{
                  channel: "b2c",
                }}
                allowClear
              />
            </Col>
            <Col>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Table
        rowKey={"id"}
        bordered
        columns={columns}
        dataSource={tableData.get()}
        loading={tableLoading}
        onChange={handleTableChange}
        pagination={{
          current: tablePage.get(),
          simple: true,
          pageSize: tablePerPage.get(),
          total: totalData.get(),
        }}
      />

      <WuiModalConfirmation
        show={showDeleteModal.get()}
        onOk={deleteModalCallback}
        onCancel={() => showDeleteModal.set(false)}
      />
    </>
  );
};

export default TabPlan;
