import React from "react";
import {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {useState} from "@hookstate/core";
import {AxiosError, AxiosResponse} from "axios";
import {useIsMounted} from "../../@framework/utilities/hooks";
import TableauRepository from "../../repositories/TableauRepository";

type TableauTabs = 'BestStorePerformanceRegion' | 'DailySalesReportB2CRegion' | 'PerformanceBO_QVO' | string

interface Props {
    name?: string,
    tab?: TableauTabs
    filters?: string | string[]
    height?: string
}

const AppDefaultTableau: React.FC<Props> = (
    {
        name= 'ErafosDashboardRegion',
        tab = 'BestStorePerformanceRegion',
        filters = '',
        height = '768'
    }
) => {
    const isMounted = useIsMounted()
    const tableauToken = useState('')

    useEffect(() => {
        if (isMounted) {
            tableauToken.set('')
            getTableauToken()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getTableauToken = async () => {
        await TableauRepository.token().then((res: AxiosResponse) => {
            tableauToken.set(res?.data)
        }).catch((err: AxiosError) => {

        })
    }

    return (
        <>
            {
                tableauToken.get() ? (
                    <div key={tab}>
                        <Helmet>
                            <script type="text/javascript" src="https://insight.erajaya.com/javascripts/api/viz_v1.js"></script>
                        </Helmet>

                        <object className="tableauViz" width="1024" height={height} style={{display: 'none'}}>
                            <param name="name" value={`${name}/${tab}`}/>
                            {
                                filters ? (
                                    filters instanceof Array ? (
                                        filters.map((item) => {
                                            return (
                                                <param name="filter" value={item}/>
                                            )
                                        })
                                    ) : <param name="filter" value={filters}/>
                                ) : ''
                            }
                            <param name="ticket" value={tableauToken.get()}/>
                        </object>
                    </div>
                ) : <div></div>
            }
        </>
    )
}

export default AppDefaultTableau