import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { WuiCardSummary } from "../../../../../../@framework/wui/components/Card";
import WuiFilterDateRange from "../../../../../../@framework/wui/components/Filter/DateRange";
import DashboardRepository from "../../../../../../repositories/DashboardRepository";
import {TChannels} from "../../../../../../utils";

var qs = require('qs');

interface Props {
    active: boolean
    setParams?: Function
}

const DashboardB2BTabA: React.FC<Props> = (
    {
        active,
        setParams
    }
) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const channel = useState<TChannels>('b2b')

    const totalSalesData = useState({
        current: 0,
        direction: '',
        percentage: '',
        prev: 0
    })

    const totalPromoterData = useState({
        current: 0,
        direction: '',
        percentage: '',
        prev: 0
    })

    const totalProductData = useState({
        current: 0,
        direction: '',
        percentage: '',
        prev: 0
    })

    const totalDealerData = useState({
        current: 0,
        direction: '',
        percentage: '',
        prev: 0
    })

    const reportVisitSalesData = useState<any>({})

    const reportStockProductData = useState<any>({})

    // For DateRange Picker
    const [filterDate, setFilterDate] = React.useState<any>([
        (searchParams.get('start_date') ? moment((searchParams.get('start_date')), 'YYYY-MM-DD') : moment().startOf('month')),
        (searchParams.get('end_date') ? moment((searchParams.get('end_date')), 'YYYY-MM-DD') : moment().endOf('month'))
    ])

    const isReset = useState(false)

    const setFilterDataToQuery = (isReset: boolean = false) => {
        let params = {
            tab: 'Tab A'
        }

        if (filterDate) {
            Object.assign(params, {
                start_date: filterDate[0].format('YYYY-MM-DD'),
                end_date: filterDate[1].format('YYYY-MM-DD')
            })
        }

        const queryParams = qs.stringify(params, { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        }

        getDataDashboard()
    }

    const getDataDashboard = async () => {
        if(!filterDate[0] || !filterDate[1]){
            return
        }
        let payload = {
            start_date: filterDate[0].format('YYYY-MM-DD'),
            end_date: filterDate[1].format('YYYY-MM-DD')
        }

        isReset.set(false)

        await DashboardRepository.totalSales(channel.get(), payload).then((res: AxiosResponse) => {
            if(isReset.get()) return
            totalSalesData.set(res?.data)
        }).catch((e: AxiosError) => {
            //handleBackendError(e, 'Failed to get total sales data')
        })

        await DashboardRepository.totalPromoter(channel.get(), payload).then((res: AxiosResponse) => {
            if(isReset.get()) return
            totalPromoterData.set(res?.data)
        }).catch((e: AxiosError) => {
            //handleBackendError(e, 'Failed to get total brand data')
        })

        await DashboardRepository.totalProduct(channel.get(), payload).then((res: AxiosResponse) => {
            if(isReset.get()) return
            totalProductData.set(res?.data)
        }).catch((e: AxiosError) => {
            //handleBackendError(e, 'Failed to get total product data')
        })

        await DashboardRepository.totalDealer(channel.get(), payload).then((res: AxiosResponse) => {
            if(isReset.get()) return

            totalDealerData.set(res?.data)
        }).catch((e: AxiosError) => {
            //handleBackendError(e, 'Failed to get total dealer data')
        })

        await DashboardRepository.reportVisitSales(channel.get(), payload).then((res: AxiosResponse) => {
            if(isReset.get()) return

            reportVisitSalesData.set(res?.data)
        }).catch((e: AxiosError) => {
            //handleBackendError(e, 'Failed to get report visit sales data')
        })

        await DashboardRepository.reportStockProduct(channel.get(), payload).then((res: AxiosResponse) => {
            if(isReset.get()) return

            reportStockProductData.set(res?.data)
        }).catch((e: AxiosError) => {
            //handleBackendError(e, 'Failed to get report visit sales data')
        })
    }

    const handleDateRangeCallback = async (dates: any, dateString?: any) => {
        await setFilterDate(dates)
    }

    useEffect(() => {
        setFilterDataToQuery(true)
    }, [filterDate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (active) {
            handleReset()
        }
        // eslint-disable-next-line
    }, [active])

    const handleReset = () => {
        setFilterDate([moment().startOf('month'), moment().endOf('month')])
        isReset.set(true)
        totalSalesData.set({
            current: 0,
            direction: '',
            percentage: '',
            prev: 0
        })
        totalPromoterData.set({
            current: 0,
            direction: '',
            percentage: '',
            prev: 0
        })
        totalProductData.set({
            current: 0,
            direction: '',
            percentage: '',
            prev: 0
        })
        totalDealerData.set({
            current: 0,
            direction: '',
            percentage: '',
            prev: 0
        })
        reportStockProductData.set({})
        reportVisitSalesData.set({})
    }

    const getDiffDays = (): string => {
        if(!filterDate[0] || !filterDate[1]){
            return ''
        }
        return `dari ${Math.ceil(moment.duration(moment(filterDate[1]).startOf('day').diff(moment(filterDate[0]).endOf('day'))).asDays()) + 1} hari yang lalu`
    }

    return (
        <>
            <Row className="mb16" gutter={[15, 15]}>
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                    <WuiFilterDateRange defaultValue={filterDate} callback={handleDateRangeCallback} />
                </Col>
                <Col>
                    <Button type="link" onClick={handleReset}>Reset</Button>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                    <WuiCardSummary
                        title={'Total Sales'}
                        value={totalSalesData.get().current}
                        percentage={totalSalesData.get().percentage}
                        type={totalSalesData.get().direction}
                        comparison={getDiffDays()}
                    />
                </Col>
                <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                    <WuiCardSummary
                        title={'Total Promotor'}
                        value={totalPromoterData.get().current}
                        percentage={totalPromoterData.get().percentage}
                        type={totalPromoterData.get().direction}
                        comparison={getDiffDays()}
                    />
                </Col>
                <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                    <WuiCardSummary
                        title={'Total Produk'}
                        value={totalProductData.get().current}
                        percentage={totalProductData.get().percentage}
                        type={totalProductData.get().direction}
                        comparison={getDiffDays()}
                    />
                </Col>
                <Col className="gutter-row" span={6} xs={24} md={12} lg={6}>
                    <WuiCardSummary
                        title={'Total Dealer'}
                        value={totalDealerData.get().current}
                        percentage={totalDealerData.get().percentage}
                        type={totalDealerData.get().direction}
                        comparison={getDiffDays()}
                    />
                </Col>
                <Col className="gutter-row" span={12} xs={24} md={24} lg={12}>
                    <WuiCardSummary
                        title={'Total Kunjungan Sales'}
                        value={reportVisitSalesData.get().current}
                        percentage={reportVisitSalesData.get().percentage}
                        type={reportVisitSalesData.get().direction}
                        comparison={getDiffDays()}
                        data={{
                            label: 'Total',
                            labels: reportVisitSalesData.get().graph?.label,
                            data: reportVisitSalesData.get().graph?.amount,
                            type: 'line'
                        }}
                    />
                </Col>

                <Col className="gutter-row" span={12} xs={24} md={24} lg={12}>
                    <WuiCardSummary
                        title={'Produk dengan stok terbanyak'}
                        value={reportStockProductData.get().current}
                        percentage={reportStockProductData.get().percentage}
                        type={reportStockProductData.get().direction}
                        comparison={getDiffDays()}
                        data={{
                            label: 'Total',
                            labels: reportStockProductData.get().graph?.label,
                            data: reportStockProductData.get().graph?.amount,
                            type: 'bar'
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DashboardB2BTabA