import { useState } from "@hookstate/core";
import { Checkbox, Spin } from "antd";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Select from "antd/es/select";
import Space from "antd/es/space";
import Form from "antd/lib/form";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import WuiSectionTitle from "../../../../../../@framework/wui/components/Sections/Title";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import {
  AREA_HIERARCHY_CLUSTER,
  AREA_HIERARCHY_OPTIONS,
  AREA_HIERARCHY_SUBTERRITORY,
  ASSISTANT_STORE_LEADER,
  SALES_ADMIN,
  SALES_CONSULTANT,
  SALES_LEVEL_OPTIONS,
  SALES_PROMOTER, STORE_LEADER,
  TABLEAU_CODE_OPTIONS,
} from "../../../../../../constant";
import { permissions } from "../../../../../../constant/permissions";
import {
  handleBackendError,
  haveAccess,
  inputUppercase,
  openNotification,
} from "../../../../../../functions/global";
import SuperiorChannelRepository from "../../../../../../repositories/SuperiorChannelRepository";
import SuperiorRepository from "../../../../../../repositories/SuperiorRepository";

const { Option } = Select;
let title = "Peran";

const AppSettingsAccountRoleForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const watchHasB2B = Form.useWatch("has_b2b", form);
  const watchHasB2C = Form.useWatch("has_b2c", form);
  const watchHasOnline = Form.useWatch("has_online", form);

  const [channelIds, setChannelIds] = React.useState<any>([]); //for update form

  const acl = useAccess();

  const onFinish = async (data: any) => {
    loading.set(true);

    const hasSelectedChannel = ["has_b2b", "has_b2c", "has_online"]
      .map((key) => {
        return !!data[key];
      })
      .find((bool) => bool === true);

    if (!hasSelectedChannel) {
      openNotification(
        "error",
        t("validation.required", {
          item: "Channel",
        })
      );

      loading.set(false);

      return;
    }

    let payload = {
      tableau_code: data?.tableau_code || "",
      code: data.code,
      name: data.name,
      is_era_email: data.is_era_email,
      channels: transformChannelFormData(data),
    };

    await (!id
      ? SuperiorRepository.create(payload)
      : SuperiorRepository.update(id, payload)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  const transformChannelFormData = (data: any) => {
    const channels = ["has_b2b", "has_b2c", "has_online"].map((name) => {
      if (data[name]) {
        const channelData: any = {};
        const channel = name.split("_")[1];
        const level = _.get(data, `${channel}_level`);
        const area = _.get(data, `${channel}_area_hierarchy`);
        const superior = _.get(data, `${channel}_superior.value`);

        channelData["channel"] = channel.toUpperCase();

        channelData["level"] = level;

        channelData["area_hierarchy"] = area;

        channelData["parent_id"] = superior;

        if (id) {
          const channelId = channelIds.find(
            (ch: any) => ch.channel === channel
          );

          if (channelId) {
            channelData["id"] = channelId.id;
          }
        }

        return channelData;
      }

      return null;
    });

    return channels.filter((channel) => channel !== null);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await SuperiorRepository.show(id, {
      with: "channels.parent",
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        const channels = data.channels.map((item: any) => ({
          id: item.id,
          channel: item?.channel?.toLowerCase(),
        }));

        setChannelIds(channels);

        const channelFieldValue = getChannelFieldValue(data.channels);

        const formData: any = {
          code: data.code,
          name: data.name,
          is_era_email: data.is_era_email,
          ...channelFieldValue,
        };

        if (data?.tableau_code) {
          formData.tableau_code = data.tableau_code;
        }

        form.setFieldsValue(formData);

        if (
          haveAccess(acl, [
            permissions.write_superior_b2b,
            permissions.write_superior_b2c,
            permissions.write_superior_online,
          ])
        ) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {
        console.log(e, "error");
      });
  };

  const getChannelFieldValue = (channels: any) => {
    const field: any = {};
    channels.forEach((item: any) => {
      const channel = item?.channel?.toLowerCase();

      field[`has_${channel}`] = true;
      field[`${channel}_level`] = item.level;
      field[`${channel}_area_hierarchy`] = item.area_hierarchy;

      field[`${channel}_superior`] = item?.parent
        ? {
            value: item.parent.id,
            label: item.parent.superior_name,
          }
        : null;
    });

    return field;
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Card>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
              size={5}
            >
              <div className="wui-form-group type-column">
                <div className="input-section">
                  <Form.Item
                    name="code"
                    label={"Kode"}
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", { item: "Kode" }),
                      },
                    ]}
                  >
                    <Input
                      disabled={disable.get()}
                      size={"large"}
                      placeholder={t("common.text.input", { item: "Kode" })}
                      onInput={inputUppercase.formatter}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="wui-form-group type-column">
                <div className="input-section">
                  <Form.Item
                    name="tableau_code"
                    label={"Kode Tableau"}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      disabled={disable.get()}
                      size={"large"}
                      style={{
                        width: "100%",
                      }}
                      placeholder={t("select.placeholder", {
                        item: "Kode Tableau",
                      })}
                      allowClear
                    >
                      {TABLEAU_CODE_OPTIONS.map((item: any) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="wui-form-group type-column">
                <div className="input-section">
                  <Form.Item
                    name="name"
                    label={"Peran"}
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", { item: "Peran" }),
                      },
                    ]}
                  >
                    <Input
                      disabled={disable.get()}
                      size={"large"}
                      placeholder={t("common.text.input", { item: "name" })}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="wui-form-group">
                <div className="input-section">
                  <Form.Item name="is_era_email" valuePropName="checked">
                    <Checkbox disabled={disable.get()}>
                      Menggunakan email Erajaya
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>

              <div className="wui-form-group type-column">
                <div className="input-section">
                  <WuiFormTitle title={"Channel"} />

                  <Space>
                    <Form.Item name="has_b2b" valuePropName="checked">
                      <Checkbox
                        disabled={
                          !haveAccess(acl, permissions.write_superior_b2b) || disable.get()
                        }
                      >
                        B2B
                      </Checkbox>
                    </Form.Item>

                    <Form.Item name="has_b2c" valuePropName="checked">
                      <Checkbox
                        disabled={
                          !haveAccess(acl, permissions.write_superior_b2c) || disable.get()
                        }
                      >
                        B2C
                      </Checkbox>
                    </Form.Item>

                    <Form.Item name="has_online" valuePropName="checked">
                      <Checkbox
                        disabled={
                          !haveAccess(acl, permissions.write_superior_online) || disable.get()
                        }
                      >
                        Online
                      </Checkbox>
                    </Form.Item>
                  </Space>
                </div>
              </div>

              {watchHasB2B && (
                <ChannelForm
                  channel="b2b"
                  disable={disable.get()}
                  currentId={id}
                />
              )}

              {watchHasB2C && (
                <ChannelForm
                  channel="b2c"
                  disable={disable.get()}
                  currentId={id}
                />
              )}

              {watchHasOnline && (
                <ChannelForm
                  channel="online"
                  disable={disable.get()}
                  currentId={id}
                />
              )}
            </Space>
          </Card>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible
              access={[
                permissions.write_superior_b2b,
                permissions.write_superior_b2c,
                permissions.write_superior_online,
              ]}
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsAccountRoleForm;

const ChannelForm = React.memo(({ channel, disable, currentId }: any) => {
  const { t } = useTranslation();

  const getSalesOptions = () => {
    if (channel === "b2b") {
      return SALES_LEVEL_OPTIONS.filter(
        (item) => item !== ASSISTANT_STORE_LEADER && item !== STORE_LEADER
      );
    } else if (channel === "b2c") {
      return SALES_LEVEL_OPTIONS.filter(
        (item) => item !== SALES_CONSULTANT && item !== SALES_PROMOTER
      );
    } else if (channel === "online") {
      return [SALES_ADMIN];
    }

    return SALES_LEVEL_OPTIONS;
  };

  const getHierarchyOptions = () => {
    if (channel === "b2c") {
      return AREA_HIERARCHY_OPTIONS.filter(
        (item) =>
          item !== AREA_HIERARCHY_CLUSTER &&
          item !== AREA_HIERARCHY_SUBTERRITORY
      );
    }

    return AREA_HIERARCHY_OPTIONS;
  };

  return (
    <React.Fragment key={channel}>
      <WuiSectionTitle title={channel?.toUpperCase()} />

      <div className="wui-form-group type-column">
        <div className="input-section">
          <Form.Item
            name={`${channel}_level`}
            label={"Level"}
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: "Level",
                }),
              },
            ]}
          >
            <Select
              disabled={disable}
              size={"large"}
              style={{
                width: "100%",
              }}
              placeholder="Pilih Level"
            >
              {getSalesOptions().map((item: any) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <Form.Item
        className="mb0"
        shouldUpdate={(prevValues, curValues) =>
          prevValues[`${channel}_level`] !== curValues[`${channel}_level`]
        }
      >
        {({ getFieldValue }) =>
          channel !== "online" &&
          getFieldValue(`${channel}_level`) !== SALES_ADMIN && (
            <div className="wui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name={`${channel}_area_hierarchy`}
                  label={"Hierarki"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: "Hierarki",
                      }),
                    },
                  ]}
                >
                  <Select
                    disabled={disable}
                    size={"large"}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Pilih Hierarki"
                  >
                    {getHierarchyOptions().map((item: any) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )
        }
      </Form.Item>

      {channel !== "online" && (
        <div className="wui-form-group type-column">
          <div className="input-section">
            <Form.Item name={`${channel}_superior`} label={"Superior"}>
              <WuiSelectSingle
                disabled={disable}
                placeholder="Pilih Superior"
                repository={SuperiorChannelRepository}
                selectParams={{
                  channel: channel.toUpperCase(),
                }}
                excludeIds={[currentId]}
              />
            </Form.Item>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});
