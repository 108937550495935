import React, { useEffect } from "react";
import WuiContainer from "../../../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import WuiFormTitle from "../../../../../../../@framework/wui/components/Form/Title";
import { Col, Row } from "antd";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import Text from "antd/es/typography/Text";
import WuiUploadImages from "../../../../../../../@framework/wui/components/UploadImages";
import DealerProspectRepository from "../../../../../../../repositories/DealerProspectRepository";
import moment from "moment";
import Typography from "antd/lib/typography";
import { toFileList } from "../../../../../../../functions/global";
import _ from "lodash";

const { Link } = Typography;

let title = "Prospek Dealer Baru";

const AppSettingsDataMasterNewProspectDealerList: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  // Form
  const disable = useState(false);
  const tabKey = useState("1");
  const [data, setData] = React.useState<any>({
    brands: [],
  });

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);
    await DealerProspectRepository.show(id, {
      with: ["sales", "brands", "attachments"],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        setData({
          ...data,
          sales_name: data.sales?.name,
          attachments: toFileList(data.attachments),
          brand_names: _.map(data?.brands ? data.brands : [], "name").join(
            ", "
          ),
        });

        disable.set(false);
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  const getCoordinate = () => {
    let arr = data?.lng_lat?.split(",");

    if (arr?.length) {
      return `${arr[1]}, ${arr[0]}`;
    }

    return "";
  };
  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />
        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card title="Informasi Dealer">
              <Space
                style={{
                  width: "100%",
                  maxHeight: tabKey.get() === "1" ? "100%" : 0,
                  opacity: tabKey.get() === "1" ? 1 : 0,
                }}
                direction="vertical"
                size={25}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Tanggal dibuat"} />
                  <div className="input-section">
                    <Text strong>
                      {moment(data.created_at).format("D MMM YYYY | HH:mm")}
                    </Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Tanggal Kunjungan"} />
                  <div className="input-section">
                    <Text strong>
                      {moment(data.visit_date).format("D MMM YYYY")}
                    </Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Sales"} />
                  <div className="input-section">
                    <Text strong>{data.user_name}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nama Dealer"} />
                  <div className="input-section">
                    <Text strong>{data.name}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"PIC"} />
                  <div className="input-section">
                    <Text strong>{data.pic_name}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nomor HP"} />
                  <div className="input-section">
                    <Text strong>{data.phone_number}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nomor KTP"} />
                  <div className="input-section">
                    <Text strong>{data.pic_identity_number}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nomor NPWP"} />
                  <div className="input-section">
                    <Text strong>{data.tax_number}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Brand"} />
                  <div className="input-section">
                    <Text strong>{data.brand_names}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Alamat Dealer"} />
                  <div className="input-section">
                    <Link
                      href={`https://www.google.com/maps/search/?api=1&query=${getCoordinate()}`}
                      target="_blank"
                    >
                      <b>{data.address}</b>
                    </Link>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Email"} />
                  <div className="input-section">
                    <Text strong>{data.email}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Alasan"} />
                  <div className="input-section">
                    <Text strong>{data.description}</Text>
                  </div>
                </div>
              </Space>
            </Card>
          </Col>
          <Col
            className="gutter-row"
            md={{
              span: 8,
              order: 2,
            }}
            xs={{
              span: 24,
              order: 1,
            }}
          >
            <Card title="Lampiran">
              {!disable.get() && (
                <WuiUploadImages
                  disabled={true}
                  max={5}
                  value={data.attachments}
                />
              )}

              {/* {!disable.get() &&
                  (form.getFieldValue("attachments") || []).length === 0 && (
                    <Text>Tidak ada lampiran</Text>
                  )} */}
            </Card>
          </Col>
        </Row>

        <div className="wui-form-btn-group">
          <Button
            className="wui-btn"
            size={"large"}
            onClick={() => navigate(-1)}
          >
            {t("common.button.back")}
          </Button>
        </div>
      </WuiContainer>
    </>
  );
};

export default AppSettingsDataMasterNewProspectDealerList;
